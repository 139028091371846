import React, { ReactNode } from "react"
import { connect } from "react-redux"
import {
  isAuthenticated,
  isTermsAccepted,
  isInitializationStarted,
  isInitialized,
  isADUser,
  user,
  LoginResponse
} from "../../store/auth/auth-reducer"
import { AppState } from "../../store/state"
import { Redirect, Route, Switch } from "react-router"
import { AppInitializer } from "../app-initializer"
import { Header } from "../header/header"
import AcceptTermsPage from "../../pages/accept-terms/accept-terms-page"
import { SalesViewPage } from "pages/sales-view/SalesViewPage"
import { EditUserPage } from "pages/sales-view/user-form/EditUserPage"
import { InviteUserPage } from "pages/sales-view/user-form/InviteUserPage"
import classNames from "classnames"
import { AnnouncementBanner } from "@kettu/ui-components"
import { useLoginEvent } from "../../util/analytics"
import { Worksite } from "../../store/worksite/models"

interface Props {
  isInitializationStarted: boolean
  isAuthenticated: boolean
  isInitialized: boolean
  isTermsAccepted: boolean
  isADUser: boolean
  isTopBannerNotificationActive?: boolean
  user: LoginResponse | null
  children: ReactNode
}

const bannerURL = process.env.REACT_APP_BANNER_URL || "2"

const Component = React.memo(
  ({
    isAuthenticated,
    isInitializationStarted,
    isInitialized,
    isTermsAccepted,
    isADUser,
    isTopBannerNotificationActive = false,
    user,
    children
  }: Props) => {
    useLoginEvent(isAuthenticated)
    if (!isInitializationStarted) {
      return <AppInitializer />
    } else if (isAuthenticated) {
      if (isADUser) {
        // TODO create separate page for AD users, with or without header?
        return (
          <>
            <Header />
            <div className="page-container__content">
              <Switch>
                <Route path="/sales">{user && <SalesViewPage />}</Route>
                <Route path="/invite-user">
                  {user && <InviteUserPage loggedInUser={user} />}
                </Route>
                <Route path="/edit-user/:id">
                  {user && <EditUserPage loggedInUser={user} />}
                </Route>
                <Route render={() => <Redirect to="/sales" />} />
              </Switch>
            </div>
          </>
        )
      }
      if (isTermsAccepted) {
        let customerIds: { [key: string]: string } = {}
        let worksitePostalCodes: { [key: string]: string } = {}
        user?.worksites.forEach((w: Worksite) => {
          customerIds[w.id] = ""
          worksitePostalCodes[w.postCode] = ""
        })

        return (
          <>
            <AnnouncementBanner
              userIsAdmin={true}
              usersWorksitesPostalCodes={Object.keys(worksitePostalCodes)}
              usersCustomerIds={Object.keys(customerIds)}
              configURL={bannerURL}
            />
            <Header
              isTopBannerNotificationActive={isTopBannerNotificationActive}
            />
            <div
              className={classNames("page-container__content", {
                "--with-top-banner": isTopBannerNotificationActive
              })}
            >
              {children}
            </div>
          </>
        )
      } else {
        return (
          <>
            <Header />
            <AcceptTermsPage />
          </>
        )
      }
    }

    if (isInitialized && !isAuthenticated) {
      return <Redirect to={"/login"} />
    }

    // Tähän joku hieno login animaatio?
    return <div> Kirjaudutaan sisään... </div>
  }
)

export const LoggedInRoutes = connect((state: AppState) => ({
  isAuthenticated: isAuthenticated(state),
  isInitializationStarted: isInitializationStarted(state),
  isInitialized: isInitialized(state),
  isTermsAccepted: isTermsAccepted(state),
  isADUser: isADUser(state),
  user: user(state)
}))(Component)
