/* global Cookiebot:readonly */

import * as React from "react"
import { FormattedMessage } from "react-intl"
import SocialMedia from "./footer-social-media-component"

import "./footer.sass"

export const Footer = React.memo(() => (
  <footer className="footer">
    <div className="footer__legal-links">
      <a
        className="footer__link"
        href="https://www.lt.fi/fi/tietosuojaseloste#yritysasiakas--ja-yhteisty%C3%B6kumppanirekisterin-tietosuojaseloste"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id="registry-policy" />
      </a>
      <a
        className="footer__link"
        href="https://www.lt.fi/fi/evasteet"
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {
          e.preventDefault()
          Cookiebot.show()
        }}
      >
        <FormattedMessage id="cookie-policy" />
      </a>
      <a
        className="footer__link"
        href="/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id="terms-and-conditions" />
      </a>
    </div>
    <SocialMedia />
    <div className="footer__copyright">
      &copy; Lassila &amp; Tikanoja {new Date().getFullYear()}
    </div>
  </footer>
))
