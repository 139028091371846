import { AjaxError } from "rxjs/ajax"

export enum ErrorKey {
  USER_BLOCKED = "user_blocked",
  SERVICE_UNAVAILABLE = "service_unavailable"
}

export interface Error {
  key: string
  message: string
}

export const containsError = (key: String, error: AjaxError): boolean =>
  containsKey(key, error.response || {})

export const containsKey = (key: String, response: any): boolean => {
  const errors = response.errors || []
  return !!errors.find((e: Error) => e.key === key)
}

export const isUserInactive = (response: any): boolean =>
  containsKey(ErrorKey.USER_BLOCKED, response)

export const isUnknownLoginError = (response: any): boolean =>
  containsKey(ErrorKey.SERVICE_UNAVAILABLE, response)
