import LoadingSpinner from "common/loading-spinner/loading-spinner"
import { format } from "date-fns"
import { useMemo } from "react"
import { FormattedMessage } from "react-intl"
import FormatAddress from "../common/FormatAddress"
import { TransferDocument } from "../documents-api"
import { ReactComponent as DownloadButton } from "../../../images/icon-download-button.svg"

interface TransferDocumentMobileProps {
  documents: TransferDocument[]
  loaded: boolean
  loadFailed: boolean
}

const TransferDocumentMobile = ({
  documents,
  loaded,
  loadFailed
}: TransferDocumentMobileProps) => {
  const getLoadFailedMobile = useMemo(
    () => (
      <div className="document document--mobile">
        <div className="document__value document__value--mobile">
          <FormattedMessage id="common.error" />
        </div>
      </div>
    ),
    []
  )

  const getNoDocumentsMobile = useMemo(
    () => (
      <div className="document document--mobile">
        <div className="document__value document__value--mobile">
          <FormattedMessage id="documents.transfer.no-document" />
        </div>
      </div>
    ),
    []
  )

  const getDocumentRows = useMemo(
    () =>
      documents.map(document => (
        <div className="document document--mobile" key={document.id}>
          <h3 className="document__header document__header--mobile">
            <FormattedMessage id="documents.transfer.deliveryDate" />
          </h3>
          <h3 className="document__value document__value--mobile">
            {format(document.date, "dd.MM.yyyy")}
          </h3>

          <h3 className="document__header document__header--mobile">
            <FormattedMessage id="documents.common.worknumber" />
          </h3>
          <h3 className="document__value document__value--mobile">
            {document.worksiteDescription}
          </h3>

          <h3 className="document__header document__header--mobile">
            <FormattedMessage id="documents.common.site" />
          </h3>
          <h3 className="document__value document__value--mobile">
            <FormatAddress document={document} />
          </h3>

          <a href={document.url} target="_blank" rel="noopener noreferrer">
            <DownloadButton />
          </a>
        </div>
      )),
    [documents]
  )

  return (
    <div>
      {loadFailed && getLoadFailedMobile}
      {!loadFailed && !loaded && (
        <LoadingSpinner className="loading-spinner--line" />
      )}
      {loaded && documents.length === 0 && getNoDocumentsMobile}
      {loaded && getDocumentRows}
    </div>
  )
}

export default TransferDocumentMobile
