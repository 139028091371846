import React from "react"

import {
  EmptyFrontloaderOrder,
  FrontloaderFormState,
  FrontloaderFormTypes,
  FrontloaderWasteTypes,
  NewFrontloaderOrder,
  RemoveFrontloaderOrder
} from "./models"

import "../common/OrderForm.sass"
import Checkbox from "lib/checkbox/checkbox"
import Button from "lib/button/button"
import { makeSwitchExhaustive } from "util/utils"

import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"
import { GeneralInformation } from "../GeneralInformation"
import {
  BackgroundColor,
  ContainerWidth,
  ContentContainer
} from "common/content-container/content-container"
import { WeekPickerInputWrapper } from "lib/week-picker/WeekPicker"
import { connect } from "react-redux"
import { AppState } from "store/state"
import { submitOrder } from "store/order/order-reducer"
import { phone } from "store/auth/auth-reducer"
import {
  getDeliveryDate,
  getFirstAvailableDay,
  getFirstAvailableWeek
} from "../order-utils"
import {
  ContainerType,
  DeliveryType,
  EmptyInterval,
  EmptyOptions,
  Order
} from "../common/models"
import { Worksite } from "store/worksite/models"
import SelectionBox, {
  SelectionBoxLayoutType
} from "lib/selection-box/selection-box"
import classNames from "classnames"

interface FrontloaderFormProps {
  formType: FrontloaderFormTypes
  phone: string
  propFormState: FrontloaderFormState | null
  onSubmit: (order: Order, formState: FrontloaderFormState) => void
  onCancel: () => void
  worksite: Worksite
}

const initialFormData = (
  phone: string,
  formType: FrontloaderFormTypes
): FrontloaderFormState => {
  return {
    dateType: DeliveryType.RANGE,
    date: getFirstAvailableDay(),
    selectedWasteType: FrontloaderWasteTypes.EnergyWaste,
    emptyInterval: formType === "new" ? EmptyInterval.ONE_WEEK : null,
    callBefore: false,
    moveDuringEmpty: false,
    changeWasteTypeTo: null,
    containerNumber: "",
    contactNumber: phone,
    extraInfo: "",
    selectedEmptyOption: EmptyOptions.ONCE
  }
}

const FrontloaderForm = ({
  formType,
  onSubmit,
  propFormState,
  onCancel,
  phone,
  intl,
  worksite
}: FrontloaderFormProps & WrappedComponentProps) => {
  const [formState, setFormState] = React.useState<FrontloaderFormState>(
    propFormState ? propFormState : initialFormData(phone, formType)
  )

  const getFormTitle = (formType: FrontloaderFormTypes) => {
    switch (formType) {
      case "new":
        return <FormattedMessage id="order.frontloader.new" />
      case "emptying":
        return <FormattedMessage id="order.frontloader.empty" />
      case "remove":
        return <FormattedMessage id="order.frontloader.remove" />
      case "unselected":
        return ""
      default:
        makeSwitchExhaustive(formType)
    }
  }

  const getCallBeforeLabel = (formType: FrontloaderFormTypes) => {
    switch (formType) {
      case "new":
        return <FormattedMessage id="order.form.call-before" />
      case "emptying":
        return <FormattedMessage id="order.form.call-before.empty" />
      case "remove":
        return <FormattedMessage id="order.form.call-before.remove" />
      case "unselected":
        return ""
      default:
        makeSwitchExhaustive(formType)
    }
  }

  const CheckboxSection = () => {
    return (
      <>
        <Checkbox
          checked={formState.callBefore}
          onChange={() =>
            setFormState({ ...formState, callBefore: !formState.callBefore })
          }
          name="call_before"
        >
          {getCallBeforeLabel(formType)}
        </Checkbox>
      </>
    )
  }

  const updateSelectedWasteType = (wasteType: FrontloaderWasteTypes) => {
    setFormState({ ...formState, selectedWasteType: wasteType })
  }

  const updateSelectedInterval = (interval: EmptyInterval) => {
    setFormState({ ...formState, emptyInterval: interval })
  }

  const updateSelectedEmptyOption = (selectedEmptyOption: EmptyOptions) => {
    switch (selectedEmptyOption) {
      case EmptyOptions.ONCE:
        setFormState({
          ...formState,
          selectedEmptyOption: selectedEmptyOption,
          dateType: DeliveryType.WEEK,
          emptyInterval: null
        })
        break
      case EmptyOptions.REPEATING:
        setFormState({
          ...formState,
          selectedEmptyOption: selectedEmptyOption,
          dateType: DeliveryType.NOT_APPLICABLE,
          emptyInterval: EmptyInterval.ONE_WEEK
        })
        break
      default:
    }
  }

  const createAndSubmitOrder = () => {
    const BUSINESS_DAYS_TO_ADD = 4
    switch (formType) {
      case "new": {
        const order: NewFrontloaderOrder = {
          type: formType,
          targetIdentifier: worksite.targetIdentifier,
          containerType: ContainerType.FRONTLOADER,
          desiredDeliveryTime: getDeliveryDate(
            formState.dateType,
            formState.date,
            BUSINESS_DAYS_TO_ADD
          ),
          wasteType: formState.selectedWasteType,
          callBefore: formState.callBefore,
          contactNumber: formState.contactNumber,
          extraInfo: formState.extraInfo ? formState.extraInfo : null,
          emptyInterval: formState.emptyInterval
        }
        onSubmit(order, formState)
        return
      }
      case "emptying": {
        const order: EmptyFrontloaderOrder = {
          type: formType,
          targetIdentifier: worksite.targetIdentifier,
          containerType: ContainerType.FRONTLOADER,
          desiredDeliveryTime: getDeliveryDate(
            formState.dateType,
            formState.date,
            BUSINESS_DAYS_TO_ADD
          ), //TODO: consider this if empty interval??
          wasteType: formState.selectedWasteType,
          callBefore: formState.callBefore,
          contactNumber: formState.contactNumber,
          extraInfo: formState.extraInfo ? formState.extraInfo : null,
          emptyInterval: formState.emptyInterval
        }
        onSubmit(order, formState)
        return
      }
      case "remove": {
        const order: RemoveFrontloaderOrder = {
          type: formType,
          targetIdentifier: worksite.targetIdentifier,
          containerType: ContainerType.FRONTLOADER,
          desiredDeliveryTime: getDeliveryDate(
            formState.dateType,
            formState.date,
            BUSINESS_DAYS_TO_ADD
          ),
          wasteType: formState.selectedWasteType,
          callBefore: formState.callBefore,
          contactNumber: formState.contactNumber,
          extraInfo: formState.extraInfo ? formState.extraInfo : null
        }
        onSubmit(order, formState)
        return
      }
      case "unselected":
        return
      default:
        makeSwitchExhaustive(formType)
    }
  }

  const needsToHaveEmptyInterval =
    formType === "new" ||
    (formType === "emptying" &&
      formState.selectedEmptyOption === EmptyOptions.REPEATING)

  const isFormDisabled = () => {
    return (
      (needsToHaveEmptyInterval && !formState.emptyInterval) ||
      !formState.selectedWasteType ||
      formState.contactNumber === ""
    )
  }

  const showDesiredDeliveryTime = () => {
    return (
      (formType === "emptying" &&
        formState.selectedEmptyOption === EmptyOptions.ONCE) ||
      formType !== "emptying"
    )
  }

  const showWeekPicker = () => {
    return showDesiredDeliveryTime() && formState.dateType === DeliveryType.WEEK
  }

  const placeholderId =
    formType === "new"
      ? "order.form.frontloader.additional-info.placeholder-new"
      : "order.form.frontloader.additional-info.placeholder-other"

  return (
    <ContentContainer
      containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}
      backgroundColor={BackgroundColor.WHITE}
    >
      <div className="modal">
        <div className="modal__header">
          <h1 className="lt-h4">{getFormTitle(formType)}</h1>
          <button
            className="close-button"
            onClick={onCancel}
            aria-label={intl.formatMessage({ id: "common.close" })}
          />
        </div>
        <div className="modal__body">
          {formType === "emptying" && (
            <fieldset className="fieldset">
              <div className="label">
                <FormattedMessage id="order.form.service" /> *
              </div>
              <SelectionBox
                itemList={Object.values(EmptyOptions)}
                selectedItem={formState.selectedEmptyOption}
                onClick={updateSelectedEmptyOption}
              />
            </fieldset>
          )}
          {showDesiredDeliveryTime() && (
            <>
              <fieldset className="fieldset">
                <div className="label">
                  <FormattedMessage id="order.form.date" /> *
                </div>
                <div className="selection-box">
                  <div
                    className={classNames("selection-box__item", {
                      "selection-box__item--selected":
                        formState.dateType === DeliveryType.RANGE
                    })}
                    onClick={() =>
                      setFormState({
                        ...formState,
                        dateType: DeliveryType.RANGE,
                        date: getFirstAvailableDay()
                      })
                    }
                  >
                    <FormattedMessage id="order.form.asap" />
                  </div>
                  <div
                    className={classNames("selection-box__item", {
                      "selection-box__item--selected":
                        formState.dateType === DeliveryType.WEEK
                    })}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        dateType: DeliveryType.WEEK,
                        date: getFirstAvailableWeek()
                      })
                    }}
                  >
                    <FormattedMessage id="order.form.choose-date-range" />
                  </div>
                </div>
                {formState.dateType === DeliveryType.RANGE && (
                  <p className="info-text lt-margin-top-s">
                    <FormattedMessage id="order.form.compactor.info.date" />
                  </p>
                )}
              </fieldset>
              {showWeekPicker() && (
                <fieldset className="fieldset">
                  <div className="label">
                    <FormattedMessage id="order.form.selected-date" /> *
                  </div>
                  <WeekPickerInputWrapper
                    date={formState.date}
                    onDayChange={day =>
                      setFormState({
                        ...formState,
                        dateType: DeliveryType.WEEK,
                        date: day
                      })
                    }
                  />
                </fieldset>
              )}
            </>
          )}
          {((formType === "emptying" &&
            formState.selectedEmptyOption === EmptyOptions.REPEATING) ||
            formType === "new") && (
            <fieldset className="fieldset">
              <div className="label">
                <FormattedMessage id="order.form.choose-interval" /> *
              </div>
              <SelectionBox
                itemList={Object.values(EmptyInterval)}
                selectedItem={formState.emptyInterval}
                onClick={updateSelectedInterval}
                layout={SelectionBoxLayoutType.SMALL}
              />
            </fieldset>
          )}
          <fieldset className="fieldset">
            <div className="label">
              <FormattedMessage id="order.form.waste-type" /> *
            </div>
            <SelectionBox
              itemList={Object.values(FrontloaderWasteTypes)}
              selectedItem={formState.selectedWasteType}
              onClick={updateSelectedWasteType}
            />
          </fieldset>

          <fieldset className="fieldset">
            <CheckboxSection />
          </fieldset>

          <GeneralInformation
            inputValue={formState.contactNumber}
            onChangeInput={event =>
              setFormState({ ...formState, contactNumber: event.target.value })
            }
            textareaValue={formState.extraInfo}
            onChangeTextarea={event =>
              setFormState({ ...formState, extraInfo: event.target.value })
            }
            textareaPlaceholder={intl.formatMessage({ id: placeholderId })}
          />

          <Button
            expand
            disabled={isFormDisabled()}
            onClick={createAndSubmitOrder}
          >
            <FormattedMessage id="order.form.to-confirmation" />
          </Button>
        </div>
      </div>
    </ContentContainer>
  )
}

const connector = connect(
  (state: AppState) => ({
    phone: phone(state)
  }),
  { submitOrder }
)

export default connector(injectIntl(FrontloaderForm))
