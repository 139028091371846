import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { usePhoneScreenSize } from "util/screen-size"
import { SortingGuideDocument } from "../documents-api"
import "../documents.sass"
import SortingGuideDesktop from "./SortingGuideDesktop"
import SortingGuideMobile from "./SortingGuideMobile"

const SortingGuides = () => {
  const intl = useIntl()

  const sortingGuideDocuments: SortingGuideDocument[] = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "documents.sorting.guide-fi" }),
        language: intl.formatMessage({ id: "common.language.fi" }),
        url: "https://assets.lt.fi/raksanappi/waste-sorting-guides/Suomi - Rakennustyömaan lajittelu ja jätehuolto.pdf"
      },
      {
        name: intl.formatMessage({ id: "documents.sorting.guide-en" }),
        language: intl.formatMessage({ id: "common.language.en" }),
        url: "https://assets.lt.fi/raksanappi/waste-sorting-guides/Englanti - Rakennustyömaan lajittelu ja jätehuolto.pdf"
      },
      {
        name: intl.formatMessage({ id: "documents.sorting.guide-swe" }),
        language: intl.formatMessage({ id: "common.language.swe" }),
        url: "https://assets.lt.fi/raksanappi/waste-sorting-guides/Ruotsi - Rakennustyömaan lajittelu ja jätehuolto.pdf"
      },
      {
        name: intl.formatMessage({ id: "documents.sorting.guide-ee" }),
        language: intl.formatMessage({ id: "common.language.ee" }),
        url: "https://assets.lt.fi/raksanappi/waste-sorting-guides/Viro - Rakennustyömaan lajittelu ja jätehuolto.pdf"
      },
      {
        name: intl.formatMessage({ id: "documents.sorting.guide-pl" }),
        language: intl.formatMessage({ id: "common.language.pl" }),
        url: "https://assets.lt.fi/raksanappi/waste-sorting-guides/Puola - Rakennustyömaan lajittelu ja jätehuolto.pdf"
      },
      {
        name: intl.formatMessage({ id: "documents.sorting.guide-ru" }),
        language: intl.formatMessage({ id: "common.language.ru" }),
        url: "https://assets.lt.fi/raksanappi/waste-sorting-guides/Venäjä - Rakennustyömaan lajittelu ja jätehuolto.pdf"
      }
    ],
    [intl]
  )

  return (
    <>
      <div className="documents--sub-title">
        <FormattedMessage id="documents.sorting.sort-guides-title" />
      </div>
      {usePhoneScreenSize() ? (
        <SortingGuideMobile sortingGuideDocuments={sortingGuideDocuments} />
      ) : (
        <SortingGuideDesktop sortingGuideDocuments={sortingGuideDocuments} />
      )}
    </>
  )
}

export default SortingGuides
