import React from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"

import "./StatusBar.sass"

export enum StatusBarMode {
  INFO,
  ERROR,
  NONE
}

export type StatusBarState =
  | {
      status: StatusBarMode.INFO
      messageId: string
    }
  | {
      status: StatusBarMode.ERROR
      messageId: string
    }
  | {
      status: StatusBarMode.NONE
    }

type Props = {
  barState: StatusBarState
  onClick: () => void
}

const StatusBar: React.FC<Props> = ({ barState, onClick }) => {
  const className = classNames({
    "flash-info": barState.status === StatusBarMode.INFO,
    "flash-error": barState.status === StatusBarMode.ERROR,
    "flash-hidden": barState.status === StatusBarMode.NONE
  })

  const content =
    barState.status === StatusBarMode.NONE ? null : (
      <FormattedMessage id={barState.messageId} />
    )

  return (
    <div className="flash" onClick={onClick}>
      <div className={className}> {content} </div>
    </div>
  )
}

export default StatusBar
