import { Subject } from "rxjs"
import { AjaxError, AjaxResponse } from "rxjs/ajax"
import { catchError, debounceTime, map, switchMap } from "rxjs/operators"
import { postJson } from "store/http"

export type UserListItem = {
  id?: string
  name: string
  email: string
  raksanappi: {
    phone: string
    terms: Date | undefined
    role: string
    hasAccessToReports: Boolean
  }
}

export type UserListResponse = {
  pageNumber: number
  pageSize: number
  users: UserListItem[]
  resultCount: number
}

export type UserFormData = {
  id?: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  terms: Date | undefined
  role: string
  hasAccessToReports: Boolean
}

export type UserListProps = {
  service: string
  pageNumber: number
  pageSize: number
  sortField: string
  sortAscending: Boolean
  searchQuery: string
}

export const defaultProps: UserListProps = {
  service: "raksanappi",
  pageNumber: 0,
  pageSize: 10,
  sortField: "fullName",
  sortAscending: true,
  searchQuery: ""
}

export const getUsers = (
  props: UserListProps,
  markLoadingFailed: () => void
) => {
  return postJson("/api/users/search", props).pipe(
    map((result: AjaxResponse) => {
      const resp: UserListResponse = result.response
      return resp
    }),
    catchError((error: AjaxError) => {
      console.log(`Failed to fetch users. Error: ${error}`)

      markLoadingFailed()

      return []
    })
  )
}

export const UserListApi = () => {
  const halfSecond = 500 // milliseconds
  const subject = new Subject<UserListProps>()
  return {
    subscribe: (
      setState: (userListResponse: UserListResponse) => void,
      setLoading: (loading: boolean) => void
    ) =>
      subject
        .pipe(
          debounceTime(halfSecond),
          switchMap((userList: UserListProps) => {
            return getUsers(
              {
                ...defaultProps,
                searchQuery: userList.searchQuery,
                pageNumber: userList.pageNumber
              },
              () => {
                setState({
                  pageNumber: defaultProps.pageNumber,
                  pageSize: defaultProps.pageSize,
                  resultCount: 0,
                  users: []
                })
                setLoading(false)
              }
            )
          })
        )
        .subscribe((response: UserListResponse) => {
          setState(response)
          setLoading(false)
        }),
    load: (userList: UserListProps = defaultProps) => {
      subject.next(userList)
    }
  }
}

export const listItemToForm = (item: UserListItem): UserFormData => {
  const names = item.name.split(" ")
  return {
    id: item.id,
    firstName: names[0],
    lastName: names.slice(1).join(" "),
    email: item.email,
    phoneNumber: item.raksanappi.phone,
    terms: item.raksanappi.terms,
    role: item.raksanappi.role,
    hasAccessToReports: item.raksanappi.hasAccessToReports
  }
}
