import { AppState } from "../state"
import { Order } from "../../pages/orders/common/models"

enum SubmitState {
  NONE,
  SUBMITTED,
  SUBMITTING,
  FAILED
}

/**
 * ACTIONS
 */
export enum ActionTypes {
  SUBMIT_ORDER = "SUBMIT_ORDER",
  SUBMIT_ORDER_SUCCCESS = "SUBMIT_ORDER_SUCCCESS",
  SUBMIT_ORDER_FAILED = "SUBMIT_ORDER_FAILED",
  SUBMIT_ORDER_RESET = "SUBMIT_ORDER_RESET"
}

export interface SubmitOrderAction {
  type: ActionTypes.SUBMIT_ORDER
  payload: Order
}

export interface SubmitOrderSuccessAction {
  type: ActionTypes.SUBMIT_ORDER_SUCCCESS
}

export interface SubmitOrderFailedAction {
  type: ActionTypes.SUBMIT_ORDER_FAILED
}

export interface SubmitOrderResetAction {
  type: ActionTypes.SUBMIT_ORDER_RESET
}

export type OrderAction =
  | SubmitOrderAction
  | SubmitOrderSuccessAction
  | SubmitOrderFailedAction
  | SubmitOrderResetAction

/**
 * ACTION CREATORS
 */
export const submitOrder = (order: Order): SubmitOrderAction => ({
  type: ActionTypes.SUBMIT_ORDER,
  payload: order
})

export const submitOrderSuccess = (): SubmitOrderSuccessAction => ({
  type: ActionTypes.SUBMIT_ORDER_SUCCCESS
})

export const submitOrderFailed = (): SubmitOrderFailedAction => ({
  type: ActionTypes.SUBMIT_ORDER_FAILED
})

export const submitOrderReset = (): SubmitOrderResetAction => ({
  type: ActionTypes.SUBMIT_ORDER_RESET
})

/**
 * REDUCER
 */
export type OrderState = {
  submitState: SubmitState
}

export const initialState: OrderState = {
  submitState: SubmitState.NONE
}

export function orderReducer(
  state: OrderState = initialState,
  action: OrderAction
): OrderState {
  switch (action.type) {
    case ActionTypes.SUBMIT_ORDER:
      return {
        submitState: SubmitState.SUBMITTING
      }
    case ActionTypes.SUBMIT_ORDER_SUCCCESS:
      return {
        submitState: SubmitState.SUBMITTED
      }
    case ActionTypes.SUBMIT_ORDER_FAILED:
      return {
        submitState: SubmitState.FAILED
      }
    case ActionTypes.SUBMIT_ORDER_RESET:
      return initialState
    default:
      return state
  }
}

/**
 * SELECTORS
 */
export const isSubmitInProgress = ({ order }: AppState) =>
  order.submitState === SubmitState.SUBMITTING
export const isSubmittedSuccessfully = ({ order }: AppState) =>
  order.submitState === SubmitState.SUBMITTED
export const isSubmitFailed = ({ order }: AppState) =>
  order.submitState === SubmitState.FAILED
