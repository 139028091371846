import React from "react"
import { FormType } from "./models"
import { makeSwitchExhaustive } from "util/utils"
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"
import Button from "lib/button/button"

import "./ContactForm.sass"
import { Link } from "react-router-dom"
import { Worksite } from "store/worksite/models"
import {
  ContentContainer,
  ContainerWidth,
  BackgroundColor
} from "common/content-container/content-container"

import successVideo from "../../images/feedback_success.mp4"
import { useContactFormEvent } from "../../util/analytics"

interface ContactFormProps {
  type: FormType
  worksite: Worksite | undefined
}

const ContactForm = ({
  type,
  worksite,
  intl
}: ContactFormProps & WrappedComponentProps) => {
  const [formState, setFormState] = React.useState<"incomplete" | "complete">(
    "incomplete"
  )
  const [errors, setErrors] = React.useState("")
  const [text, setText] = React.useState("")
  useContactFormEvent(type)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [formState])

  const onSubmit = async () => {
    const contactPayload = {
      contactType: type,
      content: text,
      site: worksite ? worksite.targetIdentifier : undefined
    }
    const response = await fetch("/api/contacts", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(contactPayload)
    })
    if (response.ok) {
      setFormState("complete")
      setErrors("")
      setText("")
    } else {
      setErrors("Submitting contact failed")
    }
  }

  const title = ((type: FormType) => {
    switch (type) {
      case FormType.FEEDBACK:
        return <FormattedMessage id="contact.feedback" />
      case FormType.OFFER:
        return <FormattedMessage id="contact.offer" />
      case FormType.OTHER:
        return <FormattedMessage id="contact.other" />
      default:
        return makeSwitchExhaustive(type)
    }
  })(type)

  const description = ((type: FormType) => {
    switch (type) {
      case FormType.FEEDBACK:
        return <span> </span>
      case FormType.OFFER:
        return <FormattedMessage id="contact.form.description.offer" />
      case FormType.OTHER:
        return <span> </span>
      default:
        return makeSwitchExhaustive(type)
    }
  })(type)

  const placeholder = ((type: FormType) => {
    switch (type) {
      case FormType.FEEDBACK:
        return intl.formatMessage({ id: "contact.form.placeholder.feedback" })
      case FormType.OFFER:
        return intl.formatMessage({ id: "contact.form.placeholder.offer" })
      case FormType.OTHER:
        return intl.formatMessage({ id: "contact.form.placeholder.other" })
      default:
        return makeSwitchExhaustive(type)
    }
  })(type)

  if (formState === "complete") {
    return (
      <ContentContainer
        containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}
        backgroundColor={BackgroundColor.WHITE}
      >
        <div className="contact-form--success">
          <div>
            <video playsInline autoPlay muted className={"result-animation"}>
              <source src={successVideo} />
            </video>
          </div>
          <h1 className="lt-h4">
            <FormattedMessage id="contact.form.success.title" />
          </h1>
          <Link to="/contact/menu">
            <Button disabled={false} expand>
              {" "}
              <FormattedMessage id="contact.form.success.return" />{" "}
            </Button>
          </Link>
        </div>
      </ContentContainer>
    )
  }

  return (
    <ContentContainer
      containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}
      backgroundColor={BackgroundColor.LIGHT_GRAY}
    >
      <div className="contact-form">
        {errors && (
          <div className="contact_form__errors">
            {" "}
            <FormattedMessage id="contact.form.error" />{" "}
          </div>
        )}
        <h1 className="lt-h5">{title}</h1>
        <div className="contact-form__container">
          <div className="contact-form__info">{description}</div>
          <label className="contact-form__sub-title" htmlFor="contact_text">
            {" "}
            <FormattedMessage id="contact.form.label" />{" "}
          </label>
          <div className="contact-form__text">
            <textarea
              name="contact_text"
              id="contact_text"
              className="lt-textarea"
              rows={5}
              value={text}
              placeholder={placeholder}
              onChange={ev => setText(ev.target.value)}
            />
          </div>
          <Button expand disabled={text.length <= 0} onClick={onSubmit}>
            {" "}
            <FormattedMessage id={`contact.form.submit.${type}`} />{" "}
          </Button>
        </div>
      </div>
    </ContentContainer>
  )
}

export default injectIntl(ContactForm)
