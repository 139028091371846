import React from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import { endOfISOWeek, format, startOfISOWeek } from "date-fns"
import { getFirstAvailableWeek } from "pages/orders/order-utils"
import { getDayPickerLocalisations } from "i18n/localisation-utils"

import "react-day-picker/lib/style.css"
import "./week-picker.sass"

interface DayPickerProps {
  date: Date
  onDayChange: (day: Date) => void
}

export const WeekPickerInputWrapper = ({
  date,
  onDayChange
}: DayPickerProps) => {
  const dayPickerLocalisationValues = getDayPickerLocalisations("fi")
  return (
    <DayPickerInput
      value={date}
      inputProps={{ readOnly: true }}
      format="I"
      formatDate={(date, formatString) =>
        "Viikko " + format(date, formatString)
      }
      dayPickerProps={{
        selectedDays: [{ from: startOfISOWeek(date), to: endOfISOWeek(date) }],
        disabledDays: [
          { daysOfWeek: [0, 6] },
          { before: getFirstAvailableWeek() }
        ],
        firstDayOfWeek: 1,
        showOutsideDays: true,
        modifiers: { selected: date },
        modifiersStyles: {
          selected: { backgroundColor: "#64A60A", borderRadius: "0%" }
        },
        months: dayPickerLocalisationValues.months,
        weekdaysLong: dayPickerLocalisationValues.weekdaysLong,
        weekdaysShort: dayPickerLocalisationValues.weekdaysShort
      }}
      onDayChange={onDayChange}
    />
  )
}
