import React from "react"
import { connect, ConnectedProps } from "react-redux"
import { AppState } from "../store/state"
import {
  getUserAction,
  isInitializationStarted,
  isInitialized
} from "../store/auth/auth-reducer"

type PropsFromRedux = ConnectedProps<typeof connector>

class Component extends React.PureComponent<PropsFromRedux> {
  componentDidMount() {
    if (!this.props.initializationStarted) this.props.getUser()
  }

  render() {
    return null
  }
}

const connector = connect(
  (state: AppState) => ({
    initialized: isInitialized(state),
    initializationStarted: isInitializationStarted(state)
  }),
  { getUser: getUserAction }
)

export const AppInitializer = connector(Component)
