import * as React from "react"
import classNames from "classnames"
import "./button.sass"

type Props = {
  disabled?: boolean
  expand?: boolean
  additionalClass?: string
  onClick?: () => void
}

const Button: React.FunctionComponent<Props> = ({
  disabled = false,
  expand,
  additionalClass,
  onClick,
  children
}) => (
  <button
    disabled={disabled}
    className={classNames(
      "button",
      { "button--disabled": disabled, "button--expand": expand },
      additionalClass
    )}
    onClick={onClick}
  >
    {children}
  </button>
)

export default Button
