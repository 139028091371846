import { FormattedMessage } from "react-intl"
import { NavLink } from "react-router-dom"
import "./no-active-worksites.sass"

const NoActiveWorksites = () => {
  return (
    <div className="error-container error-container--no-active-worksite">
      <h1 className="lt-h5 error-container--no-active-worksite__heading">
        <FormattedMessage id="no-active-worksite" />
      </h1>
      <span className="error-container--no-active-worksite__guide">
        <FormattedMessage id="no-active-worksite-guide" />
        <b>
          <NavLink
            className="error-container--no-active-worksite__contact-link"
            to="/contact/other"
          >
            <FormattedMessage id="no-active-worksite-contact" />
          </NavLink>
        </b>
      </span>
    </div>
  )
}

export default NoActiveWorksites
