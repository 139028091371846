import React, { useEffect } from "react"
import { useMemo, useState } from "react"
import { Worksite } from "store/worksite/models"
import { usePhoneScreenSize } from "util/screen-size"
import { filterTransferDocumentsByWorksite } from "../document-utils"
import {
  SortDirection,
  SortFields,
  TransferDocumentApi,
  TransferDocumentsResult
} from "../documents-api"
import TransferDocumentDesktop from "./TransferDocumentDesktop"
import TransferDocumentMobile from "./TransferDocumentMobile"

interface TransferDocumentsProps {
  selectedWorksite: Worksite | undefined
}

const TransferDocuments = ({ selectedWorksite }: TransferDocumentsProps) => {
  const {
    documents,
    loadDocuments,
    loaded,
    loadFailed,
    sortDirection,
    sortField,
    sortCallback
  } = useTransferDocumentList()

  useEffect(
    () => loadDocuments(sortField, sortDirection),
    [loadDocuments, sortField, sortDirection]
  )

  const filteredDocuments = useMemo(
    () => filterTransferDocumentsByWorksite(documents, selectedWorksite),
    [documents, selectedWorksite]
  )

  return (
    <>
      {usePhoneScreenSize() ? (
        <TransferDocumentMobile
          documents={filteredDocuments}
          loadFailed={loadFailed}
          loaded={loaded}
        />
      ) : (
        <TransferDocumentDesktop
          documents={filteredDocuments}
          loadFailed={loadFailed}
          loaded={loaded}
          sortCallback={sortCallback}
          sortDirection={sortDirection}
        />
      )}
    </>
  )
}

export default TransferDocuments

const transferDocumentsApi = TransferDocumentApi()

function useTransferDocumentList() {
  const [state, setState] = useState<TransferDocumentsResult>({
    documents: [],
    loaded: false,
    loadFailed: false,
    totalCount: 0
  })
  const [sortField, setSortField] = React.useState<SortFields>(SortFields.date)
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(
    SortDirection.desc
  )

  useEffect(() => {
    const subscription = transferDocumentsApi.subscribe(
      setState,
      sortField,
      sortDirection
    )

    return () => subscription.unsubscribe()
  }, [setState, sortField, sortDirection])

  const sortCallback = (field: SortFields) => {
    const direction =
      sortDirection === SortDirection.desc
        ? SortDirection.asc
        : SortDirection.desc
    setSortDirection(direction)
    setSortField(field)
  }

  return {
    ...state,
    sortField,
    sortDirection,
    sortCallback,
    loadDocuments: transferDocumentsApi.load
  }
}
