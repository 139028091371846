import { ReactComponent as Facebook } from "./images/facebook.svg"
import { ReactComponent as Instagram } from "./images/instagram.svg"
import { ReactComponent as Linkedin } from "./images/linkedin.svg"
import { ReactComponent as Twitter } from "./images/twitter.svg"
import { ReactComponent as Youtube } from "./images/youtube.svg"

const SocialMedia = () => (
  <div className="footer__social-media">
    <a
      className="social-media-link"
      href="https://www.facebook.com/LassilaTikanoja/"
      aria-label="Facebook"
    >
      <Facebook />
    </a>
    <a
      className="social-media-link"
      href="http://www.linkedin.com/company/lassila-&-tikanoja"
      aria-label="LinkedIn"
    >
      <Linkedin />
    </a>
    <a
      className="social-media-link"
      href="https://www.youtube.com/channel/UCoAjlHd76gQQ_5g84Zffixw"
      aria-label="Youtube"
    >
      <Youtube />
    </a>
    <a
      className="social-media-link"
      href="https://twitter.com/lassilatikanoja"
      aria-label="Twitter"
    >
      <Twitter />
    </a>
    <a
      className="social-media-link"
      href="https://instagram.com/lassilatikanoja"
      aria-label="Instagram"
    >
      <Instagram />
    </a>
  </div>
)

export default SocialMedia
