import {
  BaseFormState,
  ContainerType,
  DesiredDeliveryTime
} from "../common/models"

export enum OtherWasteTypes {
  Other = "Muu jäte"
}

interface BaseOtherOrder {
  type: "other"
  targetIdentifier: string
  containerType: ContainerType.OTHER
  desiredDeliveryTime: DesiredDeliveryTime | null
  wasteType: OtherWasteTypes
  contactNumber: string
  extraInfo: string | null
}

export type OtherOrder = BaseOtherOrder

export type OtherFormState = BaseFormState
