import {
  BaseFormTypes,
  ContainerType,
  DesiredDeliveryTime
} from "../common/models"

export enum VaihtolavaWasteTypes {
  Metal = "Metalli",
  Energy = "Energia",
  ConcreteRock = "Betoni & Kivi",
  Plaster = "Kipsi",
  MixedWood = "Sekalainen puu",
  CleanWood = "Puhdas puu",
  RecycleWood = "Kierrätyspuu",
  CombustibleWaste = "Polttokelpoinen jäte",
  ConstructionWaste = "Rakennusjäte"
}

export enum VaihtolavaSpecialContainer {
  Kansilava = "Kansilava",
  Lokerolava = "Lokerolava",
  Kippilava = "Kippilava"
}

export enum VaihtolavaSpecialRequirements {
  lowSide = "Matalalaita",
  liftingLugs = "Nostokorvat"
}

interface BaseVaihtolavaOrder {
  type: BaseFormTypes
  targetIdentifier: string
  containerType: ContainerType.ROLL_OFF | VaihtolavaSpecialContainer
  desiredDeliveryTime: DesiredDeliveryTime | null
  wasteType: VaihtolavaWasteTypes
  callBefore: boolean
  contactNumber: string
  extraInfo: string | null
}

export interface ExistingVaihtolavaOrder extends BaseVaihtolavaOrder {
  specialContainer: VaihtolavaSpecialContainer | null
  containerNumber: string | undefined
}

export interface NewVaihtolavaOrder extends BaseVaihtolavaOrder {
  lowSide: boolean
  liftingLugs: boolean
}

export interface EmptyVaihtolavaOrder extends ExistingVaihtolavaOrder {
  moveDuringEmpty: boolean
  changeWasteTypeTo: VaihtolavaWasteTypes | null
}

export type VaihtolavaOrder =
  | NewVaihtolavaOrder
  | EmptyVaihtolavaOrder
  | ExistingVaihtolavaOrder
