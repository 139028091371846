import * as React from "react"
import { FormattedMessage } from "react-intl"
import { format, parseISO } from "date-fns"
import { Location } from "history"
import { NavLink, RouteProps } from "react-router-dom"
import { Subject, Subscription } from "rxjs"
import { debounceTime, mergeMap } from "rxjs/operators"
import { ContentContainer } from "common/content-container/content-container"
import { ContextNavigation } from "common/context-navigation/context-navigation"
import WorksiteSelector from "common/worksite-selector/worksite-selector"
import { connect } from "react-redux"
import { AppState } from "store/state"
import { selectedWorksite } from "store/worksite/worksite-reducer"
import { Worksite } from "store/worksite/models"
import { makeSwitchExhaustive } from "util/utils"
import {
  getOrders,
  ListingOrderType,
  OrderListType,
  OrdersResponse
} from "./orders-api"
import "./orders.sass"
import { getDateFormatForUI } from "../order-utils"
import { ReactComponent as ReceivedIcon } from "../../../images/received.svg"
import { ReactComponent as CompletedIcon } from "../../../images/completed.svg"
import { ReactComponent as InProgressIcon } from "../../../images/in_progress.svg"
import { ReactComponent as NotCompletedIcon } from "../../../images/not_completed.svg"
import { ReactComponent as ProcessingIcon } from "../../../images/processing.svg"
import { ReactComponent as UnknownIcon } from "../../../images/unknown.svg"

interface ReduxProps {
  selectedWorksite: Worksite | undefined
}

interface State {
  orderType: OrderListType
  orders: OrdersResponse
}

type Props = ReduxProps & RouteProps

const iconMapping = {
  received: ReceivedIcon,
  incomplete: ProcessingIcon,
  "started/reserved": InProgressIcon,
  "can't complete": NotCompletedIcon,
  "partially done": CompletedIcon,
  done: CompletedIcon,
  "done with additions": CompletedIcon,
  unknown: UnknownIcon
}

class Component extends React.PureComponent<Props, State> {
  private subject: Subject<{}>
  private subscription: Subscription | null = null

  constructor(props: Props) {
    super(props)

    this.subject = new Subject()
    this.state = {
      orderType: this.getOrderType(props.location),
      orders: {
        archivedOrders: [],
        openOrders: []
      }
    }
  }

  componentDidMount(): void {
    this.subscription = this.subject
      .pipe(
        debounceTime(300),
        mergeMap(() => {
          const worksiteId = this.props.selectedWorksite
            ? this.props.selectedWorksite.targetIdentifier
            : undefined

          return getOrders(worksiteId)
        })
      )
      .subscribe(orders => this.setState({ orders }))

    this.subject.next()
    window.scrollTo(0, 0)
  }

  componentWillUnmount(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const newOrdersType = this.getOrderType(this.props.location)

    if (newOrdersType !== this.state.orderType) {
      this.setState({ orderType: newOrdersType })
    }

    if (
      this.props.selectedWorksite?.targetIdentifier !==
      prevProps.selectedWorksite?.targetIdentifier
    ) {
      this.subject.next()
    }
  }

  getOrderType(location?: Location): OrderListType {
    return location && location.pathname.match(/history$/)
      ? OrderListType.ALL
      : OrderListType.OPEN
  }

  formatISODate(date: string) {
    return format(parseISO(date), getDateFormatForUI())
  }

  getTitle(orderType: ListingOrderType) {
    switch (orderType) {
      case ListingOrderType.NEW:
        return <FormattedMessage id="order.order-type.new" />
      case ListingOrderType.EMPTY:
      case ListingOrderType.LEGACY_RAKSANAPPI_EMPTY:
        return <FormattedMessage id="order.order-type.empty" />
      case ListingOrderType.MOVE:
        return <FormattedMessage id="order.order-type.move" />
      case ListingOrderType.REMOVE:
        return <FormattedMessage id="order.order-type.remove" />
      case ListingOrderType.OTHER:
        return <FormattedMessage id="order.order-type.other" />
      case ListingOrderType.LEGACY_NEW:
      case ListingOrderType.LEGACY_EMPTY:
      case ListingOrderType.LEGACY_MOVE:
      case ListingOrderType.LEGACY_REMOVE:
        return orderType
      default:
        makeSwitchExhaustive(orderType)
    }
  }

  showInterval(
    isDryToilet: boolean,
    isFrontContainer: boolean,
    orderType: ListingOrderType
  ) {
    return (
      (isFrontContainer &&
        (orderType === "empty" || orderType === "emptying")) ||
      (isDryToilet && (orderType === "empty" || orderType === "emptying"))
    )
  }

  render() {
    const title =
      this.state.orderType === OrderListType.OPEN
        ? "orders.open.title"
        : "orders.history.title"
    const Home = () => (
      <a className={"lt-text-normal-hyperlink"} href="/orders/create">
        etusivulta
      </a>
    )
    const EmptyOrderListMessage = () => {
      return this.state.orderType === OrderListType.OPEN ? (
        <h6 className="lt-text-normal-empty-list">
          <FormattedMessage id={"order.open.list.empty"} />
          <Home />.
        </h6>
      ) : (
        <h6 className="lt-text-normal-empty-list">
          <FormattedMessage id={"order.history.list.empty"} />
          <Home />.
        </h6>
      )
    }
    const orders =
      this.state.orderType === OrderListType.OPEN
        ? this.state.orders.openOrders
        : this.state.orders.archivedOrders

    return (
      <>
        <ContextNavigation>
          <NavLink
            className="context-navigation__navlink"
            activeClassName="context-navigation__navlink--active"
            exact={true}
            to="/orders"
          >
            <FormattedMessage id="orders.open.title" />
          </NavLink>
          <NavLink
            className="context-navigation__navlink"
            activeClassName="context-navigation__navlink--active"
            exact={true}
            to="/orders/history"
          >
            <FormattedMessage id="orders.history.title" />
          </NavLink>
        </ContextNavigation>
        <ContentContainer>
          <div className="order-page__worksite-selector lt-margin-bottom-m">
            <WorksiteSelector />
          </div>
          <h1 className="lt-h4">
            <FormattedMessage id={title} />
          </h1>
          {orders.length === 0 ? (
            <EmptyOrderListMessage />
          ) : (
            orders.map(
              ({
                containerType,
                isDryToilet,
                isFrontContainer,
                isOther,
                wasteType,
                createdAt,
                type,
                id,
                desiredDeliveryTime,
                desiredDeliveryTimeTo,
                user,
                emptyInterval,
                status
              }) => {
                const deliveryTime = () => {
                  if (!!status.deliveryDate) {
                    return this.formatISODate(status.deliveryDate)
                  } else if (!desiredDeliveryTime) {
                    return this.showInterval(
                      isDryToilet,
                      isFrontContainer,
                      type
                    ) ? (
                      <FormattedMessage
                        id="order.empty-interval"
                        values={{ interval: emptyInterval }}
                      />
                    ) : (
                      "N/A"
                    )
                  } else
                    return desiredDeliveryTimeTo
                      ? this.formatISODate(desiredDeliveryTime) +
                          "-" +
                          this.formatISODate(desiredDeliveryTimeTo)
                      : this.formatISODate(desiredDeliveryTime)
                }
                const Icon = iconMapping[status.orderStatus]
                const orderStatusLabel = (
                  <FormattedMessage
                    id={`order.order-status.${status.orderStatus}`}
                  />
                )
                const cantComplete = status.orderStatus === "can't complete"

                return (
                  <div
                    className="orders-listing-order"
                    key={"order-listing-" + id}
                  >
                    <h3 className="orders-listing-order__title">
                      {this.getTitle(type)}
                    </h3>
                    <div className="label-value-row">
                      <span className="order-label">
                        <FormattedMessage id="order.created-at" />:{" "}
                      </span>
                      <span className="order-value">
                        {this.formatISODate(createdAt)}
                      </span>
                    </div>
                    <div className="label-value-row">
                      <span className="order-label">
                        <FormattedMessage id="order.subscriber" />:{" "}
                      </span>
                      <span className="order-value">
                        {user ? user.name : ""}
                      </span>
                    </div>
                    {!isDryToilet && !isOther && (
                      <div className="label-value-row">
                        <span className="order-label">
                          <FormattedMessage id="order.wastetype" />:{" "}
                        </span>
                        <span className="order-value orders-listing-order__text">
                          {wasteType}
                        </span>
                      </div>
                    )}
                    {!isOther && (
                      <div className="label-value-row">
                        <span className="order-label">
                          <FormattedMessage id="order.containerType" />:{" "}
                        </span>
                        <span className="order-value orders-listing-order__text">
                          {containerType}
                        </span>
                      </div>
                    )}
                    {!cantComplete && status.orderStatus !== "unknown" && (
                      <div className="label-value-row">
                        <span className="order-label">
                          <FormattedMessage
                            id={
                              this.state.orderType === OrderListType.ALL
                                ? "order.deliverytimeHistory"
                                : "order.deliverytime"
                            }
                          />
                          :{" "}
                        </span>
                        <span className="order-value">{deliveryTime()}</span>
                      </div>
                    )}
                    <div className="order-status-wrapper">
                      <Icon />
                      {orderStatusLabel}
                    </div>
                    {!!status.cantBeDoneReason && (
                      <div className="order-status-reason">
                        <FormattedMessage
                          id={
                            "task.details.visitWithoutAction.reason." +
                            status.cantBeDoneReason.name
                          }
                        />
                      </div>
                    )}
                  </div>
                )
              }
            )
          )}
        </ContentContainer>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  selectedWorksite: selectedWorksite(state)
})

export const OrdersPage = connect(
  mapStateToProps,
  {}
)(Component as React.ComponentClass<ReduxProps>)
