import {
  BaseFormState,
  BaseFormTypes,
  ContainerType,
  DesiredDeliveryTime,
  EmptyInterval
} from "../common/models"
import { EmptyOptions } from "../common/models"

export type DryToiletFormTypes =
  | BaseFormTypes.UNSELECTED
  | BaseFormTypes.NEW
  | BaseFormTypes.EMPTYING
  | BaseFormTypes.REMOVE

export enum DryToiletType {
  summer = "Bajamaja (kesä)",
  winter = "Bajamaja (talvi)"
}

export enum DryToiletWasteType {
  sludge = "liete"
}

interface BaseDryToiletOrder {
  type: DryToiletFormTypes
  targetIdentifier: string
  containerType: ContainerType.DRY_TOILET
  desiredDeliveryTime: DesiredDeliveryTime | null
  wasteType: DryToiletWasteType
  specialContainer: DryToiletType | null
  callBefore: boolean
  contactNumber: string
  extraInfo: string | null
}

export interface NewDryToiletOrder extends BaseDryToiletOrder {
  emptyInterval: EmptyInterval | null
  type: BaseFormTypes.NEW
}

export interface EmptyDryToiletOrder extends BaseDryToiletOrder {
  emptyInterval: EmptyInterval | null
  type: BaseFormTypes.EMPTYING
}

export interface RemoveDryToiletOrder extends BaseDryToiletOrder {
  type: BaseFormTypes.REMOVE
}

export type DryToiletOrder =
  | NewDryToiletOrder
  | EmptyDryToiletOrder
  | RemoveDryToiletOrder

export interface DryToiletFormState extends BaseFormState {
  dryToiletType: DryToiletType
  emptyInterval: EmptyInterval | null
  selectedEmptyOption: EmptyOptions
}
