import React, { useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import WorksiteSelector from "common/worksite-selector/worksite-selector"
import iconEllipsis from "common/images/icon-large-ellipsis.svg"
import iconVaihtolava from "common/images/icon-large-vaihtolava.svg"
import iconJassikat from "common/images/icon-large-jassikat.svg"
import iconDryToilet from "common/images/icon-large-dry-toilet.svg"
import iconFrontloader from "common/images/icon-large-frontloader.svg"
import iconCompactor from "common/images/icon-large-compactor.svg"
import "./OrderMainPage.sass"
import {
  ContainerWidth,
  ContentContainer
} from "../../common/content-container/content-container"
import { Worksite } from "../../store/worksite/models"
import { useDispatch, useSelector } from "react-redux"
import {
  allowedPostalCodes,
  fetchAllowedPostalCodes
} from "../../store/worksite/worksite-reducer"

export type OrderMainPageProps = {
  worksite: Worksite
}

export type GridItem = {
  path: string
  icon: string
  messageId: string
  isJassika?: boolean
}

const GRID_DATA: GridItem[] = [
  {
    path: "/orders/vaihtolava",
    icon: iconVaihtolava,
    messageId: "order-page.roll-off"
  },
  {
    path: "/orders/jassikat",
    icon: iconJassikat,
    messageId: "order-page.jassikat",
    isJassika: true
  },
  {
    path: "/orders/dry-toilet",
    icon: iconDryToilet,
    messageId: "order-page.dry-toilet"
  },
  {
    path: "/orders/frontloader",
    icon: iconFrontloader,
    messageId: "order-page.frontloader-container"
  },
  {
    path: "/orders/compactor",
    icon: iconCompactor,
    messageId: "order-page.compactor"
  },
  {
    path: "/orders/other",
    icon: iconEllipsis,
    messageId: "order-page.other"
  }
]

const OrderMainPage = ({ worksite }: OrderMainPageProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAllowedPostalCodes())
  }, [dispatch])
  const allowedCodes = useSelector(allowedPostalCodes)
  const isJassikaEnabled = useMemo(
    () => allowedCodes.indexOf(worksite.postCode) >= 0,
    [allowedCodes, worksite]
  )

  const grid = useMemo(() => {
    return (
      <div className="grid container__mobile-stretch-elem">
        {GRID_DATA.map((gi, key) => getGridItem(gi, key, isJassikaEnabled))}
      </div>
    )
  }, [isJassikaEnabled])

  return (
    <ContentContainer containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}>
      <div className="order-page__worksite-selector lt-margin-bottom-m">
        <WorksiteSelector />
      </div>
      {grid}
    </ContentContainer>
  )
}

const getGridItem = (
  gi: GridItem,
  key: number,
  isJassikatEnabled: boolean
): JSX.Element => {
  const showJassika = (!isJassikatEnabled && !gi.isJassika) || isJassikatEnabled
  if (!showJassika) return <React.Fragment key={key} />
  return (
    <div className={`grid__item_33`} key={key}>
      <Link className="box box--square" to={gi.path}>
        <div className="box__content">
          <img className="box__icon" src={gi.icon} alt="" />
          <h2 className="lt-h6 lt-margin-top-none lt-h6--narrow">
            <FormattedMessage id={gi.messageId} />
          </h2>
        </div>
      </Link>
    </div>
  )
}

export default OrderMainPage
