import { FormattedMessage } from "react-intl"
import { ReactComponent as IconCaretDown } from "common/images/caret-down.svg"
import LoadingSpinner from "common/loading-spinner/loading-spinner"
import { ReactComponent as DownloadButton } from "../../../images/icon-download-button.svg"
import { SortDirection, SortFields, TransferDocument } from "../documents-api"
import { useCallback, useMemo } from "react"
import { format } from "date-fns"
import FormatAddress from "../common/FormatAddress"

interface TransferDocumentDesktopProps {
  documents: TransferDocument[]
  loaded: boolean
  loadFailed: boolean
  sortDirection: SortDirection
  sortCallback: (sortField: SortFields) => void
}

const TransferDocumentDesktop = ({
  documents,
  loaded,
  loadFailed,
  sortDirection,
  sortCallback
}: TransferDocumentDesktopProps) => {
  const getLoadFailedDesktop = useMemo(
    () => (
      <tr className="document document--desktop document--desktop-no-document">
        <td
          colSpan={4}
          className="document__value document__value--desktop document__value--desktop-no-document"
        >
          <FormattedMessage id="common.error" />
        </td>
      </tr>
    ),
    []
  )

  const getNoDocumentsDesktop = useMemo(() => {
    return (
      <tr>
        <td
          colSpan={4}
          className="document__value document__value--desktop document__value--desktop-no-document"
        >
          <FormattedMessage id="documents.transfer.no-document" />
        </td>
      </tr>
    )
  }, [])

  const getDesktopRows = useCallback(
    (documents: TransferDocument[], loaded: boolean, loadFailed: boolean) => {
      if (loadFailed) return getLoadFailedDesktop
      if (loaded && documents.length === 0) return getNoDocumentsDesktop
      return loaded ? (
        documents.map(document => (
          <tr className="document document--desktop" key={document.id}>
            <td className="document__value document__value--desktop">
              {format(document.date, "dd.MM.yyyy")}
            </td>
            <td className="document__value document__value--desktop document__value--desktop-bold">
              {document.worksiteDescription}
            </td>
            <td className="document__value document__value--desktop">
              <FormatAddress document={document} />
            </td>
            <td className="document__value document__value--desktop">
              <a href={document.url} target="_blank" rel="noopener noreferrer">
                <DownloadButton />
              </a>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td>
            <LoadingSpinner className="loading-spinner--line" />
          </td>
          <td>
            <LoadingSpinner className="loading-spinner--line" />
          </td>
          <td>
            <LoadingSpinner className="loading-spinner--line" />
          </td>
        </tr>
      )
    },
    [getLoadFailedDesktop, getNoDocumentsDesktop]
  )

  return (
    <table className="documents documents--desktop">
      <thead>
        <tr>
          <th
            className="document__header document__header--desktop document__header--desktop-date document__header--sort"
            onClick={() => sortCallback(SortFields.date)}
          >
            <FormattedMessage id="documents.transfer.deliveryDate" />{" "}
            {sortDirection === SortDirection.asc ? (
              <IconCaretDown className="document__sort-asc" />
            ) : (
              <IconCaretDown />
            )}
          </th>
          <th className="document__header document__header--desktop">
            <FormattedMessage id="documents.common.worknumber" />
          </th>
          <th className="document__header document__header--desktop">
            <FormattedMessage id="documents.common.site" />
          </th>
          <th className="document__header document__header--download-desktop" />
        </tr>
      </thead>
      <tbody>{getDesktopRows(documents, loaded, loadFailed)}</tbody>
    </table>
  )
}

export default TransferDocumentDesktop
