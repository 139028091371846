import {
  BaseFormState,
  BaseFormTypes,
  ContainerType,
  DesiredDeliveryTime,
  EmptyInterval,
  EmptyOptions
} from "../common/models"

export type FrontloaderFormTypes =
  | BaseFormTypes.UNSELECTED
  | BaseFormTypes.NEW
  | BaseFormTypes.EMPTYING
  | BaseFormTypes.REMOVE

export enum FrontloaderWasteTypes {
  EnergyWaste = "Energia",
  Construction = "Rakennussekajäte",
  Cardboard = "Pahvi",
  CombustibleWaste = "Polttokelpoinen jäte"
}

interface BaseFrontloaderOrder {
  type: FrontloaderFormTypes
  targetIdentifier: string
  containerType: ContainerType.FRONTLOADER
  desiredDeliveryTime: DesiredDeliveryTime | null
  wasteType: FrontloaderWasteTypes
  callBefore: boolean
  contactNumber: string
  extraInfo: string | null
}

export interface NewFrontloaderOrder extends BaseFrontloaderOrder {
  emptyInterval: EmptyInterval | null
  type: BaseFormTypes.NEW
}

export interface EmptyFrontloaderOrder extends BaseFrontloaderOrder {
  emptyInterval: EmptyInterval | null
  type: BaseFormTypes.EMPTYING
}

export interface RemoveFrontloaderOrder extends BaseFrontloaderOrder {
  type: BaseFormTypes.REMOVE
}

export type FrontloaderOrder =
  | NewFrontloaderOrder
  | EmptyFrontloaderOrder
  | RemoveFrontloaderOrder

export interface FrontloaderFormState extends BaseFormState {
  selectedWasteType: FrontloaderWasteTypes
  changeWasteTypeTo: FrontloaderWasteTypes | null
  emptyInterval: EmptyInterval | null
  selectedEmptyOption: EmptyOptions
}
