import * as React from "react"
import { Link, NavLink } from "react-router-dom"
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"
import logo from "common/images/lt-raksanappi-logo-rgb.svg"
import DropdownPanel from "common/dropdown-panel/dropdown-panel"
import "./header.sass"
import {
  name,
  logout,
  LogoutAction,
  isTermsAccepted,
  isADUser
} from "store/auth/auth-reducer"
import { connect, ConnectedProps } from "react-redux"
import { AppState } from "../../store/state"
import classNames from "classnames"

type OwnProps = {
  isTopBannerNotificationActive?: boolean
}

type Props = WrappedComponentProps & PropsFromRedux & OwnProps

type BurgerMenuProps = {
  username: string
  logout: () => LogoutAction
}

const isOrderListPage = (path: string) =>
  path === "/orders" || path === "/orders/history"

export const PrimaryNavigation = () => {
  return (
    <ul className="primary-nav">
      <li className="primary-nav__item">
        <NavLink
          to="/orders/create"
          className="primary-nav__link primary-nav__link--frontpage"
          activeClassName="primary-nav__link--active"
        >
          <span className="primary-nav__icon primary-nav__icon--frontpage visible-phone" />
          <FormattedMessage id="routes.frontpage" />
        </NavLink>
      </li>
      <li className="primary-nav__item">
        <NavLink
          to="/orders"
          isActive={(_match, location) => isOrderListPage(location.pathname)}
          className="primary-nav__link primary-nav__link--orders"
          activeClassName="primary-nav__link--active"
        >
          <span className="primary-nav__icon primary-nav__icon--orders visible-phone" />
          <FormattedMessage id="routes.orders" />
        </NavLink>
      </li>
      <li className="primary-nav__item">
        <NavLink
          isActive={(_match, location) =>
            ["/archive", "/sorting"].includes(location.pathname)
          }
          to="/archive"
          className="primary-nav__link primary-nav__link--archive"
          activeClassName="primary-nav__link--active"
        >
          <span className="primary-nav__icon primary-nav__icon--archive visible-phone" />
          <FormattedMessage id="routes.archive" />
        </NavLink>
      </li>
    </ul>
  )
}

export const BurgerMenu = ({ logout, username }: BurgerMenuProps) => {
  const ynetUrl = process.env.REACT_APP_YNET_URL
  const omaltUrl = process.env.REACT_APP_OMALT_URL
  return (
    <DropdownPanel
      modifierClass="dropdown-panel--burger"
      trigger={({ isOpen }) => (
        <>
          <div className="dropdown-panel__trigger-icon" />
        </>
      )}
      content={({ closeMenu }) => (
        <>
          <div className="dropdown-panel__caret" />
          <ul className="nav-list">
            <li className="nav-list__item nav-list__item--heading">
              {username}
            </li>
            <li className="nav-list__item">
              <Link
                className="nav-list__link"
                to="/contact/menu"
                onClick={closeMenu}
              >
                <span className="nav-list__icon nav-list__icon--contact" />
                <FormattedMessage id="burgermenu.contact" />
              </Link>
            </li>
            <li className="nav-list__item">
              <Link className="nav-list__link" to="" onClick={logout}>
                <span className="nav-list__icon nav-list__icon--logout" />
                <FormattedMessage id="burgermenu.logout" />
              </Link>
            </li>
            <li className="nav-list__item nav-list__item--heading">
              <FormattedMessage id="burgermenu.other-services" />
            </li>
            <li className="nav-list__item">
              <a href={omaltUrl} className="nav-list__link">
                <span className="nav-list__icon nav-list__icon--omalt" />
                <FormattedMessage id="burgermenu.omalt" />
              </a>
            </li>
            <li className="nav-list__item">
              <a href={ynetUrl} className="nav-list__link">
                <span className="nav-list__icon nav-list__icon--ynet" />
                <FormattedMessage id="burgermenu.ynet" />
              </a>
            </li>
          </ul>
        </>
      )}
    />
  )
}

class Component extends React.PureComponent<Props, {}> {
  render() {
    const {
      logout: logoutAction,
      username,
      navigationEnabled,
      isADUser,
      isTopBannerNotificationActive = false
    } = this.props
    return (
      <header
        className={classNames("primary-header", {
          "--with-top-banner": isTopBannerNotificationActive
        })}
      >
        <div className="primary-header__container">
          <div className="primary-header__left">
            <NavLink to="/orders/create" className="primary-header__logo">
              <img src={logo} alt="L&amp;T Raksanappi" />
            </NavLink>
          </div>
          <div className="primary-header__middle">
            {navigationEnabled && !isADUser && <PrimaryNavigation />}
          </div>
          <div className="primary-header__right">
            {navigationEnabled && (
              <BurgerMenu
                username={username}
                logout={() => logoutAction(true)}
              />
            )}
          </div>
        </div>
      </header>
    )
  }
}

const connector = connect(
  (state: AppState) => ({
    username: name(state),
    navigationEnabled: isTermsAccepted(state),
    isADUser: isADUser(state)
  }),
  { logout }
)

type PropsFromRedux = ConnectedProps<typeof connector>

export const Header = connector(injectIntl(Component))
