import { Worksite } from "../../store/worksite/models"
import React from "react"

export interface WorksiteProps {
  worksite: Worksite
  showCaret: boolean
}

export const WorksiteInfo = ({ worksite, showCaret }: WorksiteProps) => {
  const addressDetails = postCodeAndCity(worksite.postCode, worksite.city)
  return (
    <div className="worksite-selector__trigger">
      <div>
        {worksite.siteIdentifier.length > 0
          ? worksite.siteIdentifier
          : worksite.streetName}
      </div>
      {showCaret ? <div className="worksite-selector__caret" /> : null}
      <div className="worksite-selector__address">
        <div>{worksite.streetName}</div>
        {addressDetails ? <div>{addressDetails}</div> : null}
      </div>
    </div>
  )
}

const postCodeAndCity = (postCode: string, city: string) => {
  if (postCode.length > 0 && city.length > 0) return `${postCode}, ${city}`
  else if (postCode.length > 0) return postCode
  else if (city.length > 0) return city
  else return null
}
