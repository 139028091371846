import { makeAjax } from "store/http"
import { AjaxResponse } from "rxjs/ajax"
import { map } from "rxjs/operators"
import { FrontloaderWasteTypes } from "../frontloader/models"
import { CompactorWasteTypes } from "../compactor/models"
import { VaihtolavaWasteTypes } from "../vaihtolava/models"
import { EmptyInterval } from "../common/models"

export enum OrderListType {
  OPEN,
  ALL
}

export enum ListingOrderType {
  NEW = "new",
  EMPTY = "emptying",
  MOVE = "move",
  REMOVE = "remove",
  OTHER = "other",
  LEGACY_NEW = "uusi",
  LEGACY_EMPTY = "tyhjennys",
  LEGACY_MOVE = "siirto",
  LEGACY_REMOVE = "poisvienti",
  LEGACY_RAKSANAPPI_EMPTY = "empty" // Now that raksanappi is connected to polar we use "emptying", but we still want to show old orders correctly
}

export interface User {
  name: string
}

export type OrderStatus = {
  latestTCSStatusUpdate: string
  latestTCSStatusUpdateAttempt: string
  cantBeDoneReason?: {
    name: string
  }
  orderStatus:
    | "received"
    | "incomplete"
    | "started/reserved"
    | "can't complete"
    | "partially done"
    | "done"
    | "done with additions"
    | "unknown"
  ticketId: string
  deliveryDate: string
}

export interface OrderListing {
  id: string
  type: ListingOrderType
  containerType: string
  wasteType: VaihtolavaWasteTypes | CompactorWasteTypes | FrontloaderWasteTypes
  desiredDeliveryTime: string
  desiredDeliveryTimeTo?: string
  createdAt: string
  emptyInterval: EmptyInterval | undefined
  isDryToilet: boolean
  isFrontContainer: boolean
  isOther: boolean
  user?: User
  status: OrderStatus
}

export type OrdersResponse = {
  archivedOrders: OrderListing[]
  openOrders: OrderListing[]
}

export const getOrders = (worksiteId: string | undefined) => {
  const requestUrl = worksiteId
    ? "/api/orders?worksites=" + worksiteId
    : "/api/orders"
  return makeAjax({
    url: requestUrl
  }).pipe(map((result: AjaxResponse): OrdersResponse => result.response))
}
