import {
  BaseFormState,
  BaseFormTypes,
  ContainerType,
  DesiredDeliveryTime
} from "../common/models"

export enum CompactorWasteTypes {
  EnergyWaste = "Energia",
  Cardboard = "Pahvi",
  Plastic = "Muovi",
  CombustibleWaste = "Polttokelpoinen jäte"
}

export enum CompactorSpecialContainer {
  Lokeropuristin = "Lokeropuristin"
}

interface BaseCompactorOrder {
  type: BaseFormTypes
  targetIdentifier: string
  containerType: ContainerType.COMPACTOR
  desiredDeliveryTime: DesiredDeliveryTime | null
  wasteType: CompactorWasteTypes
  callBefore: boolean
  contactNumber: string
  extraInfo: string | null
}

export interface ExistingCompactorOrder extends BaseCompactorOrder {
  specialContainer: CompactorSpecialContainer | null
}

export interface NewCompactorOrder extends BaseCompactorOrder {}

export interface EmptyCompactorOrder extends ExistingCompactorOrder {
  moveDuringEmpty: boolean
}

export type CompactorOrder =
  | NewCompactorOrder
  | EmptyCompactorOrder
  | ExistingCompactorOrder

export interface CompactorFormState extends BaseFormState {
  specialContainer: CompactorSpecialContainer | null
  selectedWasteType: CompactorWasteTypes
  changeWasteTypeTo: CompactorWasteTypes | null
}
