import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import LoadingSpinner from "common/loading-spinner/loading-spinner"
import Button from "lib/button/button"
import { defaultProps, UserListApi, UserListResponse } from "./sales-view-api"
import { Pagination } from "./users-table/Pagination"
import { SearchField } from "./users-table/SearchField"
import { UsersTable } from "./users-table/UsersTable"

import "./SalesViewPage.sass"

const userListApi = UserListApi()

export const SalesViewPage: React.FC = () => {
  const intl = useIntl()
  const salesUserManagementHeader = useMemo(
    () => intl.formatMessage({ id: "sales.user-management" }),
    [intl]
  )
  const salesInviteUserButtonLabel = useMemo(
    () => intl.formatMessage({ id: "sales.invite-user-button" }),
    [intl]
  )
  const [userListResponse, setUserListResponse] = useState<UserListResponse>({
    pageNumber: defaultProps.pageNumber,
    pageSize: defaultProps.pageSize,
    users: [],
    resultCount: 0
  })
  const [pageNumber, setPageNumber] = useState(defaultProps.pageNumber)
  const [searchQuery, setSearchQuery] = useState(defaultProps.searchQuery)
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const subscription = userListApi.subscribe(setUserListResponse, setLoading)
    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    setLoading(true)
    userListApi.load({
      ...defaultProps,
      searchQuery,
      pageNumber
    })
  }, [searchQuery, pageNumber])

  const currentPageUsersCount = (userListResponse?.users || []).length

  return (
    <div className="content-container background-color--gray">
      <div
        className="
        content-container__inner
        content-container__inner--width-restrict
        content-container__inner--mobile-stretch"
      >
        <h1 className="lt-h4">{salesUserManagementHeader}</h1>
        <div className="user-management-controls__container">
          <div>
            <Button
              disabled={false}
              onClick={() => history.push("/invite-user")}
            >
              {salesInviteUserButtonLabel}
            </Button>
          </div>
          <div className="users-table-controls__container">
            <div>
              <SearchField
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
            {currentPageUsersCount > 0 && !isLoading ? (
              <Pagination
                setPageNumber={setPageNumber}
                pageNumber={userListResponse.pageNumber}
                totalPages={Math.ceil(
                  userListResponse.resultCount / defaultProps.pageSize
                )}
              />
            ) : null}
          </div>
        </div>
        {isLoading ? (
          <div className="loading-spinner__container">
            <LoadingSpinner className="loading-spinner--line" />
          </div>
        ) : currentPageUsersCount > 0 ? (
          <div className="background-color--white">
            <UsersTable users={userListResponse.users} />
          </div>
        ) : (
          <div className="lt-h5 no-user-search-results-text__container">
            {intl.formatMessage({ id: "sales.no-user-search-results" })}
          </div>
        )}
      </div>
    </div>
  )
}
