import * as React from "react"
import "./clickable-div.sass"

type EventHandler = (
  event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
) => void

export interface Clickable {
  disabled?: boolean
  handler: EventHandler
  refCallback?: (ref: HTMLDivElement | null) => void
}

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Clickable

function spaceAndEnterKeyEventHandler(
  handler: EventHandler
): (event: React.KeyboardEvent<HTMLDivElement>) => void {
  return (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault()
      handler(event)
    }
  }
}

/**
 * Clickable div that is also usable with the keyboard.
 */
export default class ClickableDiv extends React.Component<Props, {}> {
  render() {
    const { disabled, handler, refCallback, ...rest } = this.props
    return (
      <div
        {...rest}
        aria-disabled={!!disabled}
        className={
          `${this.props.className} clickable` + (disabled ? " disabled" : "")
        }
        role={this.props.role || "button"}
        tabIndex={0}
        onKeyPress={e =>
          disabled ? {} : spaceAndEnterKeyEventHandler(this.props.handler)(e)
        }
        onClick={e => (disabled ? {} : handler(e))}
        ref={refCallback}
      />
    )
  }
}
