import React, { useEffect } from "react"
import { connect } from "react-redux"
import { AppState } from "store/state"
import {
  isSubmittedSuccessfully,
  submitOrder,
  submitOrderReset
} from "store/order/order-reducer"
import { Order } from "../common/models"
import { Worksite } from "store/worksite/models"
import OtherForm from "./OtherForm"
import { OtherFormState } from "./models"
import { useHistory } from "react-router"
import OrderConfirm from "../common/OrderConfirm"
import OrderSuccess from "../common/OrderSuccess"
import { useGA } from "@kettu/ui-components"

interface FrontloaderBaseProps {
  worksite: Worksite
}

interface ReduxProps {
  isSubmittedSuccessfully: boolean
  submitOrder: (order: Order) => void
  submitOrderReset: () => void
}

const PRODUCT_TYPE = "puristimet"

const OtherBase = ({
  isSubmittedSuccessfully,
  submitOrder,
  worksite,
  submitOrderReset
}: FrontloaderBaseProps & ReduxProps) => {
  const ga = useGA()
  useEffect(() => {
    ga.push({
      event: "order_step_1",
      type: "order",
      product_type: PRODUCT_TYPE
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [formState, setFormState] = React.useState<OtherFormState | null>(null)
  const [order, setOrder] = React.useState<Order | null>(null)
  const history = useHistory()

  useEffect(() => {
    return function cleanup() {
      submitOrderReset()
    }
  }, [submitOrderReset])

  const cancelOrder = () => setOrder(null)
  const resetOrder = () => {
    submitOrderReset()
    setFormState(null)
    history.push("/create")
  }

  const onFormSubmitClick = (order: Order, formState: OtherFormState) => {
    setOrder(order)
    setFormState(formState)
    window.scrollTo(0, 0)
  }

  const onSubmitOrderClick = (order: Order) => {
    submitOrder(order)
  }

  if (isSubmittedSuccessfully) {
    return <OrderSuccess onCancel={resetOrder} />
  }

  if (order) {
    return (
      <OrderConfirm
        titleId="order.confirm.other"
        order={order}
        worksite={worksite}
        onCancel={cancelOrder}
        onSubmit={onSubmitOrderClick}
      />
    )
  }

  return (
    <OtherForm
      onSubmit={onFormSubmitClick}
      propFormState={formState}
      onCancel={resetOrder}
      worksite={worksite}
    />
  )
}

const connector = connect(
  (state: AppState) => ({
    isSubmittedSuccessfully: isSubmittedSuccessfully(state)
  }),
  { submitOrder, submitOrderReset }
)

export default connector(OtherBase)
