import { FormattedMessage } from "react-intl"
import { ContentContainer } from "../../common/content-container/content-container"
import { ContextNavigation } from "../../common/context-navigation/context-navigation"
import { NavLink, useLocation } from "react-router-dom"
import NoActiveWorksites from "common/no-active-worksites/no-active-worksites"
import { AppState } from "store/state"
import { selectedWorksite } from "store/worksite/worksite-reducer"
import { worksites } from "store/auth/auth-reducer"
import { useSelector } from "react-redux"
import TransferDocumentPage from "./TransferDocumentPage"
import SortingDocumentsPage from "./SortingDocumentsPage"

const ArchiveContainerPageRoot = () => {
  const userSelectedWorksite = useSelector((state: AppState) =>
    selectedWorksite(state)
  )
  const allUserWorksites = useSelector((state: AppState) => worksites(state))
  const { pathname } = useLocation()

  if (
    !userSelectedWorksite ||
    allUserWorksites.filter(worksite => worksite.active).length === 0
  ) {
    return <NoActiveWorksites />
  }
  return (
    <>
      <ContextNavigation>
        <NavLink
          className="context-navigation__navlink"
          activeClassName="context-navigation__navlink--active"
          exact={true}
          to="/archive"
        >
          <FormattedMessage id="documents.transfer.title" />
        </NavLink>
        <NavLink
          className="context-navigation__navlink"
          activeClassName="context-navigation__navlink--active"
          exact={true}
          to="/sorting"
        >
          <FormattedMessage id="documents.sorting.title" />
        </NavLink>
      </ContextNavigation>
      <ContentContainer>
        {pathname.startsWith("/sorting") ? (
          <SortingDocumentsPage selectedWorksite={userSelectedWorksite} />
        ) : (
          <TransferDocumentPage selectedWorksite={userSelectedWorksite} />
        )}
      </ContentContainer>
    </>
  )
}

export default ArchiveContainerPageRoot
