import { useEffect, useLayoutEffect } from "react"
import { useGA } from "@kettu/ui-components"
import { OrderSelectionItem } from "../pages/orders/common/OrderSelection"
import { BaseFormTypes, Order } from "../pages/orders/common/models"
import { useIntl } from "react-intl"
import { FormType } from "../pages/contact/models"

type GA_Event = {
  event: string
  type: string
  product_type?: string
  product_subtype?: string
  jatetyyppi?: string
  bajamaja_malli?: string
}

const EventTypes = {
  [FormType.FEEDBACK]: "palaute",
  [FormType.OFFER]: "tarjouspyynto",
  [FormType.OTHER]: "muu asia"
}

export const useLoginEvent = (isAuthenticated: boolean) => {
  const ga = useGA()
  useEffect(() => {
    isAuthenticated && ga.push({ event: "login" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])
}

export const useNewOrderEvents = (
  product_type: string,
  orderSelectionItems: OrderSelectionItem[],
  formType: BaseFormTypes,
  order: Order | null,
  orderCompleted: boolean
) => {
  const ga = useGA()
  const intl = useIntl()

  // First event triggered on mount
  useLayoutEffect(() => {
    ga.push({
      event: "order_step_1",
      type: "order",
      product_type
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Second event triggered when sub category is selected
  useEffect(() => {
    const orderSubTypeLabel = orderSelectionItems.find(
      i => i.selectedFormType === formType
    )
    if (!orderSubTypeLabel || orderCompleted || order === null) return undefined
    const product_subtype = intl
      .formatMessage({ id: orderSubTypeLabel.titleId })
      .toLowerCase()
    ga.push({
      event: "order_step_2",
      type: "order",
      product_type,
      product_subtype
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType, intl, ga, orderCompleted, order])

  // Third event when order is created
  useEffect(() => {
    if (!order || orderCompleted) return undefined
    const orderSubTypeLabel = orderSelectionItems.find(
      i => i.selectedFormType === formType
    )
    const product_subtype = intl
      .formatMessage({ id: orderSubTypeLabel?.titleId })
      .toLowerCase()
    let event = {
      event: "order_step_3",
      type: "order",
      product_type,
      product_subtype,
      jatetyyppi: order.wasteType.toLowerCase()
    } as GA_Event
    if ("specialContainer" in order) {
      event.bajamaja_malli = order.specialContainer?.toLowerCase()
    }
    ga.push(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType, intl, ga, order, orderCompleted])

  useEffect(() => {
    if (!orderCompleted || !order) return undefined
    const orderSubTypeLabel = orderSelectionItems.find(
      i => i.selectedFormType === formType
    )
    const product_subtype = intl
      .formatMessage({ id: orderSubTypeLabel?.titleId })
      .toLowerCase()
    let event = {
      event: "order_completed",
      type: "order",
      product_type,
      product_subtype,
      jatetyyppi: order.wasteType.toLowerCase()
    } as GA_Event
    if ("specialContainer" in order) {
      event.bajamaja_malli = order.specialContainer?.toLowerCase()
    }
    ga.push(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType, intl, ga, order, orderCompleted])
}

export const useContactFormEvent = (type: FormType) => {
  const ga = useGA()
  useEffect(() => {
    ga.push({
      event: "contact_form",
      type: EventTypes[type]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
