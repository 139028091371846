import WorksiteSelector from "common/worksite-selector/worksite-selector"
import { FormattedMessage } from "react-intl"
import { Worksite } from "store/worksite/models"
import "./documents.sass"
import TransferDocuments from "./transfer-documents/TransferDocuments"

interface TransferDocumentPageProps {
  selectedWorksite: Worksite | undefined
}

const TransferDocumentPage = ({
  selectedWorksite
}: TransferDocumentPageProps) => {
  return (
    <>
      <WorksiteSelector />
      <h1 className="lt-h4">
        <FormattedMessage id="documents.transfer.title" />
      </h1>
      <TransferDocuments selectedWorksite={selectedWorksite} />
    </>
  )
}

export default TransferDocumentPage
