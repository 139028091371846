import React from "react"
import classNames from "classnames"

import {
  CompactorFormState,
  CompactorSpecialContainer,
  CompactorWasteTypes,
  EmptyCompactorOrder,
  ExistingCompactorOrder,
  NewCompactorOrder
} from "./models"

import "../common/OrderForm.sass"
import Checkbox from "lib/checkbox/checkbox"
import Button from "lib/button/button"
import { makeSwitchExhaustive } from "util/utils"

import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"
import { GeneralInformation } from "../GeneralInformation"
import {
  BackgroundColor,
  ContainerWidth,
  ContentContainer
} from "common/content-container/content-container"
import { DayPickerInputWrapper } from "lib/day-picker/DayPicker"
import { WeekPickerInputWrapper } from "lib/week-picker/WeekPicker"
import { connect } from "react-redux"
import { AppState } from "store/state"
import { submitOrder } from "store/order/order-reducer"
import { phone } from "store/auth/auth-reducer"
import {
  getDeliveryDate,
  getFirstAvailableDay,
  getFirstAvailableWeek
} from "../order-utils"
import {
  BaseFormTypes,
  ContainerType,
  DeliveryType,
  Order
} from "../common/models"
import { Worksite } from "store/worksite/models"
import SelectionBox from "lib/selection-box/selection-box"

interface CompactorFormProps {
  formType: BaseFormTypes
  phone: string
  propFormState: CompactorFormState | null
  onSubmit: (order: Order, formState: CompactorFormState) => void
  onCancel: () => void
  worksite: Worksite
}

const initialFormData = (
  phone: string,
  formType: BaseFormTypes
): CompactorFormState => {
  return {
    dateType: formType === "new" ? DeliveryType.WEEK : DeliveryType.ASAP,
    date: formType === "new" ? getFirstAvailableWeek() : getFirstAvailableDay(),
    selectedWasteType: CompactorWasteTypes.EnergyWaste,
    specialContainer: null,
    callBefore: false,
    moveDuringEmpty: false,
    changeWasteTypeTo: null,
    containerNumber: "",
    contactNumber: phone,
    extraInfo: ""
  }
}

// Change wishes to array, we can have both
const CompactorForm = ({
  formType,
  onSubmit,
  propFormState,
  onCancel,
  phone,
  intl,
  worksite
}: CompactorFormProps & WrappedComponentProps) => {
  const [formState, setFormState] = React.useState<CompactorFormState>(
    propFormState ? propFormState : initialFormData(phone, formType)
  )

  const getFormTitle = (formType: BaseFormTypes) => {
    switch (formType) {
      case "new":
        return <FormattedMessage id="order.compactor.new" />
      case "emptying":
        return <FormattedMessage id="order.compactor.empty" />
      case "move":
        return <FormattedMessage id="order.compactor.move" />
      case "remove":
        return <FormattedMessage id="order.compactor.remove" />
      case "other":
      case "unselected":
        return ""
      default:
        makeSwitchExhaustive(formType)
    }
  }

  const getCallBeforeLabel = (formType: BaseFormTypes) => {
    switch (formType) {
      case "new":
        return <FormattedMessage id="order.form.call-before" />
      case "emptying":
        return <FormattedMessage id="order.form.call-before.empty" />
      case "move":
        return <FormattedMessage id="order.form.call-before.move" />
      case "remove":
        return <FormattedMessage id="order.form.call-before.remove" />
      case "other":
      case "unselected":
        return ""
      default:
        makeSwitchExhaustive(formType)
    }
  }

  const CheckboxSection = () => {
    if (formType === "emptying") {
      return (
        <>
          <Checkbox
            checked={formState.callBefore}
            onChange={() =>
              setFormState({ ...formState, callBefore: !formState.callBefore })
            }
            name="call_before"
          >
            {getCallBeforeLabel(formType)}
          </Checkbox>
          <Checkbox
            checked={formState.moveDuringEmpty}
            onChange={() =>
              setFormState({
                ...formState,
                moveDuringEmpty: !formState.moveDuringEmpty
              })
            }
            name="move_empty"
          >
            <FormattedMessage id="order.form.compactor.move-during-empty" />
          </Checkbox>
        </>
      )
    }
    return (
      <>
        <Checkbox
          checked={formState.callBefore}
          onChange={() =>
            setFormState({ ...formState, callBefore: !formState.callBefore })
          }
          name="call_before"
        >
          {getCallBeforeLabel(formType)}
        </Checkbox>
      </>
    )
  }

  const updateSelectedWasteType = (wasteType: CompactorWasteTypes) => {
    setFormState({ ...formState, selectedWasteType: wasteType })
  }

  const createAndSubmitOrder = () => {
    switch (formType) {
      case "new": {
        const order: NewCompactorOrder = {
          type: formType,
          targetIdentifier: worksite.targetIdentifier,
          containerType: ContainerType.COMPACTOR,
          desiredDeliveryTime: getDeliveryDate(
            formState.dateType,
            formState.date
          ),
          wasteType: formState.selectedWasteType,
          callBefore: formState.callBefore,
          contactNumber: formState.contactNumber,
          extraInfo: formState.extraInfo ? formState.extraInfo : null
        }
        onSubmit(order, formState)
        return
      }
      case "emptying": {
        const order: EmptyCompactorOrder = {
          type: formType,
          targetIdentifier: worksite.targetIdentifier,
          containerType: ContainerType.COMPACTOR,
          desiredDeliveryTime: getDeliveryDate(
            formState.dateType,
            formState.date
          ),
          wasteType: formState.selectedWasteType,
          callBefore: formState.callBefore,
          contactNumber: formState.contactNumber,
          extraInfo: formState.extraInfo ? formState.extraInfo : null,
          specialContainer: formState.specialContainer,
          moveDuringEmpty: formState.moveDuringEmpty
        }
        onSubmit(order, formState)
        return
      }
      case "move":
      case "remove": {
        const order: ExistingCompactorOrder = {
          type: formType,
          targetIdentifier: worksite.targetIdentifier,
          containerType: ContainerType.COMPACTOR,
          desiredDeliveryTime: getDeliveryDate(
            formState.dateType,
            formState.date
          ),
          wasteType: formState.selectedWasteType,
          callBefore: formState.callBefore,
          contactNumber: formState.contactNumber,
          extraInfo: formState.extraInfo ? formState.extraInfo : null,
          specialContainer: formState.specialContainer
        }
        onSubmit(order, formState)
        return
      }
      case "other":
      case "unselected":
        return
      default:
        makeSwitchExhaustive(formType)
    }
  }

  const isFormDisabled = () => {
    return (
      !formState.date ||
      !formState.selectedWasteType ||
      formState.contactNumber === ""
    )
  }

  const placeholderId =
    formType === "new"
      ? "order.form.compactor.additional-info.placeholder.new"
      : "order.form.compactor.additional-info.placeholder.other"

  return (
    <ContentContainer
      containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}
      backgroundColor={BackgroundColor.WHITE}
    >
      <div className="modal">
        <div className="modal__header">
          <h1 className="lt-h4">{getFormTitle(formType)}</h1>
          <button
            className="close-button"
            onClick={onCancel}
            aria-label={intl.formatMessage({ id: "common.close" })}
          />
        </div>
        <div className="modal__body">
          {formType === "new" && (
            <p className="info-text lt-margin-bottom-m">
              <FormattedMessage id="order.form.compactor.info.new" />
            </p>
          )}

          {formType === "new" ? (
            <fieldset className="fieldset">
              <div className="label">
                <FormattedMessage id="order.form.selected-date" /> *
              </div>
              <p className="info-text lt-margin-bottom-s">
                <FormattedMessage id="order.form.compactor.info.date" />
              </p>
              <WeekPickerInputWrapper
                date={formState.date}
                onDayChange={day =>
                  setFormState({
                    ...formState,
                    dateType: DeliveryType.WEEK,
                    date: day
                  })
                }
              />
            </fieldset>
          ) : (
            <fieldset className="fieldset">
              <div className="label">
                <FormattedMessage id="order.form.date" /> *
              </div>
              <div className="selection-box">
                <div
                  className={classNames("selection-box__item", {
                    "selection-box__item--selected":
                      formState.dateType === DeliveryType.ASAP
                  })}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      dateType: DeliveryType.ASAP,
                      date: getFirstAvailableDay()
                    })
                  }
                >
                  <FormattedMessage id="order.form.asap" />
                </div>
                <div
                  className={classNames("selection-box__item", {
                    "selection-box__item--selected":
                      formState.dateType !== DeliveryType.ASAP
                  })}
                  onClick={() => {
                    if (formState.dateType === DeliveryType.ASAP)
                      setFormState({
                        ...formState,
                        dateType: DeliveryType.DAY,
                        date: getFirstAvailableDay()
                      })
                  }}
                >
                  <FormattedMessage id="order.form.choose-date" />
                </div>
              </div>
            </fieldset>
          )}

          {formState.dateType === DeliveryType.DAY && (
            <fieldset className="fieldset">
              <div className="label">
                <FormattedMessage id="order.form.selected-date" /> *
              </div>
              <DayPickerInputWrapper
                date={formState.date}
                onDayChange={day => setFormState({ ...formState, date: day })}
              />
            </fieldset>
          )}

          <fieldset className="fieldset">
            <div className="label">
              <FormattedMessage id="order.form.waste-type" /> *
            </div>
            <SelectionBox
              itemList={Object.values(CompactorWasteTypes)}
              selectedItem={formState.selectedWasteType}
              onClick={updateSelectedWasteType}
            />
          </fieldset>

          <fieldset className="fieldset">
            {formType !== "new" && (
              <Checkbox
                checked={!!formState.specialContainer}
                onChange={() => {
                  const newSpecial = formState.specialContainer
                    ? null
                    : CompactorSpecialContainer.Lokeropuristin
                  setFormState({ ...formState, specialContainer: newSpecial })
                }}
                name="special_container"
              >
                <FormattedMessage id="order.form.compactor.special-container" />
              </Checkbox>
            )}

            <CheckboxSection />
          </fieldset>

          <GeneralInformation
            customInfoMessage={
              <FormattedMessage id="order.form.additional-info.compactor" />
            }
            inputValue={formState.contactNumber}
            onChangeInput={event =>
              setFormState({ ...formState, contactNumber: event.target.value })
            }
            textareaValue={formState.extraInfo}
            onChangeTextarea={event =>
              setFormState({ ...formState, extraInfo: event.target.value })
            }
            textareaPlaceholder={intl.formatMessage({ id: placeholderId })}
          />

          <Button
            expand
            disabled={isFormDisabled()}
            onClick={createAndSubmitOrder}
          >
            <FormattedMessage id="order.form.to-confirmation" />
          </Button>
        </div>
      </div>
    </ContentContainer>
  )
}

const connector = connect(
  (state: AppState) => ({
    phone: phone(state)
  }),
  { submitOrder }
)

export default connector(injectIntl(CompactorForm))
