import { Switch, Route } from "react-router-dom"
import VaihtolavaBase from "./vaihtolava/VaihtolavaBase"
import CompactorBase from "./compactor/CompactorBase"
import OrderMainPage from "./OrderMainPage"
import { AppState } from "store/state"
import { selectedWorksite } from "store/worksite/worksite-reducer"
import { connect } from "react-redux"
import { Worksite } from "store/worksite/models"
import { worksites } from "store/auth/auth-reducer"
import FrontloaderBase from "./frontloader/FrontloaderBase"
import DryToiletBase from "./dry-toilet/DryToiletBase"
import OtherBase from "./other/OtherBase"
import NoActiveWorksites from "common/no-active-worksites/no-active-worksites"
import { JassikatBase } from "./jassikat/JassikatBase"

interface ReduxProps {
  selectedWorksite: Worksite | undefined
  worksites: Worksite[]
}

const OrdersRoot = ({ selectedWorksite, worksites }: ReduxProps) => {
  if (
    !selectedWorksite ||
    worksites.filter(worksite => worksite.active).length === 0
  ) {
    return <NoActiveWorksites />
  }
  return (
    <Switch>
      <Route path="/orders/jassikat">
        <JassikatBase worksite={selectedWorksite} />
      </Route>
      <Route path="/orders/vaihtolava">
        <VaihtolavaBase worksite={selectedWorksite} />
      </Route>
      <Route path="/orders/compactor">
        <CompactorBase worksite={selectedWorksite} />
      </Route>
      <Route path="/orders/frontloader">
        <FrontloaderBase worksite={selectedWorksite} />
      </Route>
      <Route path="/orders/dry-toilet">
        <DryToiletBase worksite={selectedWorksite} />
      </Route>
      <Route path="/orders/other">
        <OtherBase worksite={selectedWorksite} />
      </Route>
      <Route path="/orders/create">
        <OrderMainPage worksite={selectedWorksite} />
      </Route>
    </Switch>
  )
}

const mapStateToProps = (state: AppState) => ({
  selectedWorksite: selectedWorksite(state),
  worksites: worksites(state)
})

export default connect(mapStateToProps, {})(OrdersRoot)
