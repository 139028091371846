import React from "react"
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"
import { Link } from "react-router-dom"
import {
  BackgroundColor,
  ContainerWidth,
  ContentContainer
} from "common/content-container/content-container"

interface OrderSuccessProps {
  onCancel: () => void
  image?: string
}

const OrderSuccess = ({
  onCancel,
  image,
  intl
}: OrderSuccessProps & WrappedComponentProps) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ContentContainer
      containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}
      backgroundColor={BackgroundColor.WHITE}
    >
      <div className="modal">
        <div className="container">
          <div className="modal__header">
            <h1 className="lt-h4">
              <FormattedMessage id="order.success.title" />
            </h1>
            <button
              className="close-button"
              onClick={onCancel}
              aria-label={intl.formatMessage({ id: "common.close" })}
            />
          </div>
          <div className="modal__body">
            <p>
              <FormattedMessage id="order.success.confirmation-email" />
            </p>
            <p>
              <FormattedMessage id="order.success.customerservice" />
            </p>
            {image && (
              <img className="modal__content-image" src={image} alt="" />
            )}
            <Link
              className="button button--expand lt-margin-top-m"
              to="/orders"
            >
              <FormattedMessage id="order.success.archive" />
            </Link>
          </div>
        </div>
      </div>
    </ContentContainer>
  )
}

export default injectIntl(OrderSuccess)
