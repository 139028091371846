import * as React from "react"
import ClickableDiv from "../../lib/clickable-div/clickable-div"
import Content from "./dropdown-panel-content"
import classNames from "classnames"

import "./dropdown-panel.sass"

interface DropdownPanelTriggerElementProps {
  isOpen: boolean
}

interface DropdownPanelContentElementProps {
  closeMenu: () => void
}

interface Props {
  modifierClass: string
  trigger: React.SFC<DropdownPanelTriggerElementProps>
  content: React.SFC<DropdownPanelContentElementProps>
}

interface State {
  isOpen: boolean
}

export default class DropdownPanel extends React.Component<Props, State> {
  private triggerRef: HTMLDivElement | null = null
  private contentRef: HTMLDivElement | null = null

  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.onTriggerClick = this.onTriggerClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleEscButton = this.handleEscButton.bind(this)
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
    // document.addEventListener("touchstart", this.handleClickOutside)
    document.addEventListener("keyup", this.handleEscButton)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
    //  document.removeEventListener("touchstart", this.handleClickOutside)
    document.removeEventListener("keyup", this.handleEscButton)
  }

  render() {
    return (
      <div
        className={classNames(
          "dropdown-panel",
          { "dropdown-panel--is-open": this.state.isOpen },
          this.props.modifierClass
        )}
      >
        <ClickableDiv
          className="dropdown-panel__trigger"
          handler={this.onTriggerClick}
          refCallback={(ref: HTMLDivElement | null) => (this.triggerRef = ref)}
        >
          <this.props.trigger isOpen={this.state.isOpen} />
        </ClickableDiv>
        {this.state.isOpen && (
          <Content
            refCallback={(ref: HTMLDivElement | null) =>
              (this.contentRef = ref)
            }
          >
            <this.props.content closeMenu={() => this.close()} />
          </Content>
        )}
      </div>
    )
  }

  private onTriggerClick() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  private handleClickOutside(event: MouseEvent) {
    const notInsideTrigger =
      this.triggerRef && !this.triggerRef.contains(event.target as Node)
    const notInsideContent =
      this.contentRef && !this.contentRef.contains(event.target as Node)
    if (notInsideTrigger && notInsideContent) {
      this.close()
    }
  }

  private handleEscButton(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      this.close()
    }
  }

  private close() {
    this.setState({
      isOpen: false
    })
  }
}
