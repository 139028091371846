import { Worksite } from "../../../store/worksite/models"
import iconJassikka from "common/images/icon-large-jassikat.svg"
import OrderSelection, { OrderSelectionItem } from "../common/OrderSelection"
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react"
import OrderForm, { OrderFormWasteTypes } from "../common/OrderForm"
import { BaseFormTypes, Order } from "../common/models"
import OrderConfirm from "../common/OrderConfirm"
import { useDispatch, useSelector } from "react-redux"
import {
  isSubmittedSuccessfully,
  submitOrder,
  submitOrderReset
} from "../../../store/order/order-reducer"
import OrderSuccess from "../common/OrderSuccess"
import { useHistory } from "react-router"
import { phone } from "../../../store/auth/auth-reducer"

export type JassikatBaseProps = {
  worksite: Worksite
}

const orderSelectionItems: OrderSelectionItem[] = [
  {
    titleId: "order.jassikka.new",
    selectedFormType: BaseFormTypes.NEW
  },
  {
    titleId: "order.jassikka.empty",
    selectedFormType: BaseFormTypes.EMPTYING
  },
  {
    titleId: "order.jassikka.remove",
    selectedFormType: BaseFormTypes.REMOVE
  }
]

export const JassikatBase = ({ worksite }: JassikatBaseProps) => {
  const [formType, setFormType] = useState<BaseFormTypes>(
    BaseFormTypes.UNSELECTED
  )
  const [order, setOrder] = useState<Order>()
  const dispatch = useDispatch()
  const isSuccess = useSelector(isSubmittedSuccessfully)
  const usersPhone = useSelector(phone)
  const history = useHistory()

  useLayoutEffect(() => {
    dispatch(submitOrderReset())
  }, [dispatch])
  const cancel = useCallback(() => {
    setFormType(BaseFormTypes.UNSELECTED)
    setOrder(undefined)
  }, [])
  const submit = useCallback(
    (order: Order) => {
      dispatch(submitOrder(order))
    },
    [dispatch]
  )

  const screens = useMemo<{ [key in BaseFormTypes]: JSX.Element }>(() => {
    const orderForm = (
      <OrderForm
        phone={usersPhone}
        wasteType={OrderFormWasteTypes.Jassika}
        formType={formType}
        onCancel={cancel}
        worksite={worksite}
        onSubmit={order => {
          setFormType(BaseFormTypes.UNSELECTED)
          setOrder(order)
        }}
        propFormState={null}
      />
    )

    return {
      [BaseFormTypes.UNSELECTED]: order ? (
        isSuccess ? (
          <OrderSuccess
            onCancel={() => history.push("/create")}
            image={iconJassikka}
          />
        ) : (
          <OrderConfirm
            titleId={"order.jassikat.title"}
            order={order}
            worksite={worksite}
            onCancel={cancel}
            onSubmit={submit}
          />
        )
      ) : (
        <OrderSelection
          selectionTitleId="order.jassikat.title"
          items={orderSelectionItems}
          onClick={setFormType}
        />
      ),
      [BaseFormTypes.NEW]: orderForm,
      [BaseFormTypes.REMOVE]: orderForm,
      [BaseFormTypes.EMPTYING]: orderForm,
      [BaseFormTypes.OTHER]: <></>,
      [BaseFormTypes.MOVE]: <></>
    }
  }, [
    submit,
    history,
    order,
    cancel,
    worksite,
    formType,
    isSuccess,
    usersPhone
  ])

  return screens[formType]
}
