import { DryToiletType } from "../../pages/orders/dry-toilet/models"
import classNames from "classnames"
import "./selection-box.sass"
import React from "react"
import {
  EmptyInterval,
  EmptyOptions,
  OrderSpecialContainer,
  OrderWasteTypes
} from "pages/orders/common/models"
import { FrontloaderWasteTypes } from "pages/orders/frontloader/models"
import { CompactorWasteTypes } from "pages/orders/compactor/models"
import {
  VaihtolavaSpecialContainer,
  VaihtolavaWasteTypes
} from "pages/orders/vaihtolava/models"

export enum SelectionBoxLayoutType {
  MEDIUM,
  SMALL
}

export type ItemType =
  | DryToiletType
  | FrontloaderWasteTypes
  | CompactorWasteTypes
  | OrderWasteTypes
  | OrderSpecialContainer
  | VaihtolavaWasteTypes
  | VaihtolavaSpecialContainer
  | EmptyInterval
  | EmptyOptions

interface SelectionBoxProps {
  itemList: ItemType[]
  selectedItem: ItemType | null
  onClick: (item: any) => void
  layout?: SelectionBoxLayoutType
}

interface SelectionBoxOptionProps {
  item: ItemType
  selectedItem: ItemType | null
  onClick: () => void
  layout: SelectionBoxLayoutType
}

const SelectionBoxOption = ({
  item,
  selectedItem,
  onClick,
  layout
}: SelectionBoxOptionProps) => {
  const selected = item === selectedItem
  return (
    <div
      onClick={onClick}
      className={classNames("selection-box__item", {
        "selection-box__item--small": layout === SelectionBoxLayoutType.SMALL,
        "selection-box__item--selected": selected
      })}
    >
      {item}
    </div>
  )
}

const SelectionBox = ({
  itemList,
  selectedItem,
  onClick,
  layout
}: SelectionBoxProps) => (
  <div className="selection-box">
    {itemList.map((item, idx) => (
      <SelectionBoxOption
        key={idx}
        item={item}
        selectedItem={selectedItem}
        onClick={() => onClick(item)}
        layout={layout ? layout : SelectionBoxLayoutType.MEDIUM}
      />
    ))}
  </div>
)

export default SelectionBox
