import { BaseFormTypes, ContainerType, Order, OrderFormState } from "./models"
import { Worksite } from "../../../store/worksite/models"
import { getDeliveryDate } from "../order-utils"
import {
  EmptyVaihtolavaOrder,
  ExistingVaihtolavaOrder,
  NewVaihtolavaOrder
} from "../vaihtolava/models"
import { OrderFormWasteTypes } from "./OrderForm"

export type OrderBuilderProps = {
  wasteType: OrderFormWasteTypes
  formType: BaseFormTypes
  worksite: Worksite
  formState: OrderFormState
}

export const useOrderBuilder =
  () =>
  ({
    wasteType,
    formType,
    worksite,
    formState
  }: OrderBuilderProps): Order | undefined => {
    return builder(wasteType, formType, formState, worksite)
  }

const builder = (
  wasteType: OrderFormWasteTypes,
  formType: BaseFormTypes,
  formState: OrderFormState,
  worksite: Worksite
): Order | undefined => {
  const isJassikka = wasteType === OrderFormWasteTypes.Jassika
  const baseOrder = {
    type: formType,
    targetIdentifier: worksite.targetIdentifier,
    containerType: isJassikka
      ? "jassikka"
      : formState.specialContainer
      ? formState.specialContainer
      : ContainerType.ROLL_OFF,
    desiredDeliveryTime: getDeliveryDate(formState.dateType, formState.date),
    wasteType: formState.selectedWasteType as any,
    callBefore: formState.callBefore,
    contactNumber: formState.contactNumber,
    extraInfo: formState.extraInfo ? formState.extraInfo : null
  }

  if (formType === "new") {
    return Object.assign(baseOrder, {
      lowSide: formState.lowSide,
      liftingLugs: formState.liftingLugs
    }) as NewVaihtolavaOrder
  }

  const extendedOrder = Object.assign(baseOrder, {
    specialContainer: formState.specialContainer,
    containerNumber:
      formState.containerNumber.length > 0
        ? formState.containerNumber
        : undefined
  })

  if (["move", "remove"].indexOf(formType) >= 0) {
    return extendedOrder as ExistingVaihtolavaOrder
  }

  if (formType === "emptying") {
    return Object.assign(extendedOrder, {
      moveDuringEmpty: formState.moveDuringEmpty,
      changeWasteTypeTo: formState.changeWasteTypeTo
    }) as EmptyVaihtolavaOrder
  }
}
