import React from "react"

import "../../../common/search/search-field.sass"

import "./SearchField.sass"

type Props = {
  searchQuery: string
  setSearchQuery: (query: string) => void
}

// TODO add debounce

export const SearchField: React.FC<Props> = ({
  searchQuery,
  setSearchQuery
}) => (
  <div className="search">
    {searchQuery === "" ? (
      <div className="search__icon search__icon--search" />
    ) : (
      <div
        className="search__icon search__icon--cancel"
        onClick={e => setSearchQuery("")}
      />
    )}
    <input
      className="search__input user-search-input"
      type="text"
      value={searchQuery}
      onChange={event => setSearchQuery(event.currentTarget.value)}
    />
  </div>
)
