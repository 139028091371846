import Button from "lib/button/button"
import StatusBar, {
  StatusBarMode,
  StatusBarState
} from "lib/status-banner/StatusBar"
import React, { useEffect, useState } from "react"
import { WrappedComponentProps, injectIntl } from "react-intl"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"
import { LoginResponse } from "store/auth/auth-reducer"
import { Worksite } from "store/worksite/models"
import { listItemToForm, UserFormData, UserListItem } from "../sales-view-api"
import WorksiteSearch from "../worksites/WorksiteSearch"
import * as Validation from "./validations"
import Checkbox from "lib/checkbox/checkbox"

import "./UserForm.sass"
import { UserInputs } from "./UserInputs"

type Props = {
  loggedInUser: LoginResponse
}

export const EditUserPage: React.FC<Props> = injectIntl(
  ({ loggedInUser, intl }: Props & WrappedComponentProps) => {
    const { id } = useParams<{ id: string }>()
    const [user, setUser] = useState<UserFormData>()
    const [worksites, setWorksites] = useState<Worksite[]>([])
    const [sendLoginInstructionsByEmail, setSendLoginInstructionsByEmail] =
      useState<boolean>(false)
    const [statusBar, setStatusBar] = useState<StatusBarState>({
      status: StatusBarMode.NONE
    })
    const [validations, setValidations] =
      useState<Validation.UserFormDataValidations>(Validation.defaultValidation)
    const history = useHistory()

    useEffect(() => {
      fetch(`${process.env.REACT_APP_URL}/api/users/${id}`)
        .then(resp => resp.json())
        .then((value: any) => {
          setUser(listItemToForm(value.user as UserListItem))
          setWorksites(value.worksites)
        })
    }, [id])

    const submit = async () => {
      setValidations(Validation.defaultValidation)
      if (user) {
        const validation = Validation.validate(user, intl)

        if (validation.isValid) {
          const resp = await fetch(
            `${process.env.REACT_APP_URL}/api/users/iamupdate`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                auth0Id: id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phoneNumber,
                role: user.role,
                hasAccessToReports: user.hasAccessToReports,
                terms: user.terms,
                raksanappiWorksites: worksites.map(ws => ws.targetIdentifier),
                sendNewUserWelcomeEmail: sendLoginInstructionsByEmail,
                actorId: loggedInUser.userId
              })
            }
          )
          if (resp.ok) {
            setStatusBar({
              status: StatusBarMode.INFO,
              messageId: "sales.user-modification.success"
            })
            setTimeout(() => history.push("/sales"), 1000)
          } else {
            setStatusBar({
              status: StatusBarMode.ERROR,
              messageId: "sales.user-modification.failure"
            })
          }
        } else {
          setValidations(validation)
        }
      }
    }

    return (
      <div className="content-container background-color--gray">
        <StatusBar
          barState={statusBar}
          onClick={() => setStatusBar({ status: StatusBarMode.NONE })}
        />
        <div
          className="
          content-container__inner
          content-container__inner--width-restrict
          content-container__inner--mobile-stretch"
        >
          {!user ? (
            <h1 className="lt-h4">Ladataan käyttäjän tietoja...</h1>
          ) : (
            <div className="user-form__container">
              <h1 className="lt-h4">
                {intl.formatMessage({ id: "sales.edit-user-page.title" })}
              </h1>
              <UserInputs
                user={user}
                validations={validations}
                update={setUser}
              />
              <WorksiteSearch
                worksites={worksites}
                onUpdate={worksites => setWorksites(worksites)}
              />
              <Checkbox
                checked={sendLoginInstructionsByEmail}
                onChange={() => {
                  setSendLoginInstructionsByEmail(!sendLoginInstructionsByEmail)
                }}
                name="send-login-instructions-by-email"
              >
                {intl.formatMessage({
                  id: "sales.send-login-instructions-by-email"
                })}
              </Checkbox>
              <div className="user-form__cancel-submit-buttons-container">
                <Button
                  disabled={false}
                  expand
                  additionalClass="cancel-button spaced-button"
                  onClick={() => {
                    history.push("/sales")
                  }}
                >
                  {intl.formatMessage({ id: "common.cancel" })}
                </Button>
                <Button
                  disabled={false}
                  expand
                  onClick={submit}
                  additionalClass="spaced-button"
                >
                  {intl.formatMessage({
                    id: "sales.edit-user-page.save-modifications"
                  })}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
)
