import React from "react"
import { Link } from "react-router-dom"
import { UserListItem } from "../sales-view-api"

import "./UsersTable.sass"

type Props = {
  users: UserListItem[]
}

export const UsersTable: React.FC<Props> = ({ users }) => (
  <ul className="user-management__list">
    {users.map(u => (
      <li className="user-management__list-item" key={u.email}>
        <div className="user-management__list-row">
          <b>{u.name}</b>
        </div>
        <div className="user-management__list-row">{u.email}</div>
        <div className="user-management__list-row">{u.raksanappi.phone}</div>
        <div className="user-management__list-row user-management__list-row--actions">
          <Link to={`/edit-user/${u.id}`}>
            <button className="user-management__list-button icon-button icon-button--edit" />
          </Link>
        </div>
      </li>
    ))}
  </ul>
)
