import { FormattedMessage } from "react-intl"
import WorksiteSelector from "common/worksite-selector/worksite-selector"
import { Worksite } from "store/worksite/models"
import "./documents.sass"
import SortingGuides from "./sorting-guides/SortingGuides"
import SortingPlans from "./sorting-plans/SortingPlans"

interface SortingDocumentsPageProps {
  selectedWorksite: Worksite | undefined
}

const SortingDocumentsPage = ({
  selectedWorksite
}: SortingDocumentsPageProps) => {
  return (
    <>
      <WorksiteSelector />
      <h1 className="lt-h4">
        <FormattedMessage id="documents.sorting.title" />
      </h1>
      <SortingPlans selectedWorksite={selectedWorksite} />
      <SortingGuides />
    </>
  )
}

export default SortingDocumentsPage
