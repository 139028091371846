import React from "react"
import { Switch, Route } from "react-router-dom"
import ContactMainPage from "./ContactMainPage"
import ContactForm from "./ContactForm"
import { FormType } from "./models"
import { AppState } from "store/state"
import { selectedWorksite } from "store/worksite/worksite-reducer"
import { connect } from "react-redux"
import { Worksite } from "store/worksite/models"

interface ReduxProps {
  selectedWorksite: Worksite | undefined
}

const ContactRoot = ({ selectedWorksite }: ReduxProps) => {
  return (
    <Switch>
      <Route path="/contact/feedback">
        <ContactForm type={FormType.FEEDBACK} worksite={selectedWorksite} />
      </Route>
      <Route path="/contact/offer">
        <ContactForm type={FormType.OFFER} worksite={selectedWorksite} />
      </Route>
      <Route path="/contact/other">
        <ContactForm type={FormType.OTHER} worksite={selectedWorksite} />
      </Route>
      <Route path="/contact/menu">
        <ContactMainPage />
      </Route>
    </Switch>
  )
}

const mapStateToProps = (state: AppState) => ({
  selectedWorksite: selectedWorksite(state)
})

export default connect(mapStateToProps, {})(ContactRoot)
