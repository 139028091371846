import * as React from "react"
import classNames from "classnames"
import "./loading-spinner.sass"

type Props = {
  className?: string
}

export default class LoadingSpinner extends React.Component<Props> {
  render() {
    return (
      <div className={classNames("loading-spinner", this.props.className)} />
    )
  }
}
