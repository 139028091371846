import * as React from "react"

interface Props {
  refCallback?: (ref: HTMLDivElement | null) => void
}

export default class Content extends React.Component<Props, {}> {
  render() {
    return (
      <div
        className="dropdown-panel__content"
        ref={this.props.refCallback}
        aria-live="polite"
      >
        {this.props.children}
      </div>
    )
  }
}
