import { AppState } from "../state"
import { AllowedPostalCodesResponse, WorksiteState } from "./models"
import { AppAction } from "store/action"

/**
 * ACTIONS
 */
export enum ActionTypes {
  SELECT_WORKSITE = "SELECT_WORKSITE",
  FETCH_ALLOWED_POSTAL_CODES = "FETCH_ALLOWED_POSTAL_CODES",
  SET_ALLOWED_POSTAL_CODES = "SET_ALLOWED_POSTAL_CODES"
}

export type SelectWorksiteAction = {
  type: ActionTypes.SELECT_WORKSITE
  index: number
}

export type FetchAllowedPostalCodes = {
  type: ActionTypes.FETCH_ALLOWED_POSTAL_CODES
}

export type SetAllowedPostalCodes = {
  type: ActionTypes.SET_ALLOWED_POSTAL_CODES
  data: AllowedPostalCodesResponse
}

export type WorksiteAction =
  | SelectWorksiteAction
  | SetAllowedPostalCodes
  | FetchAllowedPostalCodes

/**
 * ACTION CREATORS
 */

export const selectWorksite = (index: number): SelectWorksiteAction => ({
  type: ActionTypes.SELECT_WORKSITE,
  index
})

export const fetchAllowedPostalCodes = (): FetchAllowedPostalCodes => ({
  type: ActionTypes.FETCH_ALLOWED_POSTAL_CODES
})

export const setAllowedPostalCodes = (
  data: AllowedPostalCodesResponse
): SetAllowedPostalCodes => ({
  type: ActionTypes.SET_ALLOWED_POSTAL_CODES,
  data
})

/**
 * REDUCER
 */

export const initialState: WorksiteState = {
  selectedWorksite: 0,
  allowedPostalCodes: []
}

export function worksiteReducer(
  state: WorksiteState = initialState,
  action: AppAction
): WorksiteState {
  switch (action.type) {
    case ActionTypes.SELECT_WORKSITE:
      return {
        ...state,
        selectedWorksite: action.index
      }
    case ActionTypes.SET_ALLOWED_POSTAL_CODES:
      return {
        ...state,
        allowedPostalCodes: action.data.allowedPostalCodes
      }
    default:
      return state
  }
}

/**
 * SELECTORS
 */
export const selectedWorksite = ({ worksite, auth }: AppState) =>
  auth.user && auth.user.worksites.length > 0
    ? auth.user.worksites[worksite.selectedWorksite]
    : undefined

export const allowedPostalCodes = ({
  worksite: { allowedPostalCodes }
}: AppState) => allowedPostalCodes
