import React from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import {
  ContainerWidth,
  ContentContainer
} from "../../common/content-container/content-container"
import WorksiteSelector from "common/worksite-selector/worksite-selector"

const ContactMainPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ContentContainer containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}>
      <div className="order-page__worksite-selector lt-margin-bottom-s">
        <WorksiteSelector />
      </div>
      <h1 className="lt-h5 lt-margin-bottom-m">
        <FormattedMessage id="contact.title" />
      </h1>
      <div className="grid container__mobile-stretch-elem">
        <div className="grid__item">
          <Link className="box box--square" to="/contact/feedback">
            <div className="box__content">
              <h2 className="lt-h6">
                {" "}
                <FormattedMessage id="contact.feedback" />{" "}
              </h2>
            </div>
          </Link>
        </div>
        <div className="grid__item">
          <Link className="box box--square" to="/contact/offer">
            <div className="box__content">
              <h2 className="lt-h6">
                {" "}
                <FormattedMessage id="contact.offer" />{" "}
              </h2>
            </div>
          </Link>
        </div>
        <div className="grid__item">
          <Link className="box box--square" to="/contact/other">
            <div className="box__content">
              <h2 className="lt-h6">
                {" "}
                <FormattedMessage id="contact.other" />{" "}
              </h2>
            </div>
          </Link>
        </div>
      </div>
    </ContentContainer>
  )
}

export default ContactMainPage
