import { FormattedMessage } from "react-intl"
import { ReactComponent as DownloadButton } from "../../../images/icon-download-button.svg"
import { SortingGuideDocument } from "../documents-api"
import "../documents.sass"

interface SortingGuideDesktopProps {
  sortingGuideDocuments: SortingGuideDocument[]
}

const SortingGuideDesktop = ({
  sortingGuideDocuments
}: SortingGuideDesktopProps) => {
  return (
    <table className="documents documents--desktop">
      <thead>
        <tr>
          <th className="document__header document__header--desktop document__header--desktop-name">
            <FormattedMessage id="documents.sorting.document" />
          </th>
          <th className="document__header document__header--desktop">
            <FormattedMessage id="documents.common.language" />
          </th>
          <th className="document__header document__header--download-desktop" />
        </tr>
      </thead>
      <tbody>
        {sortingGuideDocuments.map((document, index) => (
          <tr className="document document--desktop" key={index}>
            <td className="document__value document__value--desktop document__value--desktop-bold">
              {document.name}
            </td>
            <td className="document__value document__value--desktop">
              {document.language}
            </td>
            <td className="document__value document__value--desktop">
              <a href={document.url} target="_blank" rel="noopener noreferrer">
                <DownloadButton />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default SortingGuideDesktop
