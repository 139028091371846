import * as React from "react"
import { LoginPage } from "pages/login/login-page"
import { IntlProvider } from "react-intl"
import { messages } from "i18n/fi"
import { Footer } from "./common/footer/footer"
import { ThemeProvider } from "styled-components"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import { LoggedInRoutes } from "./common/router/logged-in-routes"
import { OrdersPage } from "./pages/orders/list/orders"
import { SSoLogin } from "./pages/login/sso-login"
import OrdersRoot from "pages/orders/OrdersRoot"
import ContactRoot from "pages/contact/ContactRoot"
import TermsAndConditionsPage from "pages/terms-and-conditions/terms-and-conditions-page"
import ArchiveContainerPageRoot from "pages/documents/ArchiveContainerPageRoot"
import { createTheme, Themes } from "@kettu/ui-components"

// This is switch to turn on/off TopBanner component used for temporary announcements
const showTopBannerNotification = false
const theme = createTheme(Themes.Petrol)

function App() {
  return (
    <IntlProvider locale="fi" messages={messages}>
      <Router>
        <ThemeProvider theme={theme}>
          <div className="page-container">
            <Switch>
              <Route
                path="/login"
                component={() => (
                  <LoginPage
                    isTopBannerNotificationActive={showTopBannerNotification}
                  />
                )}
              />
              <Route path="/sso-login" component={SSoLogin} />
              <Route
                path="/terms-and-conditions"
                component={TermsAndConditionsPage}
              />
              <LoggedInRoutes
                isTopBannerNotificationActive={showTopBannerNotification}
              >
                <Switch>
                  <Route exact path="/orders" component={OrdersPage} />
                  <Route exact path="/orders/history" component={OrdersPage} />
                  <Route path="/orders">
                    <OrdersRoot />
                  </Route>
                  <Route exact path="/archive">
                    <ArchiveContainerPageRoot />
                  </Route>
                  <Route exact path="/sorting">
                    <ArchiveContainerPageRoot />
                  </Route>
                  <Route path="/contact">
                    <ContactRoot />
                  </Route>
                  <Route render={() => <Redirect to="/orders/create" />} />
                </Switch>
              </LoggedInRoutes>
            </Switch>
          </div>
          <Footer />
        </ThemeProvider>
      </Router>
    </IntlProvider>
  )
}

export default App
