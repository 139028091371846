import React, { useEffect } from "react"
import { connect } from "react-redux"
import { AppState } from "store/state"
import {
  isSubmittedSuccessfully,
  submitOrder,
  submitOrderReset
} from "store/order/order-reducer"
import FrontloaderForm from "./FrontloaderForm"
import OrderSuccess from "../common/OrderSuccess"
import OrderConfirm from "../common/OrderConfirm"
import { makeSwitchExhaustive } from "../../../util/utils"
import OrderSelection, { OrderSelectionItem } from "../common/OrderSelection"
import { BaseFormTypes, Order } from "../common/models"
import { Worksite } from "store/worksite/models"
import { FrontloaderFormState, FrontloaderFormTypes } from "./models"
import iconFrontloader from "common/images/icon-large-frontloader.svg"
import { useHistory } from "react-router"
import { useNewOrderEvents } from "../../../util/analytics"

interface FrontloaderBaseProps {
  worksite: Worksite
}

interface ReduxProps {
  isSubmittedSuccessfully: boolean
  submitOrder: (order: Order) => void
  submitOrderReset: () => void
}

const PRODUCT_TYPE = "etukuormasailiot"

const FrontloaderBase = ({
  isSubmittedSuccessfully,
  submitOrder,
  worksite,
  submitOrderReset
}: FrontloaderBaseProps & ReduxProps) => {
  const [formType, setFormType] = React.useState<FrontloaderFormTypes>(
    BaseFormTypes.UNSELECTED
  )
  const [order, setOrder] = React.useState<Order | null>(null)
  const [formState, setFormState] = React.useState<FrontloaderFormState | null>(
    null
  )
  const history = useHistory()

  useEffect(() => {
    if (formType === BaseFormTypes.UNSELECTED) submitOrderReset()
  }, [formType, submitOrderReset])

  const orderSelectionItems: OrderSelectionItem[] = [
    {
      titleId: "order.frontloader.new",
      selectedFormType: BaseFormTypes.NEW
    },
    {
      titleId: "order.frontloader.empty",
      selectedFormType: BaseFormTypes.EMPTYING
    },
    {
      titleId: "order.frontloader.remove",
      selectedFormType: BaseFormTypes.REMOVE
    }
  ]
  useNewOrderEvents(
    PRODUCT_TYPE,
    orderSelectionItems,
    formType,
    order,
    isSubmittedSuccessfully
  )

  const getTitleId = (formType: FrontloaderFormTypes) => {
    switch (formType) {
      case BaseFormTypes.NEW:
        return "order.confirm.frontloader.new"
      case BaseFormTypes.EMPTYING:
        return "order.confirm.frontloader.empty"
      case BaseFormTypes.REMOVE:
        return "order.confirm.frontloader.remove"
      case BaseFormTypes.UNSELECTED:
        return ""
      default:
        makeSwitchExhaustive(formType)
    }
  }

  const cancelOrder = () => setOrder(null)
  const cancelForm = () => {
    setFormState(null)
    setFormType(BaseFormTypes.UNSELECTED)
  }

  const onFormSubmitClick = (order: Order, formState: FrontloaderFormState) => {
    setOrder(order)
    setFormState(formState)
    window.scrollTo(0, 0)
  }

  const onSubmitOrderClick = (order: Order) => {
    submitOrder(order)
  }

  const handleFormType = (newFormType: BaseFormTypes) => {
    if (newFormType !== "move" && newFormType !== "other") {
      setFormType(newFormType)
    }
  }

  if (isSubmittedSuccessfully) {
    return (
      <OrderSuccess
        onCancel={() => history.push("/create")}
        image={iconFrontloader}
      />
    )
  }

  if (order) {
    return (
      <OrderConfirm
        titleId={getTitleId(formType)}
        order={order}
        worksite={worksite}
        onCancel={cancelOrder}
        onSubmit={onSubmitOrderClick}
      />
    )
  }

  if (formType === BaseFormTypes.UNSELECTED) {
    return (
      <OrderSelection
        selectionTitleId="order.frontloader.title"
        items={orderSelectionItems}
        onClick={handleFormType}
      />
    )
  }

  return (
    <FrontloaderForm
      formType={formType}
      onSubmit={onFormSubmitClick}
      propFormState={formState}
      onCancel={cancelForm}
      worksite={worksite}
    />
  )
}

const connector = connect(
  (state: AppState) => ({
    isSubmittedSuccessfully: isSubmittedSuccessfully(state)
  }),
  { submitOrder, submitOrderReset }
)

export default connector(FrontloaderBase)
