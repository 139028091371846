import { makeAjax } from "../../store/http"
import { debounceTime, map, switchMap } from "rxjs/operators"
import { AjaxResponse } from "rxjs/ajax"
import { Subject } from "rxjs"
import { parseISO } from "date-fns"

export interface TransferDocument {
  date: Date
  documentNo: string
  documentType: string
  download: string
  id: string
  lastModified: Date
  url: string
  worksite: string
  worksiteDescription: string
  company: string
  streetName: string
  postCode: string
  city: string
}

interface TransferDocumentsResponse {
  totalCount: number
  results: TransferDocumentResponse[]
}

interface TransferDocumentResponse {
  date: string
  documentNo: string
  documentType: string
  download: string
  id: string
  lastModified: string
  url: string
  worksiteId: string
  worksiteDescription: string
  company: string
  streetName: string
  postCode: string
  city: string
}

export interface TransferDocumentsResult {
  documents: TransferDocument[]
  loaded: boolean
  loadFailed: boolean
  totalCount: number
}

export interface WasteSortingPlanDocumentsResult {
  wasteSortingPlanDocuments: WasteSortingPlanDocumentResponse[]
  loaded: boolean
  loadFailed: boolean
}

export interface WasteSortingPlanDocumentResponse {
  worksiteId: string
  worksiteDescription: string
  company: string
  streetName: string
  postCode: string
  city: string
  documentStatus: WasteSortingPlanDocumentStatus
  url?: string
}

export interface WasteSortingPlanDocumentSearchResponse {
  results: WasteSortingPlanDocumentResponse[]
}

export interface SortingGuideDocument {
  name: string
  language: string
  url: string
}

export enum WasteSortingPlanDocumentStatus {
  ok = "ok",
  notFound = "not-found",
  error = "error"
}

export enum SortFields {
  date = "documentDate"
}

export enum SortDirection {
  asc = "asc",
  desc = "desc"
}

function transferDocumentMapper(
  document: TransferDocumentResponse
): TransferDocument {
  return {
    ...document,
    worksite: document.worksiteId,
    date: parseISO(document.date),
    lastModified: parseISO(document.lastModified)
  }
}

function getTransferDocuments(
  sortField: SortFields,
  sortDirection: SortDirection
) {
  const requestUrl =
    "/api/transferdocuments?orderBy=" +
    sortField +
    "&orderDirection=" +
    sortDirection
  return makeAjax({
    url: requestUrl
  }).pipe(
    map(({ response }: AjaxResponse): TransferDocumentsResponse => response)
  )
}

function getWasteSortingPlanDocuments() {
  const requestUrl = "/api/documents/waste-sorting-plan"

  return makeAjax({
    url: requestUrl
  }).pipe(
    map(
      ({ response }: AjaxResponse): WasteSortingPlanDocumentSearchResponse =>
        response
    )
  )
}

export function TransferDocumentApi() {
  const subject = new Subject<{
    sortField: SortFields
    sortDirection: SortDirection
  }>()
  return {
    subscribe: (
      setState: (state: TransferDocumentsResult) => void,
      sortField: SortFields,
      sortDirection: SortDirection
    ) =>
      subject
        .pipe(
          debounceTime(300),
          switchMap(() => getTransferDocuments(sortField, sortDirection))
        )
        .subscribe(
          ({ totalCount, results }) =>
            setState({
              totalCount,
              documents: results.map(transferDocumentMapper),
              loaded: true,
              loadFailed: false
            }),
          _ =>
            setState({
              totalCount: 0,
              documents: [],
              loaded: false,
              loadFailed: true
            })
        ),
    load: (sortField: SortFields, sortDirection: SortDirection) =>
      subject.next({ sortField, sortDirection })
  }
}

export function WasteSortingPlanDocumentApi() {
  const subject = new Subject()
  return {
    subscribe: (setState: (state: WasteSortingPlanDocumentsResult) => void) =>
      subject
        .pipe(
          debounceTime(300),
          switchMap(() => getWasteSortingPlanDocuments())
        )
        .subscribe(
          ({ results }) =>
            setState({
              wasteSortingPlanDocuments: results,
              loaded: true,
              loadFailed: false
            }),
          _ =>
            setState({
              wasteSortingPlanDocuments: [],
              loaded: false,
              loadFailed: true
            })
        ),
    load: () => subject.next()
  }
}
