import React from "react"
import { Worksite } from "store/worksite/models"
import { AppState } from "store/state"
import { worksites } from "store/auth/auth-reducer"
import {
  selectWorksite,
  selectedWorksite
} from "store/worksite/worksite-reducer"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import DropdownPanel from "common/dropdown-panel/dropdown-panel"

import "./worksite-selector.sass"
import { WorksiteInfo } from "./worksite-info"

interface ReduxDispatchProps {
  selectWorksite: (index: number) => void
}

interface ReduxStateProps {
  selectedWorksite: Worksite | undefined
  worksites: Worksite[]
}

const WorksiteSelector = ({
  worksites,
  selectedWorksite,
  selectWorksite
}: ReduxDispatchProps & ReduxStateProps) => {
  if (worksites.length <= 0 || !selectedWorksite) {
    return null
  }

  if (worksites.length === 1) {
    return <WorksiteInfo worksite={selectedWorksite} showCaret={false} />
  }

  return (
    <DropdownPanel
      modifierClass="dropdown-panel--worksite"
      trigger={({ isOpen }) => (
        <WorksiteInfo worksite={selectedWorksite} showCaret={true} />
      )}
      content={({ closeMenu }) => (
        <div>
          {worksites.map((w, i) => {
            return (
              <div
                key={w.siteIdentifier + i}
                onClick={() => {
                  selectWorksite(i)
                  closeMenu()
                }}
                className="dropdown-panel__worksite"
              >
                {w.siteIdentifier.length > 0 ? w.siteIdentifier : w.streetName}
              </div>
            )
          })}
        </div>
      )}
    />
  )
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  selectWorksite: (index: number) => dispatch(selectWorksite(index))
})

const mapStateToProps = (state: AppState): ReduxStateProps => ({
  selectedWorksite: selectedWorksite(state),
  worksites: worksites(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(WorksiteSelector)
