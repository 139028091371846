import { combineEpics, Epic } from "redux-observable"
import { AppState } from "../state"
import { isOfType } from "typesafe-actions"
import { catchError, filter, map, mergeMap } from "rxjs/operators"
import { postJson } from "../http"
import { AjaxResponse } from "rxjs/ajax"
import {
  ActionTypes,
  SubmitOrderAction,
  submitOrderFailed,
  submitOrderSuccess
} from "./order-reducer"
import { AppAction } from "../action"
import { logout } from "store/auth/auth-reducer"

const submitOrderEpic: Epic<AppAction, AppAction, AppState> = action$ =>
  action$.pipe(
    filter(isOfType(ActionTypes.SUBMIT_ORDER)),
    mergeMap((action: SubmitOrderAction) => {
      return postJson("/api/orders", action.payload).pipe(
        map((result: AjaxResponse) => {
          const code = result.status
          if (code >= 200 && code <= 300) {
            return submitOrderSuccess()
          } else {
            return submitOrderFailed()
          }
        }),
        catchError(err => {
          if (err.status === 401) {
            return [logout(true)]
          } else {
            return [submitOrderFailed()]
          }
        })
      )
    })
  )

export const orderEpic = combineEpics(submitOrderEpic)
