import { FormattedMessage } from "react-intl"
import React from "react"
import "./top-banner.sass"

// This component has been used for temporary announcements
// https://github.com/lassila-tikanoja/raksanappi-kettu/pull/136
export const TopBanner = () => (
  <div className="top-banner">
    <FormattedMessage id="new-raksanappi.notification" />
  </div>
)
