import { useEffect, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Worksite } from "store/worksite/models"
import {
  WasteSortingPlanDocumentApi,
  WasteSortingPlanDocumentsResult
} from "../documents-api"
import { usePhoneScreenSize } from "util/screen-size"
import SortingPlanDesktop from "./SortingPlanDesktop"
import SortingPlanMobile from "./SortingPlanMobile"
import { filterWasteSortingPlanDocumentsByWorksite } from "../document-utils"

interface SortingPlansProps {
  selectedWorksite: Worksite | undefined
}

const SortingPlans = ({ selectedWorksite }: SortingPlansProps) => {
  const {
    wasteSortingPlanDocuments,
    loadWasteSortingPlanDocuments,
    loaded,
    loadFailed
  } = useWasteSortingPlanDocumentList()

  useEffect(
    () => loadWasteSortingPlanDocuments(),
    [loadWasteSortingPlanDocuments]
  )

  const filteredDocuments = useMemo(
    () =>
      filterWasteSortingPlanDocumentsByWorksite(
        wasteSortingPlanDocuments,
        selectedWorksite
      ),
    [wasteSortingPlanDocuments, selectedWorksite]
  )

  return (
    <>
      <div className="documents--sub-title">
        <FormattedMessage id="documents.sorting.plan-title" />
      </div>
      {usePhoneScreenSize() ? (
        <SortingPlanMobile
          wasteSortingPlanDocuments={filteredDocuments}
          loaded={loaded}
          loadFailed={loadFailed}
        />
      ) : (
        <SortingPlanDesktop
          wasteSortingPlanDocuments={filteredDocuments}
          loaded={loaded}
          loadFailed={loadFailed}
        />
      )}
    </>
  )
}

export default SortingPlans

const wasteSortingPlanDocumentApi = WasteSortingPlanDocumentApi()

function useWasteSortingPlanDocumentList() {
  const [state, setState] = useState<WasteSortingPlanDocumentsResult>({
    wasteSortingPlanDocuments: [],
    loaded: false,
    loadFailed: false
  })

  useEffect(() => {
    const subscription = wasteSortingPlanDocumentApi.subscribe(setState)

    return () => subscription.unsubscribe()
  }, [setState])

  return {
    ...state,
    loadWasteSortingPlanDocuments: wasteSortingPlanDocumentApi.load
  }
}
