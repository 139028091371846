import { FINNISH_DAY_PICKER } from "./fi"
import { makeSwitchExhaustive } from "util/utils"

type LOCALISATIONS = "fi"

export const getDayPickerLocalisations = (locale: LOCALISATIONS) => {
  switch (locale) {
    case "fi":
      return FINNISH_DAY_PICKER
    default:
      return makeSwitchExhaustive(locale)
  }
}
