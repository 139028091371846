import { Worksite } from "store/worksite/models"
import {
  TransferDocument,
  WasteSortingPlanDocumentResponse,
  WasteSortingPlanDocumentStatus
} from "./documents-api"

export const noWasteSortingPlanDocument = (
  wasteSortingPlanDocument: WasteSortingPlanDocumentResponse
) => {
  return (
    wasteSortingPlanDocument.documentStatus ===
      WasteSortingPlanDocumentStatus.notFound ||
    wasteSortingPlanDocument.documentStatus ===
      WasteSortingPlanDocumentStatus.error
  )
}

export const filterWasteSortingPlanDocumentsByWorksite = (
  wasteSortingPlanDocuments: WasteSortingPlanDocumentResponse[],
  selectedWorksite: Worksite | undefined
) => {
  if (!selectedWorksite) {
    return []
  }

  return (wasteSortingPlanDocuments || []).filter(
    wasteSortingPlanDocument =>
      wasteSortingPlanDocument.worksiteId === selectedWorksite.targetIdentifier
  )
}

export const filterTransferDocumentsByWorksite = (
  documents: TransferDocument[],
  selectedWorksite: Worksite | undefined
): TransferDocument[] => {
  if (!selectedWorksite) {
    return []
  }

  return (documents || []).filter(
    document => document.worksite === selectedWorksite.targetIdentifier
  )
}
