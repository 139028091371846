import React from "react"
import { Redirect } from "react-router"
import { connect, ConnectedProps } from "react-redux"
import {
  isAuthenticated,
  isLoginFailed,
  getSsoUserAction
} from "../../store/auth/auth-reducer"
import { AppState } from "../../store/state"

const Component = ({
  getSsoUser,
  isAuthenticated,
  hasLoginFailed
}: ConnectedProps<typeof connector>) => {
  if (hasLoginFailed) {
    return <Redirect to="/login" />
  } else if (isAuthenticated) {
    return <Redirect to="/" />
  } else if (getSsoUser()) {
    return null
  } else return <Redirect to="/login" />
}

const connector = connect(
  (state: AppState) => ({
    isAuthenticated: isAuthenticated(state),
    hasLoginFailed: isLoginFailed(state)
  }),
  { getSsoUser: getSsoUserAction }
)

export const SSoLogin = connector(Component)
