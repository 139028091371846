import React from "react"
import { connect } from "react-redux"
import { AppState } from "store/state"
import { Worksite } from "../../../store/worksite/models"
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl"
import { phone } from "../../../store/auth/auth-reducer"
import { submitOrder } from "../../../store/order/order-reducer"
import {
  BackgroundColor,
  ContainerWidth,
  ContentContainer
} from "../../../common/content-container/content-container"
import classNames from "classnames"
import { ContainerType, DeliveryType, Order } from "../common/models"
import {
  getDeliveryDate,
  getFirstAvailableDay,
  getFirstAvailableWeek
} from "../order-utils"
import { WeekPickerInputWrapper } from "../../../lib/week-picker/WeekPicker"
import { GeneralInformation } from "../GeneralInformation"
import Button from "../../../lib/button/button"
import { OtherFormState, OtherOrder, OtherWasteTypes } from "./models"

interface FrontloaderFormProps {
  phone: string
  propFormState: OtherFormState | null
  onSubmit: (order: Order, formState: OtherFormState) => void
  onCancel: () => void
  worksite: Worksite
}

const initialFormData = (phone: string): OtherFormState => {
  return {
    dateType: DeliveryType.RANGE,
    date: getFirstAvailableDay(),
    callBefore: false,
    moveDuringEmpty: false,
    containerNumber: "",
    contactNumber: phone,
    extraInfo: ""
  }
}

const OtherForm = ({
  phone,
  propFormState,
  onSubmit,
  onCancel,
  worksite,
  intl
}: FrontloaderFormProps & WrappedComponentProps) => {
  const [formState, setFormState] = React.useState<OtherFormState>(
    propFormState ? propFormState : initialFormData(phone)
  )

  const createAndSubmitOrder = () => {
    const BUSINESS_DAYS_TO_ADD = 4
    const order: OtherOrder = {
      type: "other",
      targetIdentifier: worksite.targetIdentifier,
      containerType: ContainerType.OTHER,
      desiredDeliveryTime: getDeliveryDate(
        formState.dateType,
        formState.date,
        BUSINESS_DAYS_TO_ADD
      ),
      wasteType: OtherWasteTypes.Other,
      contactNumber: formState.contactNumber,
      extraInfo: formState.extraInfo ? formState.extraInfo : null
    }
    onSubmit(order, formState)
  }

  const isFormDisabled = () => {
    return formState.contactNumber === ""
  }

  const showWeekPicker = () => {
    return formState.dateType === DeliveryType.WEEK
  }

  return (
    <ContentContainer
      containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}
      backgroundColor={BackgroundColor.WHITE}
    >
      <div className="modal">
        <div className="modal__header">
          <h1 className="lt-h4">
            <FormattedMessage id="order.form.other.title" />
          </h1>
          <button
            className="close-button"
            onClick={onCancel}
            aria-label={intl.formatMessage({ id: "common.close" })}
          />
        </div>
        <div className="modal__body">
          <p>
            <FormattedMessage id="order.form.other.description" />
          </p>
          <fieldset className="fieldset">
            <div className="label">
              <FormattedMessage id="order.form.date" /> *
            </div>
            <div className="selection-box">
              <div
                className={classNames("selection-box__item", {
                  "selection-box__item--selected":
                    formState.dateType === DeliveryType.RANGE
                })}
                onClick={() =>
                  setFormState({
                    ...formState,
                    dateType: DeliveryType.RANGE,
                    date: getFirstAvailableDay()
                  })
                }
              >
                <FormattedMessage id="order.form.asap" />
              </div>
              <div
                className={classNames("selection-box__item", {
                  "selection-box__item--selected":
                    formState.dateType === DeliveryType.WEEK
                })}
                onClick={() => {
                  setFormState({
                    ...formState,
                    dateType: DeliveryType.WEEK,
                    date: getFirstAvailableWeek()
                  })
                }}
              >
                <FormattedMessage id="order.form.choose-date-range" />
              </div>
            </div>
            {formState.dateType === DeliveryType.RANGE && (
              <p className="info-text lt-margin-top-s">
                <FormattedMessage id="order.form.compactor.info.date" />
              </p>
            )}
          </fieldset>

          {showWeekPicker() && (
            <fieldset className="fieldset">
              <div className="label">
                <FormattedMessage id="order.form.selected-date" /> *
              </div>
              <WeekPickerInputWrapper
                date={formState.date}
                onDayChange={day =>
                  setFormState({
                    ...formState,
                    dateType: DeliveryType.WEEK,
                    date: day
                  })
                }
              />
            </fieldset>
          )}

          <GeneralInformation
            inputValue={formState.contactNumber}
            onChangeInput={event =>
              setFormState({ ...formState, contactNumber: event.target.value })
            }
            textareaValue={formState.extraInfo}
            onChangeTextarea={event =>
              setFormState({ ...formState, extraInfo: event.target.value })
            }
            textareaPlaceholder={intl.formatMessage({
              id: "order.form.other.additional-info.placeholder"
            })}
          />

          <Button
            expand
            disabled={isFormDisabled()}
            onClick={createAndSubmitOrder}
          >
            <FormattedMessage id="order.form.to-confirmation" />
          </Button>
        </div>
      </div>
    </ContentContainer>
  )
}

const connector = connect(
  (state: AppState) => ({
    phone: phone(state)
  }),
  { submitOrder }
)

export default connector(injectIntl(OtherForm))
