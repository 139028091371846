import React from "react"
import { AppState } from "store/state"
import { format, parse } from "date-fns"
import { authenticatedUser, LoginResponse } from "store/auth/auth-reducer"
import { connect } from "react-redux"
import Button from "lib/button/button"

import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"
import { makeSwitchExhaustive } from "util/utils"
import { isSubmitFailed, isSubmitInProgress } from "store/order/order-reducer"
import {
  BackgroundColor,
  ContainerWidth,
  ContentContainer
} from "../../../common/content-container/content-container"
import {
  BaseFormTypes,
  ContainerType,
  DeliveryType,
  DesiredDeliveryTime,
  Order
} from "./models"
import { getDateFormatForUI } from "../order-utils"
import { Worksite } from "store/worksite/models"
import {
  DryToiletOrder,
  EmptyDryToiletOrder,
  NewDryToiletOrder
} from "../dry-toilet/models"
import {
  EmptyFrontloaderOrder,
  NewFrontloaderOrder
} from "../frontloader/models"
import {
  EmptyVaihtolavaOrder,
  ExistingVaihtolavaOrder,
  NewVaihtolavaOrder,
  VaihtolavaSpecialContainer,
  VaihtolavaSpecialRequirements
} from "../vaihtolava/models"
import { EmptyCompactorOrder } from "../compactor/models"
import { OtherOrder } from "../other/models"

interface OrderConfirmProps {
  titleId: string
  order: Order
  worksite: Worksite
  onCancel: () => void
  onSubmit: (order: Order) => void
}

interface ReduxProps {
  user: LoginResponse
  isSubmitInProgress: boolean
  isSubmitFailed: boolean
}

type Props = OrderConfirmProps & ReduxProps & WrappedComponentProps

const CompactorConfirm = ({
  titleId,
  order,
  user,
  onCancel,
  onSubmit,
  isSubmitInProgress,
  isSubmitFailed,
  intl,
  worksite
}: Props) => {
  // Emptying a frontloader & dry toilet needs some special cases
  const canSelectDifferentService = (
    order: Order
  ): order is EmptyFrontloaderOrder | EmptyDryToiletOrder =>
    (order.containerType === ContainerType.FRONTLOADER &&
      order.type === "emptying") ||
    (order.containerType === ContainerType.DRY_TOILET &&
      order.type === "emptying")

  const isNewVaihtolavaOrder = (order: Order): order is NewVaihtolavaOrder =>
    order.containerType === ContainerType.ROLL_OFF && order.type === "new"

  const isVaihtolavaSpecialContainer = (
    order: Order
  ): order is EmptyVaihtolavaOrder =>
    order.containerType === VaihtolavaSpecialContainer.Kansilava ||
    order.containerType === VaihtolavaSpecialContainer.Kippilava ||
    order.containerType === VaihtolavaSpecialContainer.Lokerolava

  const isEmptyVaihtolavaOrder = (
    order: Order
  ): order is EmptyVaihtolavaOrder =>
    (order.containerType === ContainerType.ROLL_OFF ||
      isVaihtolavaSpecialContainer(order)) &&
    order.type === "emptying"

  const isDryToilet = (order: Order): order is DryToiletOrder =>
    order.containerType === ContainerType.DRY_TOILET &&
    (order.type === "emptying" ||
      order.type === "new" ||
      order.type === "remove")

  const hasMoveDuringEmpty = (
    order: Order
  ): order is EmptyVaihtolavaOrder | EmptyCompactorOrder =>
    (order.containerType === ContainerType.ROLL_OFF ||
      order.containerType === ContainerType.COMPACTOR ||
      isVaihtolavaSpecialContainer(order)) &&
    order.type === "emptying"

  const hasContainerNumber = (
    order: Order
  ): order is EmptyVaihtolavaOrder | ExistingVaihtolavaOrder =>
    order.containerType === ContainerType.ROLL_OFF &&
    (order.type === "emptying" ||
      order.type === "move" ||
      order.type === "remove")

  const hasEmptyInterval = (
    order: Order
  ): order is NewFrontloaderOrder | NewDryToiletOrder =>
    (order.containerType === ContainerType.FRONTLOADER ||
      order.containerType === ContainerType.DRY_TOILET) &&
    order.type === "new"

  const isOtherOrder = (order: Order): order is OtherOrder =>
    order.containerType === ContainerType.OTHER && order.type === "other"

  const formatDateForUI = (date: string) =>
    format(
      parse(date, "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date()),
      getDateFormatForUI()
    )
  const getDate = (orderDate: DesiredDeliveryTime | null) => {
    if (!orderDate) {
      if (canSelectDifferentService(order)) {
        return (
          <FormattedMessage
            id="order.confirm.date.repeating"
            values={{ interval: order.emptyInterval }}
          />
        )
      } else {
        return <span> N/A </span>
      }
    }
    switch (orderDate.kind) {
      case DeliveryType.ASAP:
        return <FormattedMessage id="order.form.asap" />
      case DeliveryType.DAY:
        return <span>{formatDateForUI(orderDate.start)}</span>
      case DeliveryType.RANGE: {
        const formattedDateRange = (
          <span>
            {formatDateForUI(orderDate.start)} -{" "}
            {formatDateForUI(orderDate.end)}
          </span>
        )

        if (canSelectDifferentService(order)) {
          return (
            <>
              <FormattedMessage id="order.confirm.date.once" />:{" "}
              {formattedDateRange}
            </>
          )
        } else {
          return formattedDateRange
        }
      }
      case DeliveryType.NOT_APPLICABLE:
        if (canSelectDifferentService(order)) {
          return (
            <FormattedMessage
              id="order.confirm.date.repeating"
              values={{ interval: order.emptyInterval }}
            />
          )
        } else {
          return <span> N/A </span>
        }
      default:
        makeSwitchExhaustive(orderDate)
    }
  }
  const getSpecialRequirements = (order: Order) => {
    if (isNewVaihtolavaOrder(order)) {
      let specialRequirements = []

      if (order.liftingLugs) {
        specialRequirements.push(VaihtolavaSpecialRequirements.liftingLugs)
      }

      if (order.lowSide) {
        specialRequirements.push(VaihtolavaSpecialRequirements.lowSide)
      }

      return (
        <div className="table__row">
          <div className="table__cell">
            <FormattedMessage id="order.confirm.special-requests" />:
          </div>
          <div className="table__cell">{specialRequirements.join(", ")}</div>
        </div>
      )
    }
  }
  const getCallBeforeLabel = (orderType: BaseFormTypes) => {
    switch (orderType) {
      case BaseFormTypes.NEW:
        return <FormattedMessage id="order.confirm.call-before.new" />
      case BaseFormTypes.EMPTYING:
        return <FormattedMessage id="order.confirm.call-before.empty" />
      case BaseFormTypes.MOVE:
        return <FormattedMessage id="order.confirm.call-before.move" />
      case BaseFormTypes.REMOVE:
        return <FormattedMessage id="order.confirm.call-before.remove" />
      case BaseFormTypes.OTHER:
      case BaseFormTypes.UNSELECTED:
        return ""
      default:
        makeSwitchExhaustive(orderType)
    }
  }

  const fullAddress =
    worksite.city.length > 0
      ? `${worksite.streetName}, ${worksite.city}`
      : `${worksite.streetName}`

  return (
    <ContentContainer
      containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}
      backgroundColor={BackgroundColor.WHITE}
    >
      <div className="modal">
        <div className="container">
          <div className="modal__header">
            <h1 className="lt-h4">
              <FormattedMessage id="order.confirm.title" />
            </h1>
            <button
              className="close-button"
              onClick={onCancel}
              aria-label={intl.formatMessage({ id: "common.close" })}
            />
          </div>
          <div className="modal__body">
            <h2 className="lt-h6">
              <FormattedMessage id={titleId} />
            </h2>
            <div className="table lt-margin-bottom-m">
              <div className="table__row">
                <div className="table__cell">
                  {canSelectDifferentService(order) ? (
                    <>
                      <FormattedMessage id="order.confirm.service" />:{" "}
                    </>
                  ) : (
                    <>
                      <FormattedMessage id="order.confirm.date" />:{" "}
                    </>
                  )}
                </div>
                <div className="table__cell">
                  {getDate(order.desiredDeliveryTime)}
                </div>
              </div>
              {!isOtherOrder && (
                <div className="table__row">
                  <div className="table__cell">
                    <FormattedMessage id="order.confirm.container-type" />:
                  </div>
                  <div className="table__cell text-capitalize">
                    {isDryToilet(order)
                      ? order.specialContainer
                      : order.containerType}
                  </div>
                </div>
              )}

              {order.wasteType &&
                order.containerType !== ContainerType.DRY_TOILET &&
                !isOtherOrder && (
                  <div className="table__row">
                    <div className="table__cell">
                      <FormattedMessage id="order.confirm.waste-type" />:
                    </div>
                    <div className="table__cell">{order.wasteType}</div>
                  </div>
                )}
              {hasEmptyInterval(order) && (
                <div className="table__row">
                  <div className="table__cell">
                    <FormattedMessage id="order.confirm.empty-interval" />:
                  </div>
                  <div className="table__cell">{order.emptyInterval}</div>
                </div>
              )}
              {getSpecialRequirements(order)}
              {hasMoveDuringEmpty(order) && (
                <div className="table__row">
                  <div className="table__cell">
                    <FormattedMessage id="order.confirm.move-on-delivery" />:
                  </div>
                  <div className="table__cell">
                    {order.moveDuringEmpty ? (
                      <FormattedMessage id="common.yes" />
                    ) : (
                      <FormattedMessage id="common.no" />
                    )}
                  </div>
                </div>
              )}
              {isEmptyVaihtolavaOrder(order) && (
                <div className="table__row">
                  <div className="table__cell">
                    <FormattedMessage id="order.confirm.change-waste-type-to" />
                    :
                  </div>
                  <div className="table__cell">
                    {order.changeWasteTypeTo ? (
                      <FormattedMessage
                        id="order.confirm.change-waste-type-to.yes"
                        values={{ wasteType: order.changeWasteTypeTo }}
                      />
                    ) : (
                      <FormattedMessage id="common.no" />
                    )}
                  </div>
                </div>
              )}
              {hasContainerNumber(order) && (
                <div className="table__row">
                  <div className="table__cell">
                    <FormattedMessage id="order.confirm.container-number" />:
                  </div>
                  <div className="table__cell">{order.containerNumber}</div>
                </div>
              )}

              {!isOtherOrder(order) && (
                <div className="table__row">
                  <div className="table__cell">
                    {getCallBeforeLabel(order.type)}:
                  </div>
                  <div className="table__cell">
                    {order.callBefore ? (
                      <FormattedMessage id="common.yes" />
                    ) : (
                      <FormattedMessage id="common.no" />
                    )}
                  </div>
                </div>
              )}
              <div className="table__row">
                <div className="table__cell">
                  <FormattedMessage id="order.confirm.additional-info" />:
                </div>
                <div className="table__cell">{order.extraInfo}</div>
              </div>
              <div className="table__row">
                <div className="table__cell">
                  <FormattedMessage id="order.confirm.contact" />:
                </div>
                <div className="table__cell">{order.contactNumber}</div>
              </div>
              <div className="table__row">
                <div className="table__cell">
                  <FormattedMessage id="order.confirm.customer" />:
                </div>
                <div className="table__cell">{worksite.company}</div>
              </div>
              <div className="table__row">
                <div className="table__cell">
                  <FormattedMessage id="order.confirm.worksite.number" />:
                </div>
                <div className="table__cell">{worksite.siteIdentifier}</div>
              </div>
              <div className="table__row">
                <div className="table__cell">
                  <FormattedMessage id="order.confirm.worksite.address" />:
                </div>
                <div className="table__cell">{fullAddress}</div>
              </div>
              <div className="table__row">
                <div className="table__cell">
                  <FormattedMessage id="order.confirm.subscriber" />:
                </div>
                <div className="table__cell">{user.name}</div>
              </div>
            </div>
            {isSubmitFailed && (
              <p className="txt-error">
                <FormattedMessage id="order.confirm.error" />
              </p>
            )}
            <Button
              expand
              disabled={isSubmitInProgress}
              onClick={() => onSubmit(order)}
            >
              <FormattedMessage id="order.confirm.send" />
            </Button>
          </div>
        </div>
      </div>
    </ContentContainer>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: authenticatedUser(state),
  isSubmitInProgress: isSubmitInProgress(state),
  isSubmitFailed: isSubmitFailed(state)
})

export default connect(mapStateToProps, {})(injectIntl(CompactorConfirm))
