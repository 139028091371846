import * as React from "react"
import "./checkbox.sass"
import { ChangeEvent } from "react"
import { Ref } from "react"
import classNames from "classnames"

type Props = {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  inputRef?: Ref<HTMLInputElement>
  additionalClass?: string
  name?: string
}

const Checkbox: React.FunctionComponent<Props> = props => (
  <div className={classNames("checkbox", props.additionalClass)}>
    <label className="checkbox-container">
      <input
        id="replace-containers"
        type="checkbox"
        ref={props.inputRef}
        checked={props.checked}
        onChange={props.onChange}
        name={props.name}
      />
      <div className="checkmark" />
      {props.children}
    </label>
  </div>
)

export default Checkbox
