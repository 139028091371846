import { combineEpics, Epic } from "redux-observable"
import { AppAction } from "../action"
import { AppState } from "../state"
import { catchError, filter, map, mergeMap } from "rxjs/operators"
import { isOfType } from "typesafe-actions"
import { ActionTypes, setAllowedPostalCodes } from "./worksite-reducer"
import { makeAjax } from "../http"
import { AjaxResponse } from "rxjs/ajax"
import { AllowedPostalCodesResponse } from "./models"

const fetchAllowedPostalCodesEpic: Epic<
  AppAction,
  AppAction,
  AppState
> = action$ =>
  action$.pipe(
    filter(isOfType(ActionTypes.FETCH_ALLOWED_POSTAL_CODES)),
    mergeMap(() => {
      const settings = {
        url: "/api/orders/jassikka/allowed-postal-codes",
        method: "GET"
      }
      return makeAjax(settings).pipe(
        map((result: AjaxResponse) => {
          const response: AllowedPostalCodesResponse = result.response
          return setAllowedPostalCodes(response)
        }),
        catchError(error => [])
      )
    })
  )

export const worksiteEpic = combineEpics(fetchAllowedPostalCodesEpic)
