import Checkbox from "lib/checkbox/checkbox"
import * as React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { debounce, makeSwitchExhaustive } from "util/utils"
import { Worksite } from "../../../store/worksite/models"

import "./WorksiteSearch.sass"

enum FETCHSTATE {
  INITIAL,
  LOADING,
  FETCHED,
  ERROR
}

type RemoteData<T> =
  | { kind: FETCHSTATE.INITIAL }
  | { kind: FETCHSTATE.LOADING }
  | { kind: FETCHSTATE.FETCHED; data: T }
  | { kind: FETCHSTATE.ERROR; message?: string }

interface WorksiteSearchProps {
  worksites: Worksite[]
  onUpdate: (worksites: Worksite[]) => void
}

const getFilteredWorksites = (
  worksites: Worksite[],
  existingWorksites: Worksite[]
) => {
  return worksites.filter(
    w =>
      !existingWorksites.find(ew => ew.targetIdentifier === w.targetIdentifier)
  )
}

const WorksiteSearch = ({ worksites, onUpdate }: WorksiteSearchProps) => {
  const intl = useIntl()
  const [searchText, setSearchText] = React.useState("")
  const [worksiteResult, setWorksiteResult] = React.useState<
    RemoteData<Worksite[]>
  >({ kind: FETCHSTATE.INITIAL })
  const [displayResults, setDisplayResults] = React.useState(false)

  const onFetch = async (query: String, existingWorksites: Worksite[]) => {
    if (query.length < 3) {
      return
    }
    setWorksiteResult({ kind: FETCHSTATE.LOADING })
    const resp = await fetch(
      `${process.env.REACT_APP_URL}/api/worksites/search`,
      {
        method: "POST",
        body: JSON.stringify({ query }),
        headers: { "Content-Type": "application/json" }
      }
    )
    const json = await resp.json()
    if (resp.ok) {
      setWorksiteResult({
        kind: FETCHSTATE.FETCHED,
        data: getFilteredWorksites(json as Worksite[], existingWorksites)
      })
    } else {
      setWorksiteResult({ kind: FETCHSTATE.ERROR, message: json })
    }
  }

  const debouncedOnFetch = React.useMemo(() => debounce(onFetch, 500), [])

  const onChange = (value: string) => {
    setSearchText(value)
    debouncedOnFetch(value, worksites)
  }

  const onRemoveWorksite = (targetIdentifier: string) => {
    onUpdate(worksites.filter(w => w.targetIdentifier !== targetIdentifier))
  }

  const onAddWorksite = (worksite: Worksite) => {
    onUpdate(worksites.concat(worksite))
  }

  return (
    <div className="worksite-search">
      <h2 className="worksite-search__title">
        <FormattedMessage id="sales.worksite-search.title" />
      </h2>
      <div className="worksite-search__input-container">
        <input
          className="lt-input-text worksite-search__input"
          value={searchText}
          onChange={ev => onChange(ev.target.value)}
          placeholder={intl.formatMessage({
            id: "sales.worksite-search.placeholder"
          })}
          onFocus={() => setDisplayResults(true)}
          onBlur={() => setDisplayResults(false)}
        />
        {displayResults && (
          <WorksiteResultDisplay
            worksites={worksiteResult}
            onClick={worksite => onAddWorksite(worksite)}
            existingWorksites={worksites}
          />
        )}
      </div>
      <div>
        {worksites.map((w, i) => (
          <div
            key={`${w.targetIdentifier}-${i}`}
            className="worksite-search__checkbox"
          >
            <Checkbox
              checked
              onChange={() => onRemoveWorksite(w.targetIdentifier)}
            >
              <div className="worksite-search__checkbox-label">
                {`${w.targetIdentifier} - `}
                <FormattedMessage id="sales.worksite-search.worksite" />
                {` ${w.siteIdentifier}`}
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  )
}

interface WorksiteResultDisplayProps {
  worksites: RemoteData<Worksite[]>
  onClick: (worksite: Worksite) => void
  existingWorksites: Worksite[]
}

const WorksiteResultDisplay = ({
  worksites,
  onClick,
  existingWorksites
}: WorksiteResultDisplayProps) => {
  switch (worksites.kind) {
    case FETCHSTATE.INITIAL:
      return null
    case FETCHSTATE.LOADING:
      return (
        <div className="worksite-search__results worksite-search__results--loading">
          <FormattedMessage id="sales.worksite-search.loading" />
        </div>
      )
    case FETCHSTATE.ERROR:
      return (
        <div className="worksite-search__results worksite-search__results--loading">
          <FormattedMessage id="sales.worksite-search.error" />
        </div>
      )
    case FETCHSTATE.FETCHED:
      const filteredWorksites = getFilteredWorksites(
        worksites.data,
        existingWorksites
      )
      return (
        <div className="worksite-search__results">
          {filteredWorksites.map(w => (
            <div
              className="worksite-search__result-item"
              onMouseDown={() => onClick(w)}
              key={w.targetIdentifier}
            >
              <span> {w.targetIdentifier} </span>
              <span>{`${w.streetName} - ${w.siteIdentifier}`}</span>
            </div>
          ))}
        </div>
      )
    default:
      return makeSwitchExhaustive(worksites)
  }
}

export default WorksiteSearch
