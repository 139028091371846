import * as React from "react"
import { Redirect } from "react-router-dom"
import { ChangeEvent, SyntheticEvent } from "react"
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"
import "./login-page.sass"
import logo from "common/images/lt-raksanappi-logo-rgb.svg"
import Button from "lib/button/button"
import { connect, ConnectedProps } from "react-redux"
import {
  areCredentialsInvalid,
  hasSsoLoginFailed,
  isAuthenticated,
  isLoginInProgress,
  isSsoUserMissingRole,
  isUserBlocked,
  login,
  unknownLoginError
} from "store/auth/auth-reducer"
import { AppState } from "../../store/state"
import { TopBanner } from "../../common/top-banner/top-banner"

type OwnProps = {
  isTopBannerNotificationActive?: boolean
}

type Props = WrappedComponentProps & PropsFromRedux & OwnProps

interface State {
  username: string
  password: string
}

class Component extends React.PureComponent<Props, State> {
  private usernameInput: HTMLInputElement | null = null

  constructor(props: Props) {
    super(props)

    this.state = {
      username: "",
      password: ""
    }
  }

  componentDidMount() {
    document.title = this.props.intl.formatMessage({ id: "login.meta.title" })
    // Input fields are not mounted if redirecting to /home (user already logged in)
    if (this.usernameInput) {
      this.usernameInput.focus()
    }
  }

  setStateThroughInput = ({ target }: ChangeEvent<HTMLInputElement>) =>
    this.setState({ [target.name]: target.value } as any)

  render() {
    const {
      intl: { formatMessage },
      isAuthenticated,
      areCredentialsInvalid,
      isUserBlocked,
      unknownLoginError,
      hasSsoLoginFailed,
      isSsoUserMissingRole,
      isTopBannerNotificationActive
    } = this.props

    const resetPasswordUrl =
      process.env.REACT_APP_IAM_URL +
      "/passwords/reset?redirectUrl=" +
      encodeURIComponent(process.env.REACT_APP_URL || "")

    const unblockAccountLink =
      process.env.REACT_APP_IAM_URL + "/passwords/unblock?service=raksanappi"

    const ssoLoginLink =
      process.env.REACT_APP_LOGIN_URL +
      "/api/auth/sso/init?redirectUrl=" +
      encodeURIComponent((process.env.REACT_APP_URL || "") + "/sso-login")

    if (isAuthenticated) {
      return <Redirect to="/orders/create" />
    }

    return (
      <>
        {isTopBannerNotificationActive && <TopBanner />}
        <div className="login-page__container">
          <div className="login-page__content login-page__content--left">
            <form className="lt-form login-page__form" onSubmit={this.login}>
              <img className="login-page__logo" src={logo} alt="L&amp;T" />
              <h1 className="login-page__title">
                <FormattedMessage id="login.title" />
              </h1>
              {(areCredentialsInvalid ||
                isUserBlocked ||
                unknownLoginError) && (
                <div className="login-page__error-container">
                  {areCredentialsInvalid && (
                    <FormattedMessage id="login.failed" />
                  )}
                  {isUserBlocked && (
                    <FormattedMessage
                      id="login.user-blocked.text"
                      values={{
                        link: (
                          <FormattedMessage id="login.user-blocked.link">
                            {text => (
                              <a
                                href={unblockAccountLink}
                                className="login-page__link"
                                rel="noopener noreferrer"
                              >
                                {text}
                              </a>
                            )}
                          </FormattedMessage>
                        )
                      }}
                    />
                  )}
                  {unknownLoginError && (
                    <FormattedMessage id="login.failed.service-unavailable" />
                  )}
                </div>
              )}
              {hasSsoLoginFailed && !isSsoUserMissingRole && (
                <div className="login-page__error-container">
                  <FormattedMessage id={"login.sso_failed"} />
                </div>
              )}
              {isSsoUserMissingRole && (
                <div className="login-page__error-container">
                  <FormattedMessage id={"login.sso_failed_missing_role"} />
                </div>
              )}
              <input
                id="username"
                name="username"
                className="login-page__input"
                type="text"
                ref={node => (this.usernameInput = node)}
                onChange={this.setStateThroughInput}
                placeholder={formatMessage({ id: "login.username" })}
                aria-required="true"
              />
              <input
                id="password"
                name="password"
                className="login-page__input"
                type="password"
                onChange={this.setStateThroughInput}
                placeholder={formatMessage({ id: "login.password" })}
                aria-required="true"
              />
              <a
                className="login-page__link login-page__forgot-password-link"
                href={resetPasswordUrl}
              >
                <FormattedMessage id="login.forgot-password" />
              </a>
              <Button
                disabled={this.isLoginDisabled()}
                additionalClass="login-page__button"
              >
                <FormattedMessage id="login.submit" />
              </Button>
              <p className="lt-text-center lt-text-small login-p">
                <FormattedMessage id="login.lt.link">
                  {text => (
                    <a
                      href={ssoLoginLink}
                      className="login-page__link"
                      rel="noopener noreferrer"
                    >
                      {text}
                    </a>
                  )}
                </FormattedMessage>
              </p>
              <p className="lt-text-center lt-text-small login-p">
                <FormattedMessage
                  id="login.credentials.text"
                  values={{
                    link: (
                      <FormattedMessage id="login.credentials.link">
                        {text => (
                          <a
                            href="https://www.lt.fi/fi/raksanappi#form"
                            className="login-page__link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {text}
                          </a>
                        )}
                      </FormattedMessage>
                    )
                  }}
                />
              </p>
              <div className="login-page-line" />
              <p className="lt-text-center lt-text-small login-p">
                <FormattedMessage
                  id="login.additional-info.text"
                  values={{
                    link: (
                      <FormattedMessage id="login.additional-info.link">
                        {text => (
                          <a
                            href="https://www.lt.fi/fi/raksanappi"
                            className="login-page__link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {text}
                          </a>
                        )}
                      </FormattedMessage>
                    )
                  }}
                />
              </p>
            </form>
          </div>
          <div className="login-page__content login-page__content--right"></div>
        </div>
      </>
    )
  }

  private login = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    this.props.login(this.state)
  }

  private isLoginDisabled = () => {
    return (
      !this.state.username?.trim() ||
      !this.state.password?.trim() ||
      this.props.isLoginInProgress
    )
  }
}

const connector = connect(
  (state: AppState) => ({
    isAuthenticated: isAuthenticated(state),
    isLoginInProgress: isLoginInProgress(state),
    hasSsoLoginFailed: hasSsoLoginFailed(state),
    isSsoUserMissingRole: isSsoUserMissingRole(state),
    areCredentialsInvalid: areCredentialsInvalid(state),
    isUserBlocked: isUserBlocked(state),
    unknownLoginError: unknownLoginError(state)
  }),
  { login }
)

type PropsFromRedux = ConnectedProps<typeof connector>

export const LoginPage = connector(injectIntl(Component))
