import {
  getHours,
  addBusinessDays,
  getDay,
  startOfISOWeek,
  addWeeks,
  isWeekend,
  endOfISOWeek,
  format
} from "date-fns"
import { DeliveryType, DesiredDeliveryTime } from "./common/models"
import { makeSwitchExhaustive } from "../../util/utils"

export const getDateFormatForUI = (): string => "dd.MM.yyyy"

const formatDateForPayload = (date: Date): string =>
  format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'")

export const getFirstAvailableDay = (): Date => {
  const now = new Date()
  return getHours(now) < 14 ? addBusinessDays(now, 1) : addBusinessDays(now, 2)
}

/**
 * Returns the first available Monday for week orders.
 */
export function getFirstAvailableWeek(): Date {
  const now = new Date()
  const weeksToAdd =
    (getDay(now) === 5 && now.getHours() >= 16) || isWeekend(now) ? 2 : 1
  return startOfISOWeek(addWeeks(now, weeksToAdd))
}

export const getDeliveryDate = (
  deliveryDateType: DeliveryType,
  deliveryDate: Date,
  businessDaysToAdd: number = 0
): DesiredDeliveryTime | null => {
  switch (deliveryDateType) {
    case DeliveryType.ASAP:
      return {
        kind: DeliveryType.ASAP
      }
    case DeliveryType.RANGE:
      return {
        kind: DeliveryType.RANGE,
        start: formatDateForPayload(deliveryDate),
        end: formatDateForPayload(
          addBusinessDays(deliveryDate, businessDaysToAdd)
        )
      }
    case DeliveryType.WEEK:
      return {
        kind: DeliveryType.RANGE,
        start: formatDateForPayload(startOfISOWeek(deliveryDate)),
        end: formatDateForPayload(endOfISOWeek(deliveryDate))
      }
    case DeliveryType.DAY:
      return {
        kind: DeliveryType.DAY,
        start: formatDateForPayload(deliveryDate)
      }
    case DeliveryType.NOT_APPLICABLE:
      return null
    default:
      makeSwitchExhaustive(deliveryDateType)
  }
}
