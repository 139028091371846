// Using this method as the default case of switch statements gives ts compiler the ability to see if
// all cases have been handled
export function makeSwitchExhaustive(_unused: any): never {
  throw new Error("This should never happen")
}

// Native debounce function

export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number = 300
) {
  let timer: NodeJS.Timeout

  return (...args: T): Promise<U> => {
    clearTimeout(timer)
    return new Promise(resolve => {
      timer = setTimeout(() => resolve(callback(...args)), wait)
    })
  }
}

// Strict integer parser
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt#a_stricter_parse_function
export function strictParseInt(value: string) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value)
  } else {
    return NaN
  }
}

export const stringHasValue = (v: string | undefined | null) =>
  (v || "").trim().length > 0
