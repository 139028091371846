import { IntlShape } from "react-intl"
import { UserFormData } from "../sales-view-api"

export interface UserFormDataValidations {
  firstName: string[]
  lastName: string[]
  email: string[]
  phoneNumber: string[]
  isValid: boolean
}

const phoneRegex = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export const validate = (
  formData: UserFormData,
  intl: IntlShape
): UserFormDataValidations => {
  const firstName = validateNonEmpty(
    formData.firstName,
    intl.formatMessage({ id: "sales.validations.firstName" }),
    intl
  )
  const lastName = validateNonEmpty(
    formData.lastName,
    intl.formatMessage({ id: "sales.validations.lastName" }),
    intl
  )
  const phoneNumber = validateNonEmpty(
    formData.phoneNumber,
    intl.formatMessage({ id: "sales.validations.phone" }),
    intl
  ).concat(
    validateRegex(
      formData.phoneNumber,
      phoneRegex,
      intl.formatMessage({ id: "sales.validations.phone" }),
      intl
    )
  )
  const email = validateNonEmpty(
    formData.email,
    intl.formatMessage({ id: "sales.validations.email" }),
    intl
  ).concat(
    validateEmail(
      formData.email,
      intl.formatMessage({ id: "sales.validations.email" }),
      intl
    )
  )
  const isValid =
    [...firstName, ...lastName, ...phoneNumber, ...email].length <= 0
  return {
    firstName,
    lastName,
    phoneNumber,
    email,
    isValid
  }
}

export const defaultValidation: UserFormDataValidations = {
  firstName: [],
  lastName: [],
  phoneNumber: [],
  email: [],
  isValid: true
}

export const emailValid = (email: string): boolean => {
  if (
    email.trim().length === 0 ||
    !emailRegex.test(email) ||
    // Some users paste the email and it contains the 'mailto:' string.
    // It causes unnecessary noise in the error logs because the code is checking from the IAM if the email exists.
    // https://lassila-tikanoja.slack.com/archives/CCCFQ455F/p1642680040024600
    email.includes("mailto:")
  ) {
    return false
  }
  return true
}

const validateNonEmpty = (value: string, tag: string, intl: IntlShape) => {
  return value.length > 0
    ? []
    : [`${tag} ${intl.formatMessage({ id: "sales.validations.notEmpty" })}`]
}

const validateRegex = (
  value: string,
  regex: RegExp,
  tag: string,
  intl: IntlShape
) => {
  return regex.test(value)
    ? []
    : [
        `${tag}: ${intl.formatMessage({
          id: "sales.validations.correctFormat"
        })}`
      ]
}

const validateEmail = (value: string, tag: string, intl: IntlShape) => {
  return emailValid(value)
    ? []
    : [
        `${tag}: ${intl.formatMessage({
          id: "sales.validations.correctFormat"
        })}`
      ]
}
