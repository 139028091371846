import { combineEpics, Epic } from "redux-observable"
import {
  AuthAction,
  ActionTypes,
  LoginAction,
  loginFailed,
  LoginResponse,
  loginSuccessful,
  userReceivedAction,
  loggedOut,
  ssoLoginFailed,
  ssoLoginFailedMissingRole,
  noUserReceived,
  termsAcceptedSuccess
} from "./auth-reducer"
import { AjaxResponse } from "rxjs/ajax"
import { AppState } from "../state"
import { isOfType } from "typesafe-actions"
import { catchError, filter, map, mergeMap } from "rxjs/operators"
import { makeAjax, postJson } from "../http"
import { AppAction } from "../action"
import { isUserInactive, isUnknownLoginError } from "../../util/errors"

const loginEpic: Epic<AppAction, AppAction, AppState> = action$ =>
  action$.pipe(
    filter(isOfType(ActionTypes.LOGIN)),
    mergeMap((action: LoginAction) => {
      const settings = {
        url: "/api/auth/login",
        method: "POST",
        body: {
          username: action.payload.username,
          password: action.payload.password
        }
      }
      return makeAjax(settings).pipe(
        map((result: AjaxResponse) => {
          const response: LoginResponse = result.response
          const code = result.status
          if (code >= 200 && code <= 300) {
            return loginSuccessful(response)
          } else {
            return loginFailed(
              isUserInactive(response),
              isUnknownLoginError(response)
            )
          }
        }),
        catchError(error => [
          loginFailed(
            isUserInactive(error.response),
            isUnknownLoginError(error.response)
          )
        ])
      )
    })
  )

const getUserEpic: Epic<AppAction, AppAction, AppState> = action$ =>
  action$.pipe(
    filter(isOfType(ActionTypes.GET_USER)),
    mergeMap(() => {
      const settings = {
        url: "/api/me",
        method: "GET"
      }
      return makeAjax(settings).pipe(
        map((result: AjaxResponse) => {
          const code = result.status
          if (code >= 200 && code <= 300) {
            return userReceivedAction(result.response)
          } else {
            return noUserReceived()
          }
        }),
        catchError(() => [noUserReceived()])
      )
    })
  )

const completeSsoEpic: Epic<AuthAction, AuthAction, AppState> = action$ =>
  action$.pipe(
    filter(isOfType(ActionTypes.GET_SSO_USER)),
    mergeMap(() => {
      const settings = {
        url: "/api/me",
        method: "GET"
      }
      return makeAjax(settings).pipe(
        map((result: AjaxResponse) => {
          const code = result.status
          if (code >= 200 && code <= 300) {
            return loginSuccessful(result.response)
          } else {
            return ssoLoginFailed()
          }
        }),
        catchError(e => {
          if (e.status === 403) {
            return [ssoLoginFailedMissingRole()]
          } else {
            return [ssoLoginFailed()]
          }
        })
      )
    })
  )

const logoutEpic: Epic<AppAction, AppAction, AppState> = action$ =>
  action$.pipe(
    filter(isOfType(ActionTypes.LOGOUT)),
    mergeMap(() => postJson("/api/auth/logout").pipe(map(() => loggedOut())))
  )

const acceptTermsEpic: Epic<AppAction, AppAction, AppState> = action$ =>
  action$.pipe(
    filter(isOfType(ActionTypes.ACCEPT_TERMS)),
    mergeMap(() =>
      postJson("/api/me/accept-terms").pipe(
        map(res => termsAcceptedSuccess(res.response))
      )
    )
  )

export const authEpic = combineEpics(
  loginEpic,
  getUserEpic,
  logoutEpic,
  completeSsoEpic,
  acceptTermsEpic
)
