import * as React from "react"
import "./content-container.sass"
import classNames from "classnames"

export enum ContainerWidth {
  RESTRICT,
  RESTRICT_STRETCH_MOBILE,
  FULL_WIDTH
}

export enum BackgroundColor {
  WHITE,
  GRAY,
  LIGHT_GRAY
}

interface Props {
  children?: React.ReactNode
  title?: React.ReactNode
  containerWidth?: ContainerWidth
  backgroundColor?: BackgroundColor
}

export const ContentContainer = ({
  title,
  children,
  containerWidth = ContainerWidth.FULL_WIDTH,
  backgroundColor = BackgroundColor.GRAY
}: Props) => {
  const innerClasses = []
  const containerClasses = []

  switch (containerWidth) {
    case ContainerWidth.RESTRICT:
      innerClasses.push("content-container__inner--width-restrict")
      break
    case ContainerWidth.RESTRICT_STRETCH_MOBILE:
      innerClasses.push("content-container__inner--width-restrict")
      innerClasses.push("content-container__inner--mobile-stretch")
  }

  if (backgroundColor === BackgroundColor.WHITE) {
    containerClasses.push("background-color--white")
  } else if (backgroundColor === BackgroundColor.LIGHT_GRAY) {
    containerClasses.push("background-color--light-gray")
  } else {
    containerClasses.push("background-color--gray")
  }

  return (
    <div className={classNames("content-container", containerClasses)}>
      {title}
      <div className={classNames("content-container__inner", innerClasses)}>
        {children}
      </div>
    </div>
  )
}
