import "./terms-and-conditions-page.sass"
import * as React from "react"

// TODO Static MVP, fetch from Contentful
const TermsAndConditionsPage = () => {
  return (
    <div className="content-container background-color--gray">
      <section className="terms-and-conditions__content">
        <h1 id="lassila-tikanoja-oyj-n-raksanappi-verkkoasiointipalvelun-k-ytt-ehdot">
          L&T Ympäristöpalvelut Oy:n Raksanappi- verkkoasiointipalvelun
          käyttöehdot
        </h1>

        <h2 id="1-yleist-">1. Yleistä</h2>

        <p>
          Raksanappi-verkkoasiointipalvelua (jäljempänä ”palvelu”) ylläpitää ja
          sen tarjoaa käyttöön L&T Ympäristöpalvelut Oy (jäljempänä ”L&T”).
        </p>
        <p>
          Käyttämällä palvelua käyttäjä hyväksyy nämä käyttöehdot. Mikäli
          käyttäjä ei hyväksy näitä käyttöehtoja, käyttäjällä ei ole oikeutta
          asentaa, avata tai käyttää palvelua.
        </p>
        <p>
          L&T pidättää oikeuden muuttaa näitä käyttöehtoja milloin tahansa ilman
          ennakkoilmoitusta. Käyttäjän katsotaan hyväksyneen päivitetyt
          käyttöehdot käyttämällä palvelua käyttöehtoihin tehdyn muutoksen
          jälkeen. L&T:llä on oikeus käyttää alihankkijoita tarjotessaan
          palvelua.
        </p>

        <h2 id="2-immateriaalioikeudet-ja-k-ytt-rajoitukset">
          2. Immateriaalioikeudet ja käyttörajoitukset
        </h2>

        <p>
          Kaikki palveluun sekä sen sisältämiin tietoihin, toimintoihin ja
          muuhun sisältöön liittyvät immateriaalioikeudet kuuluvat L&T:lle.
          Käyttäjällä ei ole oikeutta muuttaa, muunnella, poistaa, kopioida,
          julkaista, saattaa yleisön saataviin tai siirtää mitään
          immateriaalioikeuksien kohteita.
        </p>
        <p>
          Käyttäjän oikeus käyttää palvelua rajoittuu ainoastaan käyttäjän
          henkilökohtaiseen, ei- kaupalliseen käyttöön. Palvelun kaupallinen
          käyttö on kiellettyä. Käyttäjää nimenomaisesti kielletään myöntämästä
          palveluun alilisenssiä sekä vuokraamasta, siirtämästä tai muuten
          jakelemasta palvelua tai palvelun käyttöoikeuksia.
        </p>
        <p>
          L&T pidättää kaikki muut paitsi näissä käyttöehdoissa nimenomaisesti
          myönnetyt oikeudet.
        </p>

        <h2 id="3-palvelun-k-yt-st-aiheutuvat-kulut">
          3. Palvelun käytöstä aiheutuvat kulut
        </h2>

        <p>
          Käyttäjän tulee omalla kustannuksellaan hankkia laitteet ja
          internetyhteydet, joita tarvitaan palvelun käyttämiseksi. L&T ei
          takaa, että palvelu olisi saatavilla kaikille laitteille.
        </p>
        <p>
          Käyttäjä on yksinomaan vastuussa tiedonsiirtokuluista ja muista
          palvelun käyttöön liittyvistä maksuista.
        </p>

        <h2 id="4-henkil-kohtaiset-k-ytt-j-tunnukset">
          4. Henkilökohtaiset käyttäjätunnukset
        </h2>

        <p>
          Palvelun käyttöä varten käyttäjällä luovutettu käyttäjätunnus ja
          salasana ovat henkilökohtaisia, eikä niitä saa luovuttaa tai ilmaista
          kolmannelle osapuolelle. Käyttäjä on vastuussa kaikista kyseisellä
          käyttäjätunnuksella tapahtuneesta palvelun käytöstä.
        </p>

        <h2 id="5-saatavuus-ja-takuu">5. Saatavuus ja takuu</h2>

        <p>
          Palvelu tarjotaan käyttäjän käyttöön sellaisenaan. Palvelu saattaa
          ajoittain olla poissa käytöstä tai palvelun toimintaa voi tilapäisesti
          haitata virheet ja ylläpitotoimet.
        </p>
        <p>
          L&T ei anna mitään palveluun ja sen kautta käytettävän palvelun
          laatuun, toimivuuteen, saatavuuteen tai suorituskykyyn liittyvää
          takuuta. L&T pidättää oikeuden muuttaa palvelua tai poistaa palvelun
          tai sen osia käytöstä milloin tahansa ilman erillistä ilmoitusta.
        </p>

        <h2 id="6-ev-steet">6. Evästeet</h2>

        <p>
          L&T tai kolmannet osapuolet voivat käyttää evästeitä, jäljitteitä ja
          muita analyysitekniikoita, joiden avulla ne voivat kerätä, käyttää,
          tallentaa ja välittää teknisiä ja muita tietoja. Palvelun käyttäjistä
          ei kerätä tietoja, joista yksittäinen käyttäjä olisi tunnistettavissa.
          Evästeiden avulla kerättyä tietoa voidaan käyttää esimerkiksi palvelun
          kehittämistä varten.
        </p>

        <h2 id="7-lt-vastuu">7. L&amp;T vastuu</h2>

        <p>
          Missään tapauksessa L&T ei ole vastuussa käyttäjälle, käyttäjän
          edustamalle yhteisölle tai kolmannelle osapuolelle mistään
          välittömästä tai välillisestä vahingosta. L&T ei myöskään ole
          vastuussa käyttäjälle, käyttäjän edustamalle yhteisölle tai
          kolmannelle osapuolelle mistään palvelun tai palvelun välityksellä
          tarjotun palvelun käytöstä tai väärinkäytöstä seuraavista tappioista
          tai tulonmenetyksistä.
        </p>

        <h2 id="8-henkil-tiedot-ja-niiden-k-ytt-">
          8. Henkilötiedot ja niiden käyttö
        </h2>

        <p>
          L&T käyttää palvelun käyttämisen aikana mahdollisesti saamiaan
          henkilötietoja palvelun tuottamiseen sekä palvelun kehittämiseen ja
          ongelmien korjaukseen.
        </p>

        <h2 id="9-sovellettava-laki-ja-erimielisyyksien-ratkaiseminen">
          9. Sovellettava laki ja erimielisyyksien ratkaiseminen
        </h2>

        <p>
          Näihin käyttöehtoihin sovelletaan Suomen lakia, lukuun ottamatta sen
          lainvalintaa koskevia säännöksiä. Mahdolliset erimielisyydet
          ratkaistaan Helsingin käräjäoikeudessa.
        </p>

        <h2 id="10-yhteystiedot">10. Yhteystiedot</h2>
        <p>
          L&T Ympäristöpalvelut Oy
          <br />
          PL 28
          <br />
          00441 Helsinki
          <br />
          Y-tunnus: 3155938-4
        </p>
      </section>
    </div>
  )
}

export default TermsAndConditionsPage
