import { ajax } from "rxjs/ajax"
import { AjaxRequest } from "rxjs/internal/observable/dom/AjaxObservable"
import { throwError } from "rxjs"

export const httpErrorHandler = (error: any) => {
  return throwError(error)
}

export const makeAjax = (request: AjaxRequest) => {
  const options = {
    ...request,
    responseType: "json",
    timeout: 30 * 1000,
    withCredentials: true
  }
  return ajax(options)
}

export const apiRequest = (
  path: string,
  method: string = "GET"
): AjaxRequest => {
  return {
    url: path,
    method: method
  }
}

export const makeRequest = (path: string, method: string, body?: any) => {
  const request = {
    ...apiRequest(path, method),
    body: body,
    headers: {
      "Content-Type": "application/json"
    }
  }
  return makeAjax(request)
}

export const postJson = (path: string, body?: any) =>
  makeRequest(path, "POST", body)
