import React from "react"
import { FormattedMessage } from "react-intl"

interface OtherInformationProps {
  inputValue: string
  onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void
  textareaValue: string
  onChangeTextarea: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  textareaPlaceholder: string
  customInfoMessage?: JSX.Element
}

export const GeneralInformation = ({
  inputValue,
  onChangeInput,
  textareaValue,
  onChangeTextarea,
  textareaPlaceholder,
  customInfoMessage
}: OtherInformationProps) => (
  <>
    <fieldset className="fieldset">
      <label htmlFor="phone" className="label">
        <FormattedMessage id="order.form.contact-number" /> *
      </label>
      <input
        type="tel"
        className="lt-input-text"
        name="phone"
        value={inputValue}
        onChange={onChangeInput}
      />
    </fieldset>
    <fieldset className="fieldset">
      <label htmlFor="additional-info" className="label">
        {customInfoMessage || (
          <FormattedMessage id="order.form.additional-info" />
        )}
      </label>
      <textarea
        name="additional-info"
        className="lt-textarea"
        rows={5}
        value={textareaValue}
        placeholder={textareaPlaceholder}
        onChange={onChangeTextarea}
      />
    </fieldset>
  </>
)
