import "./accept-terms-page.sass"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Dispatch } from "redux"
import {
  ContainerWidth,
  ContentContainer
} from "../../common/content-container/content-container"
import { acceptTerms } from "../../store/auth/auth-reducer"
import { connect } from "react-redux"

interface Props {
  onSubmit: () => void
}

interface State {
  checked: boolean
}

class AcceptTermsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { checked: false }
  }

  render() {
    return (
      <ContentContainer containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}>
        <div className="accept-terms">
          <h1 className="accept-terms__title">
            <FormattedMessage id="accept-terms.title" />
          </h1>
          <p className="accept-terms__description">
            <FormattedMessage id="accept-terms.description" />
          </p>
          <label className="checkbox-container accept-terms__checkbox-label">
            <input
              id="accept-terms"
              type="checkbox"
              checked={this.state.checked}
              onChange={e => this.setState({ checked: e.target.checked })}
            />
            <div className="checkmark" />
            <FormattedMessage id="accept-terms.accept" />
            &nbsp;
            <a href="/terms-and-conditions" target="_blank">
              <FormattedMessage id="accept-terms.terms" />
            </a>
            &nbsp;
            <FormattedMessage id="accept-terms.and-read" />
            &nbsp;
            <a
              href="https://www.lt.fi/fi/tietosuojaseloste#yritysasiakas--ja-yhteistyökumppanirekisterin-tietosuojaseloste"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="accept-terms.privacy-policy" />
            </a>
          </label>
          <button
            className="button"
            disabled={!this.state.checked}
            onClick={() => this.props.onSubmit()}
          >
            <FormattedMessage id="accept-terms.accept-button" />
          </button>
        </div>
      </ContentContainer>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: () => dispatch(acceptTerms())
})

export default connect(null, mapDispatchToProps)(AcceptTermsPage)
