import classNames from "classnames"
import LoadingSpinner from "common/loading-spinner/loading-spinner"
import { useCallback, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { ReactComponent as DownloadButton } from "../../../images/icon-download-button.svg"
import FormatAddress from "../common/FormatAddress"
import { noWasteSortingPlanDocument } from "../document-utils"
import {
  WasteSortingPlanDocumentResponse,
  WasteSortingPlanDocumentStatus
} from "../documents-api"

interface SortingPlanDesktopProps {
  wasteSortingPlanDocuments: WasteSortingPlanDocumentResponse[]
  loaded: boolean
  loadFailed: boolean
}

const SortingPlanDesktop = ({
  wasteSortingPlanDocuments,
  loaded,
  loadFailed
}: SortingPlanDesktopProps) => {
  const getLoadFailedDesktop = useMemo(
    () => (
      <tr className="document document--desktop document--desktop-no-document">
        <td
          colSpan={4}
          className="document__value document__value--desktop document__value--desktop-no-document"
        >
          <FormattedMessage id="common.error" />
        </td>
      </tr>
    ),
    []
  )

  const getNoDocumentDesktop = useCallback(
    (document: WasteSortingPlanDocumentResponse) => {
      return (
        <td
          colSpan={4}
          className="document__value document__value--desktop document__value--desktop-no-document"
        >
          {document.documentStatus ===
            WasteSortingPlanDocumentStatus.notFound && (
            <FormattedMessage id="documents.sorting.no-document" />
          )}
          {document.documentStatus === WasteSortingPlanDocumentStatus.error && (
            <FormattedMessage id="common.error" />
          )}
        </td>
      )
    },
    []
  )
  const getDesktopRows = useCallback(
    (
      wasteSortingPlanDocuments: WasteSortingPlanDocumentResponse[],
      loaded: boolean,
      loadFailed: boolean
    ) => {
      if (loadFailed) return getLoadFailedDesktop
      if (loaded)
        return wasteSortingPlanDocuments.map((document, index) => (
          <tr
            className={classNames("document document--desktop", {
              "document--desktop-no-document":
                noWasteSortingPlanDocument(document)
            })}
            key={index}
          >
            {document.documentStatus === WasteSortingPlanDocumentStatus.ok && (
              <>
                <td className="document__value document__value--desktop document__value--desktop-bold">
                  <FormattedMessage id="documents.sorting.plan-file-name" />
                </td>
                <td className="document__value document__value--desktop document__value--desktop-bold">
                  {document.worksiteDescription}
                </td>
                <td className="document__value document__value--desktop">
                  <FormatAddress document={document} />
                </td>
                <td className="document__value document__value--desktop">
                  <a
                    href={document.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DownloadButton />
                  </a>
                </td>
              </>
            )}
            {noWasteSortingPlanDocument(document) &&
              getNoDocumentDesktop(document)}
          </tr>
        ))

      return (
        <tr>
          <td>
            <LoadingSpinner className="loading-spinner--line" />
          </td>
          <td>
            <LoadingSpinner className="loading-spinner--line" />
          </td>
          <td>
            <LoadingSpinner className="loading-spinner--line" />
          </td>
          <td>
            <LoadingSpinner className="loading-spinner--line" />
          </td>
        </tr>
      )
    },
    [getLoadFailedDesktop, getNoDocumentDesktop]
  )

  return (
    <table className="documents documents--desktop">
      <thead>
        <tr>
          <th className="document__header document__header--desktop document__header--desktop-name">
            <FormattedMessage id="documents.sorting.document" />
          </th>
          <th className="document__header document__header--desktop">
            <FormattedMessage id="documents.common.worknumber" />
          </th>
          <th className="document__header document__header--desktop">
            <FormattedMessage id="documents.common.site" />
          </th>
          <th className="document__header document__header--download-desktop" />
        </tr>
      </thead>
      <tbody>
        {getDesktopRows(wasteSortingPlanDocuments, loaded, loadFailed)}
      </tbody>
    </table>
  )
}

export default SortingPlanDesktop
