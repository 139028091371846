import * as React from "react"
import "./context-navigation.sass"
import { ReactNode } from "react"

export const ContextNavigation = ({ children }: { children?: ReactNode }) => {
  let elements: ReactNode[] = []

  if (Array.isArray(children)) {
    elements = children
  } else if (children) {
    elements.push(children)
  }

  return (
    <div className="context-navigation">
      <div className="context-navigation__content">
        <ul className="context-navigation__list">
          {elements.map((element, key) => (
            <li className="context-navigation__list-item" key={"nav-" + key}>
              {element}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
