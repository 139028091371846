import React from "react"
import { FormattedMessage } from "react-intl"
import {
  ContainerWidth,
  ContentContainer
} from "common/content-container/content-container"
import { BaseFormTypes } from "./models"
import iconNew from "common/images/icon-large-new.svg"
import iconEmpty from "common/images/icon-large-empty.svg"
import iconMove from "common/images/icon-large-move.svg"
import iconRemove from "common/images/icon-large-remove.svg"

export interface OrderSelectionItem {
  titleId: string
  selectedFormType: BaseFormTypes
}

interface SelectionProps {
  onClick: (selection: BaseFormTypes) => void
  selectionTitleId: string
  items: OrderSelectionItem[]
}

const OrderSelection = ({
  selectionTitleId,
  items,
  onClick
}: SelectionProps) => {
  const getIcon = (formType: BaseFormTypes) => {
    switch (formType) {
      case "new":
        return iconNew
      case "emptying":
        return iconEmpty
      case "move":
        return iconMove
      case "remove":
        return iconRemove
      default:
        return ""
    }
  }

  return (
    <ContentContainer containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}>
      <h1 className="lt-h4">
        <FormattedMessage id={selectionTitleId} />
      </h1>
      <div className="grid container__mobile-stretch-elem">
        {items.map((item: OrderSelectionItem, idx: number) => (
          <div
            key={idx}
            className="grid__item"
            onClick={() => onClick(item.selectedFormType)}
          >
            <div className="box box--square">
              <div className="box__content">
                <img
                  className="box__icon"
                  src={getIcon(item.selectedFormType)}
                  alt=""
                />
                <h2 className="lt-h6 lt-margin-top-none">
                  <FormattedMessage id={item.titleId} />
                </h2>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ContentContainer>
  )
}

export default OrderSelection
