import React, { useEffect, useState } from "react"
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl"
import { UserFormData } from "../sales-view-api"

import "./UserInputs.sass"
import { UserFormDataValidations } from "./validations"

type Props = {
  user: UserFormData
  validations: UserFormDataValidations
  update: (user: UserFormData) => void
}

export const UserInputs: React.FC<Props> = injectIntl(
  ({ user, validations, update, intl }: Props & WrappedComponentProps) => {
    const [firstName, setFirstName] = useState(user.firstName)
    const [lastName, setLastName] = useState(user.lastName)
    const [email, setEmail] = useState(user.email)
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)

    useEffect(() => {
      const timer = setTimeout(
        () =>
          update({
            firstName,
            lastName,
            phoneNumber,
            email,
            terms: user.terms,
            role: user.role,
            hasAccessToReports: user.hasAccessToReports
          }),
        500
      )
      return () => clearTimeout(timer)
    }, [
      update,
      firstName,
      lastName,
      phoneNumber,
      email,
      user.terms,
      user.role,
      user.hasAccessToReports
    ])

    return (
      <>
        <h2 className="user-inputs__title">
          <FormattedMessage id="sales.user-inputs.title" />
        </h2>
        <div className="user-inputs__container">
          <input
            className="lt-input-text"
            placeholder={intl.formatMessage({
              id: "sales.user-inputs.firstname"
            })}
            value={firstName}
            onChange={event => setFirstName(event.currentTarget.value)}
          />
          <ValidationErrors errors={validations.firstName} />
          <input
            className="lt-input-text"
            placeholder={intl.formatMessage({
              id: "sales.user-inputs.lastname"
            })}
            value={lastName}
            onChange={event => setLastName(event.currentTarget.value)}
          />
          <ValidationErrors errors={validations.lastName} />
          <input
            className="lt-input-text"
            placeholder={intl.formatMessage({ id: "sales.user-inputs.email" })}
            value={email}
            onChange={event => setEmail(event.currentTarget.value.trim())}
          />
          <ValidationErrors errors={validations.email} />
          <input
            className="lt-input-text"
            placeholder={intl.formatMessage({
              id: "sales.user-inputs.phone-number"
            })}
            value={phoneNumber}
            onChange={event => setPhoneNumber(event.currentTarget.value)}
          />
          <ValidationErrors errors={validations.phoneNumber} />
        </div>
      </>
    )
  }
)

const ValidationErrors = ({ errors }: { errors: string[] }) => {
  if (errors.length <= 0) {
    return null
  }
  return (
    <div className="lt-input-text__errors">
      {errors.map(e => (
        <span> {e} </span>
      ))}
    </div>
  )
}
