import React, { useEffect, useState } from "react"
import "./Pagination.sass"
import { strictParseInt } from "util/utils"

type Props = {
  pageNumber: number // zero-based value
  setPageNumber: (page: number) => void
  totalPages: number
}

export const Pagination: React.FC<Props> = ({
  pageNumber,
  setPageNumber,
  totalPages
}) => {
  // Use "+ 1" because "pageNumber" is a zero-based value
  const [userEditablePageNumber, setUserEditablePageNumber] = useState(
    (pageNumber + 1).toString()
  )

  useEffect(() => {
    // Use "+ 1" because "pageNumber" is a zero-based value
    setUserEditablePageNumber((pageNumber + 1).toString())
  }, [pageNumber])

  const setPreviousPage = () => {
    const newPageNumber = pageNumber - 1
    if (newPageNumber >= 0) {
      setPageNumber(newPageNumber)
    }
  }

  const setNextPage = () => {
    const newPageNumber = pageNumber + 1
    if (newPageNumber < totalPages) {
      setPageNumber(newPageNumber)
    }
  }

  const onPageNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
    const parsed = strictParseInt(event.currentTarget.value)
    if (!isNaN(parsed) || event.currentTarget.value === "") {
      setUserEditablePageNumber(event.currentTarget.value)

      if (parsed >= 1 && parsed <= totalPages) {
        // Use "- 1" because this needs to be a zero-based value
        setPageNumber(parsed - 1)
      }
    }
  }

  return (
    <>
      <div className="pagination__page-container">
        <button
          className={"pagination__page direction direction--prev"}
          onClick={setPreviousPage}
        ></button>
        <span className="pagination__page current-page">Sivu:</span>
        <input
          className="pagination__page page-input"
          type="text"
          value={userEditablePageNumber}
          onChange={onPageNumberChange}
        />
        <span className="pagination__page total-pages"> / {totalPages}</span>
        <button
          className={"pagination__page direction direction--next"}
          onClick={setNextPage}
        ></button>
      </div>
    </>
  )
}
