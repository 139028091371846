import React, { useCallback, useMemo, useState } from "react"
import classNames from "classnames"
import Checkbox from "lib/checkbox/checkbox"
import Button from "lib/button/button"
import { makeSwitchExhaustive } from "util/utils"
import { FormattedMessage, useIntl } from "react-intl"
import "./OrderForm.sass"
import {
  BackgroundColor,
  ContainerWidth,
  ContentContainer
} from "common/content-container/content-container"
import { getFirstAvailableDay, getFirstAvailableWeek } from "../order-utils"
import { DayPickerInputWrapper } from "lib/day-picker/DayPicker"
import {
  BaseFormTypes,
  DeliveryType,
  Order,
  OrderFormState,
  OrderSpecialContainer,
  OrderWasteTypes,
  OrderWasteTypesJassika,
  OrderWasteTypesVaihtolavat
} from "./models"
import { Worksite } from "store/worksite/models"
import SelectionBox from "lib/selection-box/selection-box"
import { useOrderBuilder } from "./hooks"
import { Warning } from "../../../common/warning/warning"
import { WeekPickerInputWrapper } from "../../../lib/week-picker/WeekPicker"

export enum OrderFormWasteTypes {
  Vaihtolava = "vaihtolava",
  Jassika = "jassika"
}

type OrderFormProps = {
  wasteType: OrderFormWasteTypes
  formType: BaseFormTypes
  phone?: string
  propFormState: OrderFormState | null
  onSubmit: (order: Order, formState: OrderFormState) => void
  onCancel: () => void
  worksite: Worksite
}

const initialFormData = (phone: string): OrderFormState => {
  return {
    dateType: DeliveryType.ASAP,
    date: getFirstAvailableDay(),
    selectedWasteType: null,
    lowSide: false,
    liftingLugs: false,
    specialContainer: null,
    callBefore: false,
    moveDuringEmpty: false,
    changeWasteTypeTo: null,
    containerNumber: "",
    contactNumber: phone,
    extraInfo: ""
  }
}

const formTitles: {
  [key in OrderFormWasteTypes]: { [key in Partial<BaseFormTypes>]: string }
} = {
  [OrderFormWasteTypes.Jassika]: {
    new: "order.jassikka.new",
    emptying: "order.jassikka.empty",
    move: "order.jassikka.move",
    remove: "order.jassikka.remove",
    other: "",
    unselected: ""
  },
  [OrderFormWasteTypes.Vaihtolava]: {
    new: "order.vaihtolava.new",
    emptying: "order.vaihtolava.empty",
    move: "order.vaihtolava.move",
    remove: "order.vaihtolava.remove",
    other: "",
    unselected: ""
  }
}

// Change wishes to array, we can have both
const OrderForm = ({
  wasteType,
  formType,
  onSubmit,
  propFormState,
  onCancel,
  phone = "",
  worksite
}: OrderFormProps) => {
  const [formState, setFormState] = React.useState<OrderFormState>(
    propFormState ? propFormState : initialFormData(phone)
  )
  const intl = useIntl()
  const buildOrder = useOrderBuilder()
  const isJassikka = useMemo(
    () => wasteType === OrderFormWasteTypes.Jassika,
    [wasteType]
  )
  const [wasteTypeIsError, setWasteTypeIsError] = useState(false)
  const [contactNumberIsError, setContactNumberIsError] = useState(false)

  const ChangeWasteTypeToOption = () => {
    return (
      <>
        <Checkbox
          checked={!!formState.changeWasteTypeTo}
          onChange={() => {
            const newWasteType = formState.changeWasteTypeTo
              ? null
              : OrderWasteTypesVaihtolavat.ConstructionWaste
            setFormState({ ...formState, changeWasteTypeTo: newWasteType })
          }}
          name="change_empty"
        >
          <FormattedMessage id="order.form.vaihtolava.change-during.empty" />
        </Checkbox>
        {!!formState.changeWasteTypeTo && (
          <SelectionBox
            itemList={Object.values(
              isJassikka ? OrderWasteTypesJassika : OrderWasteTypesVaihtolavat
            )}
            selectedItem={formState.changeWasteTypeTo}
            onClick={updateChangeWasteTypeTo}
          />
        )}
      </>
    )
  }

  const getCallBeforeLabel = (formType: BaseFormTypes) => {
    switch (formType) {
      case "new":
        return <FormattedMessage id="order.form.call-before" />
      case "emptying":
        return <FormattedMessage id="order.form.call-before.empty" />
      case "move":
        return <FormattedMessage id="order.form.call-before.move" />
      case "remove":
        return <FormattedMessage id="order.form.call-before.remove" />
      case "other":
      case "unselected":
        return ""
      default:
        makeSwitchExhaustive(undefined)
    }
  }

  const CheckboxSection = () => {
    if (formType === "emptying") {
      return (
        <>
          <Checkbox
            checked={formState.callBefore}
            onChange={() =>
              setFormState({ ...formState, callBefore: !formState.callBefore })
            }
            name="call_before"
          >
            {getCallBeforeLabel(formType)}
          </Checkbox>
          <Checkbox
            checked={formState.moveDuringEmpty}
            onChange={() =>
              setFormState({
                ...formState,
                moveDuringEmpty: !formState.moveDuringEmpty
              })
            }
            name="move_empty"
          >
            <FormattedMessage id="order.form.vaihtolava.move-during-empty" />
          </Checkbox>
          <ChangeWasteTypeToOption />
        </>
      )
    }
    return (
      <>
        <Checkbox
          checked={formState.callBefore}
          onChange={() =>
            setFormState({ ...formState, callBefore: !formState.callBefore })
          }
          name="call_before"
        >
          {getCallBeforeLabel(formType)}
        </Checkbox>
      </>
    )
  }

  const updateChangeWasteTypeTo = (wasteType: OrderWasteTypes) => {
    setFormState({ ...formState, changeWasteTypeTo: wasteType })
  }

  const updateSelectedWasteType = (wasteType: OrderWasteTypes) => {
    setWasteTypeIsError(false)
    setFormState({ ...formState, selectedWasteType: wasteType })
  }

  const updateSpecialContainer = (container: OrderSpecialContainer) => {
    setFormState({ ...formState, specialContainer: container })
  }

  const formTitle = useMemo(
    () => <FormattedMessage id={formTitles[wasteType][formType]} />,
    [wasteType, formType]
  )

  const onFormSubmit = useCallback(() => {
    const order = buildOrder({
      wasteType,
      formType,
      formState,
      worksite
    })
    order && onSubmit(order, formState)
  }, [buildOrder, onSubmit, wasteType, formType, formState, worksite])

  const validateForm = useCallback(() => {
    let isError = false
    if (!formState.selectedWasteType) {
      isError = true
      setWasteTypeIsError(true)
    }
    if (formState.contactNumber === "") {
      isError = true
      setContactNumberIsError(true)
    }
    !isError && onFormSubmit()
  }, [formState, onFormSubmit])

  const warnings = useMemo(() => {
    const errors = []
    wasteTypeIsError &&
      errors.push(intl.formatMessage({ id: "order.form.waste-type.error" }))
    contactNumberIsError &&
      errors.push(
        intl.formatMessage({ id: "order.form.contact-number.warning" })
      )
    return errors
  }, [wasteTypeIsError, contactNumberIsError, intl])

  return (
    <ContentContainer
      containerWidth={ContainerWidth.RESTRICT_STRETCH_MOBILE}
      backgroundColor={BackgroundColor.WHITE}
    >
      <div className="modal">
        <div className="modal__header">
          <h1 className="lt-h4">{formTitle}</h1>
          <button
            className="close-button"
            onClick={onCancel}
            aria-label={intl.formatMessage({ id: "common.close" })}
          />
        </div>
        <div className="modal__body">
          <fieldset className="fieldset">
            <div className="label">
              <FormattedMessage id="order.form.date" /> *
            </div>
            <div className="selection-box">
              <div
                className={classNames("selection-box__item", {
                  "selection-box__item--selected":
                    formState.dateType === DeliveryType.ASAP
                })}
                onClick={() =>
                  setFormState({ ...formState, dateType: DeliveryType.ASAP })
                }
              >
                <FormattedMessage id="order.form.asap" />
              </div>
              <div
                className={classNames("selection-box__item", {
                  "selection-box__item--selected":
                    formState.dateType !== DeliveryType.ASAP
                })}
                onClick={() => {
                  if (formState.dateType === DeliveryType.ASAP)
                    setFormState({
                      ...formState,
                      dateType: isJassikka
                        ? DeliveryType.WEEK
                        : DeliveryType.DAY,
                      date: isJassikka
                        ? getFirstAvailableWeek()
                        : getFirstAvailableDay()
                    })
                }}
              >
                <FormattedMessage id="order.form.choose-date" />
              </div>
              {isJassikka && formState.dateType === DeliveryType.ASAP && (
                <div className="margin-top-10">
                  <p className="info-text-not-bold">
                    <FormattedMessage id="order.form.dry-toilet.info.date" />
                  </p>
                </div>
              )}
            </div>
          </fieldset>

          {[DeliveryType.DAY, DeliveryType.WEEK].indexOf(formState.dateType) >=
            0 && (
            <fieldset className="fieldset">
              <div className="label">
                <FormattedMessage id="order.form.selected-date" /> *
              </div>
              {isJassikka ? (
                <WeekPickerInputWrapper
                  date={formState.date}
                  onDayChange={date =>
                    setFormState({
                      ...formState,
                      date
                    })
                  }
                />
              ) : (
                <DayPickerInputWrapper
                  date={formState.date}
                  onDayChange={day => setFormState({ ...formState, date: day })}
                />
              )}
            </fieldset>
          )}

          <fieldset className="fieldset">
            <div
              className={`label${wasteTypeIsError ? " no-bottom-margin" : ""}`}
            >
              <FormattedMessage id="order.form.waste-type" /> *
            </div>
            {wasteTypeIsError && (
              <div className="form-error">
                <FormattedMessage id="order.form.waste-type.error" />
              </div>
            )}
            <div className="selection-box">
              <SelectionBox
                itemList={Object.values(
                  isJassikka
                    ? OrderWasteTypesJassika
                    : OrderWasteTypesVaihtolavat
                )}
                selectedItem={formState.selectedWasteType}
                onClick={updateSelectedWasteType}
              />
            </div>
          </fieldset>

          <fieldset className="fieldset">
            {formType !== "new" && !isJassikka && (
              <Checkbox
                checked={!!formState.specialContainer}
                onChange={() => {
                  const newSpecial = formState.specialContainer
                    ? null
                    : OrderSpecialContainer.Kansilava
                  setFormState({ ...formState, specialContainer: newSpecial })
                }}
                name="special_container"
              >
                <FormattedMessage id="order.form.vaihtolava.special-container" />
              </Checkbox>
            )}

            {!!formState.specialContainer && (
              <div className="lt-margin-bottom-m">
                <SelectionBox
                  itemList={Object.values(OrderSpecialContainer)}
                  selectedItem={formState.specialContainer}
                  onClick={updateSpecialContainer}
                />
              </div>
            )}
            <CheckboxSection />
          </fieldset>

          {formType !== "new" && (
            <fieldset className="fieldset">
              <label className="label" htmlFor="container-number">
                <FormattedMessage
                  id={`order.form.${wasteType}.container-number`}
                />
              </label>
              <input
                name="container-number"
                className="lt-input-text input-with-error"
                value={formState.containerNumber}
                onChange={ev =>
                  setFormState({
                    ...formState,
                    containerNumber: ev.target.value
                  })
                }
              />
            </fieldset>
          )}

          <>
            <fieldset className="fieldset">
              <label htmlFor="phone" className="label">
                <FormattedMessage id="order.form.contact-number" /> *
              </label>
              <div
                className={`${
                  contactNumberIsError ? " input-with-error-wrapper" : ""
                }`}
              >
                <input
                  type="tel"
                  className={`lt-input-text${
                    contactNumberIsError ? " input-with-error" : ""
                  }`}
                  name="phone"
                  value={formState.contactNumber}
                  onChange={event => {
                    setContactNumberIsError(false)
                    setFormState({
                      ...formState,
                      contactNumber: event.target.value
                    })
                  }}
                />
                {contactNumberIsError && (
                  <div className="form-error">
                    <FormattedMessage id="order.form.contact-number.error" />
                  </div>
                )}
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <label htmlFor="additional-info" className="label">
                <FormattedMessage id="order.form.additional-info" />
              </label>
              <textarea
                name="additional-info"
                className="lt-textarea"
                rows={5}
                value={formState.extraInfo}
                placeholder={intl.formatMessage({
                  id: `order.form.${wasteType}.placeholder`
                })}
                onChange={event =>
                  setFormState({ ...formState, extraInfo: event.target.value })
                }
              />
            </fieldset>
          </>

          <Warning
            title={intl.formatMessage({ id: "order.form.warning.title" })}
            messages={warnings}
          />

          <Button expand onClick={validateForm}>
            <FormattedMessage id="order.form.to-confirmation" />
          </Button>
        </div>
      </div>
    </ContentContainer>
  )
}

export default OrderForm
