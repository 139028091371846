import LoadingSpinner from "common/loading-spinner/loading-spinner"
import { useCallback, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { ReactComponent as DownloadButton } from "../../../images/icon-download-button.svg"
import FormatAddress from "../common/FormatAddress"
import { noWasteSortingPlanDocument } from "../document-utils"
import {
  WasteSortingPlanDocumentResponse,
  WasteSortingPlanDocumentStatus
} from "../documents-api"

interface SortingPlanMobileProps {
  wasteSortingPlanDocuments: WasteSortingPlanDocumentResponse[]
  loaded: boolean
  loadFailed: boolean
}

const SortingPlanMobile = ({
  wasteSortingPlanDocuments,
  loaded,
  loadFailed
}: SortingPlanMobileProps) => {
  const getLoadFailedMobile = useMemo(
    () => (
      <div className="document document--mobile">
        <div className="document__value document__value--mobile">
          <FormattedMessage id="common.error" />
        </div>
      </div>
    ),
    []
  )

  const getNoDocumentMobile = useCallback(
    (document: WasteSortingPlanDocumentResponse) => {
      return (
        <div className="document__value document__value--mobile">
          {document.documentStatus ===
            WasteSortingPlanDocumentStatus.notFound && (
            <FormattedMessage id="documents.sorting.no-document" />
          )}
          {document.documentStatus === WasteSortingPlanDocumentStatus.error && (
            <FormattedMessage id="common.error" />
          )}
        </div>
      )
    },
    []
  )

  return (
    <div>
      {loadFailed && getLoadFailedMobile}
      {!loadFailed && !loaded && (
        <LoadingSpinner className="loading-spinner--line" />
      )}
      {loaded &&
        wasteSortingPlanDocuments.map((document, index) => (
          <div className="document document--mobile" key={index}>
            {document.documentStatus === WasteSortingPlanDocumentStatus.ok && (
              <>
                <h3 className="document__header document__header--mobile">
                  <FormattedMessage id="documents.sorting.document" />
                </h3>
                <h3 className="document__value document__value--mobile">
                  <FormattedMessage id="documents.sorting.plan-file-name" />
                </h3>
                <h3 className="document__header document__header--mobile">
                  <FormattedMessage id="documents.common.worknumber" />
                </h3>
                <h3 className="document__value document__value--mobile">
                  {document.worksiteDescription}
                </h3>
                <h3 className="document__header document__header--mobile">
                  <FormattedMessage id="documents.common.site" />
                </h3>
                <h3 className="document__value document__value--mobile">
                  <FormatAddress document={document} />
                </h3>
                <a
                  href={document.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DownloadButton />
                </a>
              </>
            )}
            {noWasteSortingPlanDocument(document) &&
              getNoDocumentMobile(document)}
          </div>
        ))}
    </div>
  )
}

export default SortingPlanMobile
