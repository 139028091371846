import Button from "lib/button/button"
import React, { useCallback, useState } from "react"
import { WrappedComponentProps, injectIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { LoginResponse } from "store/auth/auth-reducer"
import { Worksite } from "store/worksite/models"
import { UserFormData } from "../sales-view-api"
import WorksiteSearch from "../worksites/WorksiteSearch"
import * as Validation from "./validations"
import Checkbox from "lib/checkbox/checkbox"

import "./UserForm.sass"
import { UserInputs } from "./UserInputs"
import StatusBar, {
  StatusBarMode,
  StatusBarState
} from "lib/status-banner/StatusBar"

type Props = {
  loggedInUser: LoginResponse
}

const emptyUser: UserFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  terms: undefined,
  role: "",
  hasAccessToReports: false
}

export const InviteUserPage: React.FC<Props> = injectIntl(
  ({ loggedInUser, intl }: Props & WrappedComponentProps) => {
    const [disabled, setDisabled] = useState<boolean>(false)
    const [user, setUser] = useState<UserFormData>(emptyUser)
    const [worksites, setWorksites] = useState<Worksite[]>([])
    const [sendLoginInstructionsByEmail, setSendLoginInstructionsByEmail] =
      useState<boolean>(false)
    const [statusBar, setStatusBar] = useState<StatusBarState>({
      status: StatusBarMode.NONE
    })
    const [validations, setValidations] =
      useState<Validation.UserFormDataValidations>(Validation.defaultValidation)
    const history = useHistory()

    const handleSetUserFormData = useCallback((user: UserFormData) => {
      setUser(user)
      checkExistingUser(user.email)
    }, [])

    const checkExistingUser = async (email: string) => {
      setStatusBar({ status: StatusBarMode.NONE })
      setDisabled(false)

      if (Validation.emailValid(email)) {
        const resp = await fetch(
          `${process.env.REACT_APP_URL}/api/users/email/${email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        if (resp.ok) {
          setStatusBar({
            status: StatusBarMode.ERROR,
            messageId: "sales.user-creation.existing-user"
          })
          setDisabled(true)
        } else if (resp.status !== 404) {
          setStatusBar({
            status: StatusBarMode.ERROR,
            messageId: "sales.user-creation.existing-user.search-failed"
          })
        }
      }
    }

    const submit = async () => {
      setValidations(Validation.defaultValidation)
      if (user) {
        const validation = Validation.validate(user, intl)

        if (validation.isValid) {
          const resp = await fetch(
            `${process.env.REACT_APP_URL}/api/users/iamcreate`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phoneNumber,
                role: "Customer",
                hasAccessToReports: false,
                raksanappiWorksites: worksites.map(ws => ws.targetIdentifier),
                sendNewUserWelcomeEmail: sendLoginInstructionsByEmail,
                actorId: loggedInUser.userId
              })
            }
          )
          if (resp.ok) {
            setStatusBar({
              status: StatusBarMode.INFO,
              messageId: "sales.user-creation.success"
            })
            setTimeout(() => history.push("/sales"), 1000)
          } else {
            setStatusBar({
              status: StatusBarMode.ERROR,
              messageId: "sales.user-creation.failure"
            })
          }
        } else {
          setValidations(validation)
        }
      }
    }

    return (
      <div className="content-container background-color--gray">
        <StatusBar
          barState={statusBar}
          onClick={() => setStatusBar({ status: StatusBarMode.NONE })}
        />
        <div
          className="
          content-container__inner
          content-container__inner--width-restrict
          content-container__inner--mobile-stretch"
        >
          <div className="user-form__container">
            <h1 className="lt-h4">
              {intl.formatMessage({ id: "sales.invite-user-page.title" })}
            </h1>
            <UserInputs
              user={user}
              validations={validations}
              update={handleSetUserFormData}
            />
            <WorksiteSearch
              worksites={worksites}
              onUpdate={worksites => setWorksites(worksites)}
            />
            <Checkbox
              checked={sendLoginInstructionsByEmail}
              onChange={() => {
                setSendLoginInstructionsByEmail(!sendLoginInstructionsByEmail)
              }}
              name="send-login-instructions-by-email"
            >
              {intl.formatMessage({
                id: "sales.send-login-instructions-by-email"
              })}
            </Checkbox>
            <div className="user-form__cancel-submit-buttons-container">
              <Button
                disabled={false}
                expand
                additionalClass="cancel-button spaced-button"
                onClick={() => {
                  history.push("/sales")
                }}
              >
                {intl.formatMessage({ id: "common.cancel" })}
              </Button>
              <Button
                disabled={disabled}
                onClick={submit}
                expand
                additionalClass="spaced-button"
              >
                {intl.formatMessage({
                  id: "sales.invite-user-page.create-user"
                })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
)
