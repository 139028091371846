import { AppState } from "../state"
import { Worksite } from "store/worksite/models"

export interface LoginCredentials {
  username: string
  password: string
}

export type Role = string

export interface LoginResponse {
  name: string
  userId: string
  phone: string
  roles: Role[]
  customerIds: string[]
  worksites: Worksite[]
  termsAccepted: boolean
  isLT: boolean
}

enum UserInitialization {
  NOT_INITIALIZED,
  INITIALIZING,
  INITIALIZED
}

enum UserAuthentication {
  NOT_AUTHENTICATED,
  AUTHENTICATING,
  AUTHENTICATED,
  AUTHENTICATION_FAILED,
  SSO_FAILED,
  SSO_FAILED_MISSING_ROLE,
  USER_BLOCKED,
  UNKNOWN_LOGIN_ERROR
}

/**
 * ACTIONS
 */
export enum ActionTypes {
  LOGIN = "LOGIN",
  GET_USER = "GET_USER",
  USER_RECEIVED = "USER_RECEIVED",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILED = "LOGIN_FAILED",
  NO_USER_RECEIVED = "NO_USER_RECEIVED",
  LOGOUT = "LOGOUT",
  LOGGED_OUT = "LOGGED_OUT",
  GET_SSO_USER = "GET_SSO_USER",
  SSO_LOGIN_FAILED = "SSO_LOGIN_FAILED",
  SSO_LOGIN_FAILED_MISSING_ROLE = "SSO_LOGIN_FAILED_MISSING_ROLE",
  ACCEPT_TERMS = "ACCEPT_TERMS",
  TERMS_ACCEPTED_SUCCESS = "TERMS_ACCEPTED_SUCCESS"
}

export interface LoginAction {
  type: ActionTypes.LOGIN
  payload: LoginCredentials
}

export interface GetUserAction {
  type: ActionTypes.GET_USER
}

export interface UserReceivedAction {
  type: ActionTypes.USER_RECEIVED
  payload: LoginResponse
}

export interface LoginSuccessAction {
  type: ActionTypes.LOGIN_SUCCESS
  payload: LoginResponse
}

export interface LoginFailedAction {
  type: ActionTypes.LOGIN_FAILED
  isBlocked: boolean
  unknownLoginError: boolean
}

export interface NoUserReceivedAction {
  type: ActionTypes.NO_USER_RECEIVED
}

export interface SsoLoginFailedAction {
  type: ActionTypes.SSO_LOGIN_FAILED
}

export interface SsoLoginFailedMissingRoleAction {
  type: ActionTypes.SSO_LOGIN_FAILED_MISSING_ROLE
}

export interface LogoutAction {
  type: ActionTypes.LOGOUT
  clearCookie: boolean
}

export interface LoggedOutAction {
  type: ActionTypes.LOGGED_OUT
}

export interface GetSsoUserAction {
  type: ActionTypes.GET_SSO_USER
}

export interface AcceptTermsAction {
  type: ActionTypes.ACCEPT_TERMS
}
export interface TermsAcceptedSuccessAction {
  type: ActionTypes.TERMS_ACCEPTED_SUCCESS
  payload: LoginResponse
}

export type AuthAction =
  | LoginAction
  | LoginSuccessAction
  | LoginFailedAction
  | LogoutAction
  | NoUserReceivedAction
  | LoggedOutAction
  | GetUserAction
  | UserReceivedAction
  | GetSsoUserAction
  | SsoLoginFailedAction
  | SsoLoginFailedMissingRoleAction
  | AcceptTermsAction
  | TermsAcceptedSuccessAction

/**
 * ACTION CREATORS
 */
export const login = (credentials: LoginCredentials): LoginAction => ({
  type: ActionTypes.LOGIN,
  payload: credentials
})

export const loginSuccessful = (user: LoginResponse): LoginSuccessAction => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: user
})

export const loginFailed = (
  isBlocked: boolean,
  unknownLoginError: boolean
): LoginFailedAction => ({
  type: ActionTypes.LOGIN_FAILED,
  isBlocked,
  unknownLoginError
})

export const noUserReceived = (): NoUserReceivedAction => ({
  type: ActionTypes.NO_USER_RECEIVED
})

export const ssoLoginFailed = (): SsoLoginFailedAction => ({
  type: ActionTypes.SSO_LOGIN_FAILED
})

export const ssoLoginFailedMissingRole =
  (): SsoLoginFailedMissingRoleAction => ({
    type: ActionTypes.SSO_LOGIN_FAILED_MISSING_ROLE
  })

export const logout = (clearCookie: boolean): LogoutAction => ({
  type: ActionTypes.LOGOUT,
  clearCookie: clearCookie
})

export const loggedOut = (): LoggedOutAction => ({
  type: ActionTypes.LOGGED_OUT
})

export const getUserAction = (): GetUserAction => ({
  type: ActionTypes.GET_USER
})

export const userReceivedAction = (
  user: LoginResponse
): UserReceivedAction => ({
  type: ActionTypes.USER_RECEIVED,
  payload: user
})

export const getSsoUserAction = (): GetSsoUserAction => ({
  type: ActionTypes.GET_SSO_USER
})

export const acceptTerms = (): AcceptTermsAction => ({
  type: ActionTypes.ACCEPT_TERMS
})

export const termsAcceptedSuccess = (
  user: LoginResponse
): TermsAcceptedSuccessAction => ({
  type: ActionTypes.TERMS_ACCEPTED_SUCCESS,
  payload: user
})

/**
 * REDUCER
 */

export type AuthState = {
  initialized: UserInitialization
  authentication: UserAuthentication
  user: LoginResponse | null
  loginInProgress: boolean
}

export const initialState: AuthState = {
  initialized: UserInitialization.NOT_INITIALIZED,
  authentication: UserAuthentication.NOT_AUTHENTICATED,
  user: null,
  loginInProgress: false
}

export function authReducer(
  state: AuthState = initialState,
  action: AuthAction
): AuthState {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        authentication: UserAuthentication.AUTHENTICATING,
        user: null,
        loginInProgress: true
      }
    case ActionTypes.GET_USER:
      return {
        ...state,
        initialized: UserInitialization.INITIALIZING
      }
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        authentication: UserAuthentication.AUTHENTICATED,
        user: action.payload,
        loginInProgress: false
      }
    case ActionTypes.USER_RECEIVED:
      return {
        initialized: UserInitialization.INITIALIZED,
        authentication: UserAuthentication.AUTHENTICATED,
        user: action.payload,
        loginInProgress: false
      }
    case ActionTypes.NO_USER_RECEIVED:
      return {
        ...state,
        initialized: UserInitialization.INITIALIZED
      }
    case ActionTypes.LOGIN_FAILED:
      return {
        ...state,
        authentication: action.isBlocked
          ? UserAuthentication.USER_BLOCKED
          : action.unknownLoginError
          ? UserAuthentication.UNKNOWN_LOGIN_ERROR
          : UserAuthentication.AUTHENTICATION_FAILED,
        user: null,
        loginInProgress: false
      }
    case ActionTypes.SSO_LOGIN_FAILED:
      return {
        ...state,
        authentication: UserAuthentication.SSO_FAILED,
        user: null,
        loginInProgress: false
      }
    case ActionTypes.SSO_LOGIN_FAILED_MISSING_ROLE:
      return {
        ...state,
        authentication: UserAuthentication.SSO_FAILED_MISSING_ROLE,
        user: null,
        loginInProgress: false
      }
    case ActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        loginInProgress: false
      }
    case ActionTypes.LOGGED_OUT:
      return {
        ...state,
        initialized: UserInitialization.NOT_INITIALIZED,
        authentication: UserAuthentication.NOT_AUTHENTICATED
      }
    case ActionTypes.TERMS_ACCEPTED_SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    default:
      return state
  }
}

/**
 * SELECTORS
 */
export const isInitializationStarted = ({ auth }: AppState) =>
  auth.initialized !== UserInitialization.NOT_INITIALIZED
export const isInitialized = ({ auth }: AppState) =>
  auth.initialized === UserInitialization.INITIALIZED
export const hasSsoLoginFailed = ({ auth }: AppState) =>
  auth.authentication === UserAuthentication.SSO_FAILED
export const isSsoUserMissingRole = ({ auth }: AppState) =>
  auth.authentication === UserAuthentication.SSO_FAILED_MISSING_ROLE
export const isAuthenticated = ({ auth }: AppState) => auth.user !== null
export const isTermsAccepted = ({ auth }: AppState): boolean =>
  auth.user?.termsAccepted === true
export const isADUser = ({ auth }: AppState): boolean =>
  auth.user?.isLT === true
export const isLoginInProgress = ({ auth }: AppState) => auth.loginInProgress
export const areCredentialsInvalid = ({ auth }: AppState) =>
  auth.authentication === UserAuthentication.AUTHENTICATION_FAILED
export const isUserBlocked = ({ auth }: AppState) =>
  auth.authentication === UserAuthentication.USER_BLOCKED
export const unknownLoginError = ({ auth }: AppState) =>
  auth.authentication === UserAuthentication.UNKNOWN_LOGIN_ERROR
export const isLoginFailed = ({ auth }: AppState) =>
  [
    UserAuthentication.AUTHENTICATION_FAILED,
    UserAuthentication.SSO_FAILED,
    UserAuthentication.SSO_FAILED_MISSING_ROLE,
    UserAuthentication.USER_BLOCKED,
    UserAuthentication.UNKNOWN_LOGIN_ERROR
  ].includes(auth.authentication)
export const name = ({ auth }: AppState) =>
  auth.user ? auth.user.name.split(" ")[0] : ""
export const phone = ({ auth }: AppState) => (auth.user ? auth.user.phone : "")
export const user = ({ auth }: AppState) => auth.user
export const authenticatedUser = ({ auth }: AppState) => {
  if (auth.user) {
    return auth.user
  }
  throw new Error("User missing in a logged in path")
}
export const worksites = ({ auth }: AppState) => {
  if (auth.user) {
    return auth.user.worksites
  }
  throw new Error("User missing in a logged in path")
}
