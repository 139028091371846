import { VaihtolavaOrder } from "../vaihtolava/models"
import { CompactorOrder } from "../compactor/models"
import { FrontloaderOrder } from "../frontloader/models"
import { DryToiletOrder } from "../dry-toilet/models"
import { OtherOrder } from "../other/models"

export interface BaseFormState {
  dateType: DeliveryType
  date: Date
  callBefore: boolean
  moveDuringEmpty: boolean
  containerNumber: string
  contactNumber: string
  extraInfo: string
}

export enum BaseFormTypes {
  UNSELECTED = "unselected",
  NEW = "new",
  EMPTYING = "emptying",
  MOVE = "move",
  REMOVE = "remove",
  OTHER = "other"
}

export enum ContainerType {
  COMPACTOR = "puristin",
  ROLL_OFF = "vaihtolava",
  FRONTLOADER = "etukuormaussäiliö",
  DRY_TOILET = "bajamaja",
  OTHER = "muu"
}

export enum EmptyInterval {
  ONE_WEEK = "1 vko",
  TWO_WEEK = "2 vko",
  FOUR_WEEK = "4 vko"
}

export enum EmptyOptions {
  ONCE = "Kertatyhjennys",
  REPEATING = "Säännöllinen tyhjennys"
}

export enum DeliveryType {
  ASAP = "ASAP",
  DAY = "DAY",
  RANGE = "RANGE",
  WEEK = "WEEK",
  NOT_APPLICABLE = "not_applicable"
}

export type DeliveryAsap = {
  kind: DeliveryType.ASAP
}

export type DeliveryOnDate = {
  kind: DeliveryType.DAY
  start: string
}

export type DeliveryRange = {
  kind: DeliveryType.RANGE
  start: string
  end: string
}

export type DeliveryNotApplicable = {
  kind: DeliveryType.NOT_APPLICABLE
}

export type DesiredDeliveryTime =
  | DeliveryAsap
  | DeliveryOnDate
  | DeliveryRange
  | DeliveryNotApplicable

export type Order =
  | CompactorOrder
  | VaihtolavaOrder
  | FrontloaderOrder
  | DryToiletOrder
  | OtherOrder

export enum OrderSpecialContainer {
  Kansilava = "Kansilava",
  Lokerolava = "Lokerolava",
  Kippilava = "Kippilava"
}

export enum OrderWasteTypesVaihtolavat {
  Metal = "Metalli",
  Energy = "Energia",
  ConcreteRock = "Betoni & Kivi",
  Plaster = "Kipsi",
  MixedWood = "Sekalainen puu",
  CleanWood = "Puhdas puu",
  RecycleWood = "Kierrätyspuu",
  CombustibleWaste = "Polttokelpoinen jäte",
  ConstructionWaste = "Rakennusjäte"
}

export enum OrderWasteTypesJassika {
  Metal = "Metalli",
  Energy = "Energia",
  ConcreteRock = "Betoni & Kivi",
  Plaster = "Kipsi",
  MixedWood = "Sekalainen puu",
  CleanWood = "Puhdas puu",
  RecycleWood = "Kierrätyspuu",
  BitumWaste = "Bitumikermi",
  TileWaste = "Laattajäte",
  ConstructionWaste = "Rakennusjäte"
}

export type OrderWasteTypes =
  | OrderWasteTypesVaihtolavat
  | OrderWasteTypesJassika

export interface OrderFormState extends BaseFormState {
  lowSide: boolean
  liftingLugs: boolean
  specialContainer: OrderSpecialContainer | null
  selectedWasteType: OrderWasteTypes | null
  changeWasteTypeTo: OrderWasteTypes | null
}
