import React, { useMemo } from "react"
import { stringHasValue } from "util/utils"
import {
  TransferDocument,
  WasteSortingPlanDocumentResponse
} from "../documents-api"

interface FormatAddressProps {
  document: WasteSortingPlanDocumentResponse | TransferDocument
}

const FormatAddress = ({ document }: FormatAddressProps) => {
  const formattedAddress = useMemo(() => {
    const zipAndCity = [document.postCode, document.city]
      .filter(stringHasValue)
      .join(" ")

    return [document.streetName, zipAndCity].filter(stringHasValue).join(", ")
  }, [document.postCode, document.city, document.streetName])

  return (
    <>
      {document.company}
      <br />
      {formattedAddress}
    </>
  )
}

export default React.memo(FormatAddress)
