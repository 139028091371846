import React from "react"
import warningIcon from "common/images/warning-icon.svg"
import "./warning.sass"

export type WarningProps = {
  title: string
  messages: string[]
}

export const Warning = ({ title, messages }: WarningProps) => {
  if (messages.length === 0) return <></>

  return (
    <div className="warning">
      <div>
        <img src={warningIcon} alt="" />
        <span>{title}</span>
      </div>
      <ul>
        {messages.map((m, key) => (
          <li key={key}>{m}</li>
        ))}
      </ul>
    </div>
  )
}
