import { FormattedMessage } from "react-intl"
import { ReactComponent as DownloadButton } from "../../../images/icon-download-button.svg"
import { SortingGuideDocument } from "../documents-api"
import "../documents.sass"

interface SortingGuideMobileProps {
  sortingGuideDocuments: SortingGuideDocument[]
}

const SortingGuideMobile = ({
  sortingGuideDocuments
}: SortingGuideMobileProps) => {
  return (
    <>
      {sortingGuideDocuments.map((document, index) => (
        <div className="document document--mobile" key={index}>
          <h3 className="document__header document__header--mobile">
            <FormattedMessage id="documents.sorting.document" />
          </h3>
          <h3 className="document__value document__value--mobile">
            {document.name}
          </h3>
          <h3 className="document__header document__header--mobile">
            <FormattedMessage id="documents.common.language" />
          </h3>
          <h3 className="document__value document__value--mobile">
            {document.language}
          </h3>
          <a href={document.url} target="_blank" rel="noopener noreferrer">
            <DownloadButton />
          </a>
        </div>
      ))}
    </>
  )
}

export default SortingGuideMobile
