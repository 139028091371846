import React, { useEffect } from "react"
import { connect } from "react-redux"
import { AppState } from "store/state"
import {
  isSubmittedSuccessfully,
  submitOrder,
  submitOrderReset
} from "store/order/order-reducer"
import { BaseFormTypes, Order, OrderFormState } from "../common/models"
import OrderSelection, { OrderSelectionItem } from "../common/OrderSelection"
import OrderSuccess from "../common/OrderSuccess"
import OrderConfirm from "../common/OrderConfirm"
import { makeSwitchExhaustive } from "util/utils"
import { Worksite } from "store/worksite/models"
import iconVaihtolava from "common/images/icon-large-vaihtolava.svg"
import { useHistory } from "react-router"
import { useNewOrderEvents } from "../../../util/analytics"
import OrderForm, { OrderFormWasteTypes } from "../common/OrderForm"
import { phone } from "../../../store/auth/auth-reducer"

interface VaihtolavaBaseProps {
  worksite: Worksite
  phone: string
}

interface ReduxProps {
  isSubmittedSuccessfully: boolean
  submitOrder: (order: Order) => void
  submitOrderReset: () => void
}

const PRODUCT_TYPE = "vaihtolavat"

const VaihtolavaBase = ({
  isSubmittedSuccessfully,
  submitOrder,
  worksite,
  submitOrderReset,
  phone
}: VaihtolavaBaseProps & ReduxProps) => {
  const [formType, setFormType] = React.useState<BaseFormTypes>(
    BaseFormTypes.UNSELECTED
  )
  const [order, setOrder] = React.useState<Order | null>(null)
  const [formState, setFormState] = React.useState<OrderFormState | null>(null)
  const history = useHistory()

  useEffect(() => {
    if (formType === BaseFormTypes.UNSELECTED) submitOrderReset()
  }, [formType, submitOrderReset])

  const orderSelectionItems: OrderSelectionItem[] = [
    {
      titleId: "order.vaihtolava.new",
      selectedFormType: BaseFormTypes.NEW
    },
    {
      titleId: "order.vaihtolava.empty",
      selectedFormType: BaseFormTypes.EMPTYING
    },
    {
      titleId: "order.vaihtolava.move",
      selectedFormType: BaseFormTypes.MOVE
    },
    {
      titleId: "order.vaihtolava.remove",
      selectedFormType: BaseFormTypes.REMOVE
    }
  ]
  useNewOrderEvents(
    PRODUCT_TYPE,
    orderSelectionItems,
    formType,
    order,
    isSubmittedSuccessfully
  )

  const getTitleId = (formType: BaseFormTypes) => {
    switch (formType) {
      case BaseFormTypes.NEW:
        return "order.confirm.vaihtolava.new"
      case BaseFormTypes.EMPTYING:
        return "order.confirm.vaihtolava.empty"
      case BaseFormTypes.MOVE:
        return "order.confirm.vaihtolava.move"
      case BaseFormTypes.REMOVE:
        return "order.confirm.vaihtolava.remove"
      case "other":
      case BaseFormTypes.UNSELECTED:
        return ""
      default:
        makeSwitchExhaustive(formType)
    }
  }

  const cancelOrder = () => setOrder(null)
  const cancelForm = () => {
    setFormState(null)
    setFormType(BaseFormTypes.UNSELECTED)
  }

  const onFormSubmitClick = (order: Order, formState: OrderFormState) => {
    setOrder(order)
    setFormState(formState)
    window.scrollTo(0, 0)
  }

  const onSubmitOrderClick = (order: Order) => {
    submitOrder(order)
  }

  if (isSubmittedSuccessfully) {
    return (
      <OrderSuccess
        onCancel={() => history.push("/create")}
        image={iconVaihtolava}
      />
    )
  }

  if (order) {
    return (
      <OrderConfirm
        titleId={getTitleId(formType)}
        order={order}
        worksite={worksite}
        onCancel={cancelOrder}
        onSubmit={onSubmitOrderClick}
      />
    )
  }

  if (formType === BaseFormTypes.UNSELECTED) {
    return (
      <OrderSelection
        selectionTitleId="order.vaihtolava.title"
        items={orderSelectionItems}
        onClick={setFormType}
      />
    )
  }

  return (
    <OrderForm
      phone={phone}
      wasteType={OrderFormWasteTypes.Vaihtolava}
      formType={formType}
      onSubmit={onFormSubmitClick}
      propFormState={formState}
      onCancel={cancelForm}
      worksite={worksite}
    />
  )
}

const connector = connect(
  (state: AppState) => ({
    phone: phone(state),
    isSubmittedSuccessfully: isSubmittedSuccessfully(state)
  }),
  { submitOrder, submitOrderReset }
)

export default connector(VaihtolavaBase)
