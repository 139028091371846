import React from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import { format } from "date-fns"
import { getFirstAvailableDay } from "pages/orders/order-utils"
import { getDayPickerLocalisations } from "i18n/localisation-utils"

import "react-day-picker/lib/style.css"
import "./day-picker.sass"

interface DayPickerProps {
  date: Date
  onDayChange: (day: Date) => void
}

export const DayPickerInputWrapper = ({
  date,
  onDayChange
}: DayPickerProps) => {
  const dayPickerLocalisationValues = getDayPickerLocalisations("fi")
  return (
    <DayPickerInput
      value={date}
      inputProps={{ readOnly: true }}
      format="dd.MM.yyyy"
      formatDate={(date, formatString) => format(date, formatString)}
      dayPickerProps={{
        selectedDays: [date],
        disabledDays: [
          { daysOfWeek: [0, 6] },
          { before: getFirstAvailableDay() }
        ],
        firstDayOfWeek: 1,
        showOutsideDays: true,
        modifiers: { selected: date },
        modifiersStyles: {
          selected: { backgroundColor: "#64A60A", borderRadius: "0%" }
        },
        months: dayPickerLocalisationValues.months,
        weekdaysLong: dayPickerLocalisationValues.weekdaysLong,
        weekdaysShort: dayPickerLocalisationValues.weekdaysShort
      }}
      onDayChange={onDayChange}
    />
  )
}
