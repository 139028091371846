import { createStore, applyMiddleware, compose, combineReducers } from "redux"
import { combineEpics, createEpicMiddleware, Epic } from "redux-observable"
import { httpErrorHandler } from "./http"
import { isProd } from "util/env"
import { AppAction } from "./action"
import { AppState } from "./state"
import { authReducer } from "./auth/auth-reducer"
import { authEpic } from "./auth/auth-epic"
import { orderReducer } from "./order/order-reducer"
import { orderEpic } from "./order/order-epic"
import { worksiteReducer } from "./worksite/worksite-reducer"
import { worksiteEpic } from "./worksite/worksite-epic"

const epicMiddleware = createEpicMiddleware<AppAction, AppAction, AppState>({
  dependencies: { httpErrorHandler }
})

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && !isProd()
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const rootReducer = combineReducers({
  auth: authReducer,
  order: orderReducer,
  worksite: worksiteReducer
})

const rootEpic: Epic<AppAction, AppAction, AppState> = combineEpics<
  AppAction,
  AppAction,
  AppState
>(authEpic, orderEpic, worksiteEpic)

/**
 * STORE
 */
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(epicMiddleware))
)

epicMiddleware.run(rootEpic)
